.styleguide {

  .cart-product {
    margin-left: 50px; } }

.cart-product {
  width: 780px;
  height: 201px;
  background-color: $c-white;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;

  .cart &, .checkout & {
    @include mq($until: phone) {
      flex-wrap: wrap; } }

  .popup-cart__products & {
    padding: 0 0 20px 0;
    box-sizing: content-box;
    width: auto;
    min-height: 106px;
    max-height: 110px;
    margin-left: 0;
    border-bottom: 1px solid $c-brightgrey;
    border-radius: 0;

    @include mq($until: phone) {
      height: auto; } }


  &--unavailable {
    border: 2px solid $c-notification-red !important;
    color: $c-notification-red; }

  &--wishlist {
    height: 153px;
    border: 0;
    color: unset; }

  &--order-detail {
    height: auto; }

  &__shipping {
    margin-bottom: 10px;
    @include fontsize(12px, 1.33);

    .cart-product--unavailable &, .popup-cart__products & {
      display: none; }

    .cart-product--order-detail & {
      font-weight: bold;
      @include fontsize(16px, 1.5);
      margin-bottom: 0; }

    .cart-product--wishlist & {
      @include fontsize(16px, 20px);
      margin-bottom: 5px; } }

  &__wrap-separator {
    display: flex;
    flex-grow: 1;

    &--detail {
      position: relative;

      .cart-product--wishlist & {
        display: flex;
        flex-direction: column; } } }

  &__image {
    display: block;
    overflow: hidden;
    max-width: 120px;
    width: stretch;
    flex-shrink: 0;
    height: 159px;
    border-radius: 8px;
    background-color: $c-white;
    border: 2px solid $c-lightgrey;
    @include transition;

    .cart-product--available & {
      @include mq($until: phone) {
        width: 74px;
        height: 98px;
        @include transition; } }

    .image {
      height: 100%;
      padding: 10px;
      box-sizing: border-box;
      display: flex;

      img {
        align-self: center;
        object-fit: cover;
        width: 100%; } }

    .popup-cart__products &,
    .cart-product--wishlist &,
    .cart-product--order-detail & {
      max-width: 80px;
      flex-shrink: 0;
      width: stretch;
      height: 106px; } }

  &__row {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .button {
      margin-left: 0;
      @include fontsize(16px, 1.35);
      font-weight: normal;
      height: 18px;
      display: flex;
      justify-content: flex-start;
      padding-left: 24px;
      padding-right: 0;

      .icon {
        @include icon-size(16px);
        left: 0; }

      &:hover, &:focus {
        transform: none;
        width: auto; }

      .popup-cart__products & {
        @include fontsize(12px, 1.33);
        padding-left: 0;
        color: $c-grey;

        &:hover, &:focus {
          color: $c-black; } } }

    &--detail {
      margin-bottom: 10px;

      .cart-product--order-detail & {
        margin-bottom: 0; }

      .popup-cart__products & {
        margin-bottom: 6px;
        white-space: nowrap;
        flex-wrap: wrap; }

      .cart-product--wishlist &, .cart &, .checkout &, .orders & {
        @include fontsize(16px, 1.5);
        margin-bottom: 5px;
        white-space: nowrap;
        flex-wrap: wrap; }

      @include mq($from: tablet) {
        .orders & {
          width: 20em; } } }

    &--actions {
      margin-top: auto;

      .cart-product--order-detail & {
        margin-top: 0; }

      @include mq($until: mobile) {
        .cart &, .checkout & {
          flex-wrap: wrap;
          display: block; } }

      @include mq($until: phone) {
        .popup-cart__products & {
          flex-wrap: wrap; } } } }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    padding-left: 20px; }

  &__title {
    @include fontsize(24px, 1.16);
    font-weight: bold;
    padding-bottom: 10px;

    .cart-product--order-detail & {
      font-weight: bold;
      white-space: pre-wrap;
      @include fontsize(18px, 1.2);

      @include mq($from: desktop) {
        max-width: 18vw;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; } }

    .popup-cart__products & {
      @include fontsize(18px, 1.22);
      padding-bottom: 8px;
      max-width: 236px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @include mq($until: phone) {
        white-space: pre-wrap;
        text-overflow: unset;
        overflow: unset;
        @include fontsize(18px, 1.2); } }

    .cart &, .checkout & {
      max-width: 60vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-grow: 0;

      @include mq($from: mobile) {
        max-width: 48vw; }
      @include mq($from: tablet) {
        max-width: 27vw; }
      @include mq($from: desktop) {
        max-width: 28vw; } }

    .cart-product--wishlist & {
      @include fontsize(24px, 28px);
      padding-bottom: 5px;
      max-width: 50vw;
      padding-right: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @include mq($from: mobile) {
        max-width: 48vw; }
      @include mq($from: tablet) {
        max-width: 27vw; }
      @include mq($from: desktop) {
        max-width: 30vw; } } }

  &__detail {
    @include fontsize(16px, 1.5);

    .cart-product--wishlist & {
      @include fontsize(16px, 24px); }

    &-seperator {
      display: inline-block;
      padding: 0 5px;

      &--mobile {
        display: none; }

      @include mq($until: mobile) {
        .wishlist &, .cart &, .checkout & {
          display: block; } }

      .popup-cart__products & {
        display: none; } }

    &-price--mobile {
      display: none;

      @include mq($until: mobile) {
        .wishlist &, .cart &, .checkout & {
          display: block;
          font-weight: bold;
          color: $c-orange; } }
      @include mq($until: desktop) {
        .orders & {
          display: block;
          font-weight: bold;
          color: $c-orange; } } }

    &-qty {
      display: none;

      .popup-cart__products &, .orders__product-list & {
        display: inline-block; } }

    &-id {
      display: inline-block;
      margin-right: 5px;

      .popup-cart__products & {
        display: none; } }

    &-variant {
      position: relative;

      .popup-cart__products & {
        max-width: 100px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }

      &-label {
        display: inline-block;
        margin-right: 5px;
        margin-left: 8px;

        .cart-product--wishlist & {
          margin-left: 0; }

        .orders__product-list & {
          margin-left: 0; } } } }

  &__price {
    @include fontsize(24px, 1.16);
    font-weight: bold;
    color: $c-orange;
    margin-bottom: 10px;

    @include mq($until: mobile) {
      .wishlist &, .cart &, .checkout &, .orders & {
        display: none; } }
    @include mq($until: desktop) {
      .orders & {
        display: none; } }

    .cart-product--order-detail & {
      margin-bottom: auto;
      text-align: right;
      @include fontsize(18px, 1.22); }

    .cart-product--unavailable & {
      color: $c-lightgrey;
      margin-bottom: 0; }

    .popup-cart__products & {
      @include fontsize(18px, 1.22); }

    .cart-product--wishlist & {
      @include fontsize(24px, 28px);
      margin-bottom: 0;
      color: $c-orange; } }

  &__availability {
    @include fontsize(16px, 1.25);
    font-weight: bold;

    .cart-product--wishlist & {
      color: $c-notification-red;
      margin-bottom: 5px; } }

  &__action-wishlist {
    margin-right: 10px;

    .icon {
      transition: none;

      svg use {
        transition: none; } }

    .icon--heart-active svg use {
      fill: $c-orange;
      transition: none; }


    .button:hover, .button:focus {
      color: $c-blue;
      text-decoration: underline;

      .cart-product--wishlist & {
        width: 100%;
        transform: none; } }

    .button {
      position: relative;

      .cart-product--wishlist & {
        font-weight: normal;
        height: 100%;
        padding-left: 24px;
        @include fontsize(16px, 1.5);

        .icon {
          left: 0;
          @include icon-size(16px); } }

      .cart-product--unavailable & {
        color: $c-brightgrey;
        pointer-events: none;

        .icon {
          @include icon-color($c-brightgrey); } } }

    .popup-cart__products & {
      margin-right: 5px; }

    .cart-product--wishlist & {
      margin-right: 0;
      margin-bottom: 5px; } }

  &__action-seperator {
    display: none;

    .popup-cart__products & {
      display: inline-block;
      @include fontsize(12px, 1.33);
      color: $c-grey; } }

  &__action-delete {
    position: relative;
    display: block;

    @include mq($until: mobile) {
      .cart &, .checkout & {
        margin-top: 8px; } }

    .button:hover, .button:focus {
      color: $c-blue;
      text-decoration: underline;

      .cart-product--wishlist & {
        width: auto;
        transform: none; } }

    .button {
      position: relative;
      height: 18px;

      .cart-product--wishlist & {
        font-weight: normal;
        height: auto;
        padding-left: 24px;
        @include fontsize(16px, 1.5);

        .icon {
          left: 0;
          top: 10px;
          @include icon-size(16px); } } }

    .popup-cart__products & {
      margin-left: 5px; }

    .cart-product--wishlist & {
      margin-left: 0; }

    .orders__product-list & {
      height: 21px; } }

  &__status {
    display: flex;
    flex-direction: column;
    padding-left: 104px;

    @include mq($from: desktop) {
      padding-left: 0; }

    &-label {
      margin-bottom: 10px; }

    &-icons {
      display: flex;

      .icon {
        margin-right: 15px; }

      .icon:not(.active) {
        @include icon-color($c-brightgrey); }

      .icon--shipment-clock.active {
        @include icon-color($c-orange); }

      .icon--shipment-check.active {
        @include icon-color($c-black); }

      .icon--shipment-disable.active {
        @include icon-color($c-notification-red); } } }

  &__quantity {
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include mq($until: phone) {
      .cart &, .checkout & {
        margin-left: 88px;
        margin-top: 8px; } }

    .cart-product--order-detail & {
      width: auto;
      margin-top: 10px;
      text-align: right;

      .button {
        max-width: 280px; }

      @include mq($until: desktop) {
        .button {
          width: 100%;
          max-width: 380px; } }

      @include mq($from: desktop) {
        margin-top: 0; } }

    .cart-product--wishlist & {
      flex-basis: 160px;
      justify-content: flex-start; }

    .popup-cart__products & {
      display: none; }

    .selectfield {

      &__container {
        margin-bottom: 0;

        .cart-product--wishlist & {
          margin-top: auto; }

        .icon {
          .cart-product--overview & {
            display: none; } } }

      &__selected {
        background-color: $c-lightgrey; } } } }

.popup-cart__products .cart-product__action-wishlist,
.popup-cart__products .cart-product__action-delete {

  .icon {
    display: none; } }

.cart-product + .cart-product {
  margin-top: 0;

  .popup-cart__products & {
    margin-top: 20px; } }
