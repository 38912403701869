.not-found {
  position: relative;
  height: 100%;

  &__container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 100%;
    @include transition;

    @include mq($from: mobile) {
      padding: 40px 44px;
      flex-direction: row; }
    @include mq($from: tablet) {
      padding: 40px 130px;
      @include transition; } }

  &__lottie {
    max-width: 180px;
    width: 100%;
    @include transition;
    @include mq($from: mobile) {
      max-width: 400px;
      @include transition; } }

  &__content {
    margin-top: 40px;
    max-width: 480px;
    width: 100%;
    @include transition;
    @include mq($from: mobile) {
      margin-top: 0;
      margin-left: 40px;
      @include transition; }

    @include mq($from: tablet) {
      margin-left: 80px;
      @include transition; }

    h1 {
      @include fontsize(42px, 48px); }

    h2 {
      margin-bottom: 40px; }

    h4 {
      margin-top: 20px;

      & + p {
        margin-top: 10px; } }

    & > span {
      margin-top: 10px;
      display: block;
      font-weight: bold;
      @include fontsize(18px, 22px);
      color: $c-orange;

      & > span:first-of-type {
        color: $c-notification-red; }

      & > span:nth-of-type(3) {
        color: #3D52A8; } } }


  &__actions {
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    @include mq($from: mobile) {
      flex-direction: row; }

    a {
      display: inline-block;
      width: 100%;
      align-self: center;
      max-width: 280px;
      @include transition;

      @include mq($from: mobile) {
        width: 220px;
        @include transition; }

      &:first-of-type {
        margin-bottom: 10px;
        @include mq($from: mobile) {
          margin-bottom: 0;
          margin-right: 20px; } } }

    .button {
      width: 100%;

      &:first-of-type {
        .icon {
          @include icon-size(16px);
          transform: translateY(-50%) rotate(180deg);
          transform-origin: center; } } } } }
