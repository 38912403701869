.rect-auto {
  clip: rect(auto, auto, auto, auto); }

.c100 {
  &.p51 .slice, &.p52 .slice, &.p53 .slice, &.p54 .slice, &.p55 .slice, &.p56 .slice, &.p57 .slice, &.p58 .slice, &.p59 .slice, &.p60 .slice, &.p61 .slice, &.p62 .slice, &.p63 .slice, &.p64 .slice, &.p65 .slice, &.p66 .slice, &.p67 .slice, &.p68 .slice, &.p69 .slice, &.p70 .slice, &.p71 .slice, &.p72 .slice, &.p73 .slice, &.p74 .slice, &.p75 .slice, &.p76 .slice, &.p77 .slice, &.p78 .slice, &.p79 .slice, &.p80 .slice, &.p81 .slice, &.p82 .slice, &.p83 .slice, &.p84 .slice, &.p85 .slice, &.p86 .slice, &.p87 .slice, &.p88 .slice, &.p89 .slice, &.p90 .slice, &.p91 .slice, &.p92 .slice, &.p93 .slice, &.p94 .slice, &.p95 .slice, &.p96 .slice, &.p97 .slice, &.p98 .slice, &.p99 .slice, &.p100 .slice {
    clip: rect(auto, auto, auto, auto);
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%); } }

.c100 {
  .bar, &.p51 .fill, &.p52 .fill, &.p53 .fill, &.p54 .fill, &.p55 .fill, &.p56 .fill, &.p57 .fill, &.p58 .fill, &.p59 .fill, &.p60 .fill, &.p61 .fill, &.p62 .fill, &.p63 .fill, &.p64 .fill, &.p65 .fill, &.p66 .fill, &.p67 .fill, &.p68 .fill, &.p69 .fill, &.p70 .fill, &.p71 .fill, &.p72 .fill, &.p73 .fill, &.p74 .fill, &.p75 .fill, &.p76 .fill, &.p77 .fill, &.p78 .fill, &.p79 .fill, &.p80 .fill, &.p81 .fill, &.p82 .fill, &.p83 .fill, &.p84 .fill, &.p85 .fill, &.p86 .fill, &.p87 .fill, &.p88 .fill, &.p89 .fill, &.p90 .fill, &.p91 .fill, &.p92 .fill, &.p93 .fill, &.p94 .fill, &.p95 .fill, &.p96 .fill, &.p97 .fill, &.p98 .fill, &.p99 .fill, &.p100 .fill {
    position: absolute;
    border: 0.08em solid $c-orange;
    width: 0.84em;
    height: 0.84em;
    clip: rect(0em, 0.5em, 1em, 0em);
    border-radius: 50%;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);

    .club & {
      border-color: $c-brightgold; }

    .gewinnerreise & {
      border-color: $c-orange; } } }

.pie-fill {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg); }

.c100 {
  &.p51 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p52 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p53 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p54 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p55 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p56 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p57 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p58 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p59 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p60 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p61 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p62 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p63 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p64 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p65 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p66 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p67 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p68 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p69 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p70 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p71 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p72 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p73 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p74 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p75 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p76 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p77 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p78 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p79 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p80 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p81 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p82 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p83 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p84 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p85 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p86 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p87 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p88 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p89 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p90 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p91 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p92 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p93 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p94 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p95 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p96 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p97 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p98 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p99 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  &.p100 {
    .bar:after, .fill {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); } }

  position: relative;
  font-size: 120px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  float: left;
  background-color: $c-darkblue;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .club & {
    background-color: $c-brightgrey; }

  .gewinnerreise & {
    background-color: $c-darkblue; }

  &:not(:last-of-type) {
    margin: 0 0.1em 0 0; }

  * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;

    &:before, &:after {
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box; } }

  &.center {
    float: none;
    margin: 0 auto; }

  &.big {
    font-size: 240px; }

  &.small {
    font-size: 80px; }

  > span {
    z-index: 1;
    left: 0;
    top: 0;
    font-size: 0.2em;
    color: $c-white;
    display: block;
    text-align: center;
    white-space: nowrap;
    @include transition;

    .club & {
      color: $c-black; }

    .gewinnerreise & {
      color: $c-white; }

    &:first-of-type {
      @include fontsize(54px, 0.8);
      font-weight: bold; }

    &:last-of-type {
      @include fontsize(12px, 12px);
      font-weight: bold; } }

  &:after {
    position: absolute;
    top: 0.08em;
    left: 0.08em;
    display: block;
    content: " ";
    border-radius: 50%;
    background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box;
    width: 0.84em;
    height: 0.84em;

    .club & {
      background: $c-white; }

    .gewinnerreise & {
      background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box; } }

  .slice {
    position: absolute;
    width: 1em;
    height: 1em;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    clip: rect(0em, 1em, 1em, 0.5em); }

  &.p1 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(3.6deg);
    -moz-transform: rotate(3.6deg);
    -ms-transform: rotate(3.6deg);
    -o-transform: rotate(3.6deg);
    transform: rotate(3.6deg); }

  &.p2 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(7.2deg);
    -moz-transform: rotate(7.2deg);
    -ms-transform: rotate(7.2deg);
    -o-transform: rotate(7.2deg);
    transform: rotate(7.2deg); }

  &.p3 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(10.8deg);
    -moz-transform: rotate(10.8deg);
    -ms-transform: rotate(10.8deg);
    -o-transform: rotate(10.8deg);
    transform: rotate(10.8deg); }

  &.p4 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(14.4deg);
    -moz-transform: rotate(14.4deg);
    -ms-transform: rotate(14.4deg);
    -o-transform: rotate(14.4deg);
    transform: rotate(14.4deg); }

  &.p5 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(18deg);
    -moz-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    -o-transform: rotate(18deg);
    transform: rotate(18deg); }

  &.p6 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(21.6deg);
    -moz-transform: rotate(21.6deg);
    -ms-transform: rotate(21.6deg);
    -o-transform: rotate(21.6deg);
    transform: rotate(21.6deg); }

  &.p7 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(25.2deg);
    -moz-transform: rotate(25.2deg);
    -ms-transform: rotate(25.2deg);
    -o-transform: rotate(25.2deg);
    transform: rotate(25.2deg); }

  &.p8 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(28.8deg);
    -moz-transform: rotate(28.8deg);
    -ms-transform: rotate(28.8deg);
    -o-transform: rotate(28.8deg);
    transform: rotate(28.8deg); }

  &.p9 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(32.4deg);
    -moz-transform: rotate(32.4deg);
    -ms-transform: rotate(32.4deg);
    -o-transform: rotate(32.4deg);
    transform: rotate(32.4deg); }

  &.p10 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(36deg);
    -moz-transform: rotate(36deg);
    -ms-transform: rotate(36deg);
    -o-transform: rotate(36deg);
    transform: rotate(36deg); }

  &.p11 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(39.6deg);
    -moz-transform: rotate(39.6deg);
    -ms-transform: rotate(39.6deg);
    -o-transform: rotate(39.6deg);
    transform: rotate(39.6deg); }

  &.p12 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(43.2deg);
    -moz-transform: rotate(43.2deg);
    -ms-transform: rotate(43.2deg);
    -o-transform: rotate(43.2deg);
    transform: rotate(43.2deg); }

  &.p13 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(46.8deg);
    -moz-transform: rotate(46.8deg);
    -ms-transform: rotate(46.8deg);
    -o-transform: rotate(46.8deg);
    transform: rotate(46.8deg); }

  &.p14 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(50.4deg);
    -moz-transform: rotate(50.4deg);
    -ms-transform: rotate(50.4deg);
    -o-transform: rotate(50.4deg);
    transform: rotate(50.4deg); }

  &.p15 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(54deg);
    -moz-transform: rotate(54deg);
    -ms-transform: rotate(54deg);
    -o-transform: rotate(54deg);
    transform: rotate(54deg); }

  &.p16 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(57.6deg);
    -moz-transform: rotate(57.6deg);
    -ms-transform: rotate(57.6deg);
    -o-transform: rotate(57.6deg);
    transform: rotate(57.6deg); }

  &.p17 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(61.2deg);
    -moz-transform: rotate(61.2deg);
    -ms-transform: rotate(61.2deg);
    -o-transform: rotate(61.2deg);
    transform: rotate(61.2deg); }

  &.p18 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(64.8deg);
    -moz-transform: rotate(64.8deg);
    -ms-transform: rotate(64.8deg);
    -o-transform: rotate(64.8deg);
    transform: rotate(64.8deg); }

  &.p19 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(68.4deg);
    -moz-transform: rotate(68.4deg);
    -ms-transform: rotate(68.4deg);
    -o-transform: rotate(68.4deg);
    transform: rotate(68.4deg); }

  &.p20 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(72deg);
    -moz-transform: rotate(72deg);
    -ms-transform: rotate(72deg);
    -o-transform: rotate(72deg);
    transform: rotate(72deg); }

  &.p21 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(75.6deg);
    -moz-transform: rotate(75.6deg);
    -ms-transform: rotate(75.6deg);
    -o-transform: rotate(75.6deg);
    transform: rotate(75.6deg); }

  &.p22 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(79.2deg);
    -moz-transform: rotate(79.2deg);
    -ms-transform: rotate(79.2deg);
    -o-transform: rotate(79.2deg);
    transform: rotate(79.2deg); }

  &.p23 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(82.8deg);
    -moz-transform: rotate(82.8deg);
    -ms-transform: rotate(82.8deg);
    -o-transform: rotate(82.8deg);
    transform: rotate(82.8deg); }

  &.p24 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(86.4deg);
    -moz-transform: rotate(86.4deg);
    -ms-transform: rotate(86.4deg);
    -o-transform: rotate(86.4deg);
    transform: rotate(86.4deg); }

  &.p25 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg); }

  &.p26 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(93.6deg);
    -moz-transform: rotate(93.6deg);
    -ms-transform: rotate(93.6deg);
    -o-transform: rotate(93.6deg);
    transform: rotate(93.6deg); }

  &.p27 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(97.2deg);
    -moz-transform: rotate(97.2deg);
    -ms-transform: rotate(97.2deg);
    -o-transform: rotate(97.2deg);
    transform: rotate(97.2deg); }

  &.p28 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(100.8deg);
    -moz-transform: rotate(100.8deg);
    -ms-transform: rotate(100.8deg);
    -o-transform: rotate(100.8deg);
    transform: rotate(100.8deg); }

  &.p29 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(104.4deg);
    -moz-transform: rotate(104.4deg);
    -ms-transform: rotate(104.4deg);
    -o-transform: rotate(104.4deg);
    transform: rotate(104.4deg); }

  &.p30 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(108deg);
    -moz-transform: rotate(108deg);
    -ms-transform: rotate(108deg);
    -o-transform: rotate(108deg);
    transform: rotate(108deg); }

  &.p31 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(111.6deg);
    -moz-transform: rotate(111.6deg);
    -ms-transform: rotate(111.6deg);
    -o-transform: rotate(111.6deg);
    transform: rotate(111.6deg); }

  &.p32 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(115.2deg);
    -moz-transform: rotate(115.2deg);
    -ms-transform: rotate(115.2deg);
    -o-transform: rotate(115.2deg);
    transform: rotate(115.2deg); }

  &.p33 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(118.8deg);
    -moz-transform: rotate(118.8deg);
    -ms-transform: rotate(118.8deg);
    -o-transform: rotate(118.8deg);
    transform: rotate(118.8deg); }

  &.p34 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(122.4deg);
    -moz-transform: rotate(122.4deg);
    -ms-transform: rotate(122.4deg);
    -o-transform: rotate(122.4deg);
    transform: rotate(122.4deg); }

  &.p35 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(126deg);
    -moz-transform: rotate(126deg);
    -ms-transform: rotate(126deg);
    -o-transform: rotate(126deg);
    transform: rotate(126deg); }

  &.p36 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(129.6deg);
    -moz-transform: rotate(129.6deg);
    -ms-transform: rotate(129.6deg);
    -o-transform: rotate(129.6deg);
    transform: rotate(129.6deg); }

  &.p37 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(133.2deg);
    -moz-transform: rotate(133.2deg);
    -ms-transform: rotate(133.2deg);
    -o-transform: rotate(133.2deg);
    transform: rotate(133.2deg); }

  &.p38 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(136.8deg);
    -moz-transform: rotate(136.8deg);
    -ms-transform: rotate(136.8deg);
    -o-transform: rotate(136.8deg);
    transform: rotate(136.8deg); }

  &.p39 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(140.4deg);
    -moz-transform: rotate(140.4deg);
    -ms-transform: rotate(140.4deg);
    -o-transform: rotate(140.4deg);
    transform: rotate(140.4deg); }

  &.p40 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(144deg);
    -moz-transform: rotate(144deg);
    -ms-transform: rotate(144deg);
    -o-transform: rotate(144deg);
    transform: rotate(144deg); }

  &.p41 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(147.6deg);
    -moz-transform: rotate(147.6deg);
    -ms-transform: rotate(147.6deg);
    -o-transform: rotate(147.6deg);
    transform: rotate(147.6deg); }

  &.p42 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(151.2deg);
    -moz-transform: rotate(151.2deg);
    -ms-transform: rotate(151.2deg);
    -o-transform: rotate(151.2deg);
    transform: rotate(151.2deg); }

  &.p43 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(154.8deg);
    -moz-transform: rotate(154.8deg);
    -ms-transform: rotate(154.8deg);
    -o-transform: rotate(154.8deg);
    transform: rotate(154.8deg); }

  &.p44 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(158.4deg);
    -moz-transform: rotate(158.4deg);
    -ms-transform: rotate(158.4deg);
    -o-transform: rotate(158.4deg);
    transform: rotate(158.4deg); }

  &.p45 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(162deg);
    -moz-transform: rotate(162deg);
    -ms-transform: rotate(162deg);
    -o-transform: rotate(162deg);
    transform: rotate(162deg); }

  &.p46 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(165.6deg);
    -moz-transform: rotate(165.6deg);
    -ms-transform: rotate(165.6deg);
    -o-transform: rotate(165.6deg);
    transform: rotate(165.6deg); }

  &.p47 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(169.2deg);
    -moz-transform: rotate(169.2deg);
    -ms-transform: rotate(169.2deg);
    -o-transform: rotate(169.2deg);
    transform: rotate(169.2deg); }

  &.p48 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(172.8deg);
    -moz-transform: rotate(172.8deg);
    -ms-transform: rotate(172.8deg);
    -o-transform: rotate(172.8deg);
    transform: rotate(172.8deg); }

  &.p49 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(176.4deg);
    -moz-transform: rotate(176.4deg);
    -ms-transform: rotate(176.4deg);
    -o-transform: rotate(176.4deg);
    transform: rotate(176.4deg); }

  &.p50 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }

  &.p51 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(183.6deg);
    -moz-transform: rotate(183.6deg);
    -ms-transform: rotate(183.6deg);
    -o-transform: rotate(183.6deg);
    transform: rotate(183.6deg); }

  &.p52 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(187.2deg);
    -moz-transform: rotate(187.2deg);
    -ms-transform: rotate(187.2deg);
    -o-transform: rotate(187.2deg);
    transform: rotate(187.2deg); }

  &.p53 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(190.8deg);
    -moz-transform: rotate(190.8deg);
    -ms-transform: rotate(190.8deg);
    -o-transform: rotate(190.8deg);
    transform: rotate(190.8deg); }

  &.p54 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(194.4deg);
    -moz-transform: rotate(194.4deg);
    -ms-transform: rotate(194.4deg);
    -o-transform: rotate(194.4deg);
    transform: rotate(194.4deg); }

  &.p55 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(198deg);
    -moz-transform: rotate(198deg);
    -ms-transform: rotate(198deg);
    -o-transform: rotate(198deg);
    transform: rotate(198deg); }

  &.p56 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(201.6deg);
    -moz-transform: rotate(201.6deg);
    -ms-transform: rotate(201.6deg);
    -o-transform: rotate(201.6deg);
    transform: rotate(201.6deg); }

  &.p57 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(205.2deg);
    -moz-transform: rotate(205.2deg);
    -ms-transform: rotate(205.2deg);
    -o-transform: rotate(205.2deg);
    transform: rotate(205.2deg); }

  &.p58 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(208.8deg);
    -moz-transform: rotate(208.8deg);
    -ms-transform: rotate(208.8deg);
    -o-transform: rotate(208.8deg);
    transform: rotate(208.8deg); }

  &.p59 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(212.4deg);
    -moz-transform: rotate(212.4deg);
    -ms-transform: rotate(212.4deg);
    -o-transform: rotate(212.4deg);
    transform: rotate(212.4deg); }

  &.p60 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(216deg);
    -moz-transform: rotate(216deg);
    -ms-transform: rotate(216deg);
    -o-transform: rotate(216deg);
    transform: rotate(216deg); }

  &.p61 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(219.6deg);
    -moz-transform: rotate(219.6deg);
    -ms-transform: rotate(219.6deg);
    -o-transform: rotate(219.6deg);
    transform: rotate(219.6deg); }

  &.p62 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(223.2deg);
    -moz-transform: rotate(223.2deg);
    -ms-transform: rotate(223.2deg);
    -o-transform: rotate(223.2deg);
    transform: rotate(223.2deg); }

  &.p63 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(226.8deg);
    -moz-transform: rotate(226.8deg);
    -ms-transform: rotate(226.8deg);
    -o-transform: rotate(226.8deg);
    transform: rotate(226.8deg); }

  &.p64 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(230.4deg);
    -moz-transform: rotate(230.4deg);
    -ms-transform: rotate(230.4deg);
    -o-transform: rotate(230.4deg);
    transform: rotate(230.4deg); }

  &.p65 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(234deg);
    -moz-transform: rotate(234deg);
    -ms-transform: rotate(234deg);
    -o-transform: rotate(234deg);
    transform: rotate(234deg); }

  &.p66 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(237.6deg);
    -moz-transform: rotate(237.6deg);
    -ms-transform: rotate(237.6deg);
    -o-transform: rotate(237.6deg);
    transform: rotate(237.6deg); }

  &.p67 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(241.2deg);
    -moz-transform: rotate(241.2deg);
    -ms-transform: rotate(241.2deg);
    -o-transform: rotate(241.2deg);
    transform: rotate(241.2deg); }

  &.p68 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(244.8deg);
    -moz-transform: rotate(244.8deg);
    -ms-transform: rotate(244.8deg);
    -o-transform: rotate(244.8deg);
    transform: rotate(244.8deg); }

  &.p69 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(248.4deg);
    -moz-transform: rotate(248.4deg);
    -ms-transform: rotate(248.4deg);
    -o-transform: rotate(248.4deg);
    transform: rotate(248.4deg); }

  &.p70 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(252deg);
    -moz-transform: rotate(252deg);
    -ms-transform: rotate(252deg);
    -o-transform: rotate(252deg);
    transform: rotate(252deg); }

  &.p71 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(255.6deg);
    -moz-transform: rotate(255.6deg);
    -ms-transform: rotate(255.6deg);
    -o-transform: rotate(255.6deg);
    transform: rotate(255.6deg); }

  &.p72 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(259.2deg);
    -moz-transform: rotate(259.2deg);
    -ms-transform: rotate(259.2deg);
    -o-transform: rotate(259.2deg);
    transform: rotate(259.2deg); }

  &.p73 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(262.8deg);
    -moz-transform: rotate(262.8deg);
    -ms-transform: rotate(262.8deg);
    -o-transform: rotate(262.8deg);
    transform: rotate(262.8deg); }

  &.p74 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(266.4deg);
    -moz-transform: rotate(266.4deg);
    -ms-transform: rotate(266.4deg);
    -o-transform: rotate(266.4deg);
    transform: rotate(266.4deg); }

  &.p75 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg); }

  &.p76 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(273.6deg);
    -moz-transform: rotate(273.6deg);
    -ms-transform: rotate(273.6deg);
    -o-transform: rotate(273.6deg);
    transform: rotate(273.6deg); }

  &.p77 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(277.2deg);
    -moz-transform: rotate(277.2deg);
    -ms-transform: rotate(277.2deg);
    -o-transform: rotate(277.2deg);
    transform: rotate(277.2deg); }

  &.p78 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(280.8deg);
    -moz-transform: rotate(280.8deg);
    -ms-transform: rotate(280.8deg);
    -o-transform: rotate(280.8deg);
    transform: rotate(280.8deg); }

  &.p79 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(284.4deg);
    -moz-transform: rotate(284.4deg);
    -ms-transform: rotate(284.4deg);
    -o-transform: rotate(284.4deg);
    transform: rotate(284.4deg); }

  &.p80 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(288deg);
    -moz-transform: rotate(288deg);
    -ms-transform: rotate(288deg);
    -o-transform: rotate(288deg);
    transform: rotate(288deg); }

  &.p81 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(291.6deg);
    -moz-transform: rotate(291.6deg);
    -ms-transform: rotate(291.6deg);
    -o-transform: rotate(291.6deg);
    transform: rotate(291.6deg); }

  &.p82 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(295.2deg);
    -moz-transform: rotate(295.2deg);
    -ms-transform: rotate(295.2deg);
    -o-transform: rotate(295.2deg);
    transform: rotate(295.2deg); }

  &.p83 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(298.8deg);
    -moz-transform: rotate(298.8deg);
    -ms-transform: rotate(298.8deg);
    -o-transform: rotate(298.8deg);
    transform: rotate(298.8deg); }

  &.p84 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(302.4deg);
    -moz-transform: rotate(302.4deg);
    -ms-transform: rotate(302.4deg);
    -o-transform: rotate(302.4deg);
    transform: rotate(302.4deg); }

  &.p85 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(306deg);
    -moz-transform: rotate(306deg);
    -ms-transform: rotate(306deg);
    -o-transform: rotate(306deg);
    transform: rotate(306deg); }

  &.p86 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(309.6deg);
    -moz-transform: rotate(309.6deg);
    -ms-transform: rotate(309.6deg);
    -o-transform: rotate(309.6deg);
    transform: rotate(309.6deg); }

  &.p87 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(313.2deg);
    -moz-transform: rotate(313.2deg);
    -ms-transform: rotate(313.2deg);
    -o-transform: rotate(313.2deg);
    transform: rotate(313.2deg); }

  &.p88 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(316.8deg);
    -moz-transform: rotate(316.8deg);
    -ms-transform: rotate(316.8deg);
    -o-transform: rotate(316.8deg);
    transform: rotate(316.8deg); }

  &.p89 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(320.4deg);
    -moz-transform: rotate(320.4deg);
    -ms-transform: rotate(320.4deg);
    -o-transform: rotate(320.4deg);
    transform: rotate(320.4deg); }

  &.p90 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(324deg);
    -moz-transform: rotate(324deg);
    -ms-transform: rotate(324deg);
    -o-transform: rotate(324deg);
    transform: rotate(324deg); }

  &.p91 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(327.6deg);
    -moz-transform: rotate(327.6deg);
    -ms-transform: rotate(327.6deg);
    -o-transform: rotate(327.6deg);
    transform: rotate(327.6deg); }

  &.p92 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(331.2deg);
    -moz-transform: rotate(331.2deg);
    -ms-transform: rotate(331.2deg);
    -o-transform: rotate(331.2deg);
    transform: rotate(331.2deg); }

  &.p93 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(334.8deg);
    -moz-transform: rotate(334.8deg);
    -ms-transform: rotate(334.8deg);
    -o-transform: rotate(334.8deg);
    transform: rotate(334.8deg); }

  &.p94 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(338.4deg);
    -moz-transform: rotate(338.4deg);
    -ms-transform: rotate(338.4deg);
    -o-transform: rotate(338.4deg);
    transform: rotate(338.4deg); }

  &.p95 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(342deg);
    -moz-transform: rotate(342deg);
    -ms-transform: rotate(342deg);
    -o-transform: rotate(342deg);
    transform: rotate(342deg); }

  &.p96 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(345.6deg);
    -moz-transform: rotate(345.6deg);
    -ms-transform: rotate(345.6deg);
    -o-transform: rotate(345.6deg);
    transform: rotate(345.6deg); }

  &.p97 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(349.2deg);
    -moz-transform: rotate(349.2deg);
    -ms-transform: rotate(349.2deg);
    -o-transform: rotate(349.2deg);
    transform: rotate(349.2deg); }

  &.p98 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(352.8deg);
    -moz-transform: rotate(352.8deg);
    -ms-transform: rotate(352.8deg);
    -o-transform: rotate(352.8deg);
    transform: rotate(352.8deg); }

  &.p99 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(356.4deg);
    -moz-transform: rotate(356.4deg);
    -ms-transform: rotate(356.4deg);
    -o-transform: rotate(356.4deg);
    transform: rotate(356.4deg); }

  &.p100 .bar {
    transition: transform 0.3s ease-in-out;
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); }

  &:hover {
    cursor: default; } }
