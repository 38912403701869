.casino-header__section {
  background-image: url("/images/app/casino/vid--casino.jpg");
  background-position: bottom;
  min-height: 412px;
  overflow: hidden;
  @include transition;

  video {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    object-fit: cover;
    border-radius: 1px;
    width: 100%;
    //height: 100%
    overflow: hidden;

    .oldie & {
      height: auto; } }

  .app-header__left p {
    @include mq($until: tablet) {
      margin: 0 auto; } }

  &.app-header__section--open {
    @include transition;

    & ~ .casino-room__section .casino-room__information {
      max-height: 36em;
      margin-bottom: 20px;

      @include mq($from: tablet) {
        display: none; } }

    .app-header__left {
      p, h3 {
        display: none;
        @include mq($from: mobile) {
          display: block; }
        @include mq($from: tablet) {
          max-width: 380px; } }

      p {
        max-height: 40em; } }

    @include mq($from: tablet) {
      .casino-room__slots-wrapper {
        display: flex; }
      & ~ .casino-room__section .casino-room__slots-wrapper {
        display: none; } } }

  @include mq($from: mobile) {
    background-position: bottom center;
    min-height: 540px;
    &.app-header__section--open {
      height: auto; } }

  @include mq($from: tablet) {
    height: 310px;

    //& > video
    //  height: 820px
    //  transform: translateY(-65%)
    //  +transition

    &.app-header__section--open {
      height: auto; } }

  //& > video
  ////transform: translateY(-50%)
  ////height: 100%
  ////+transition

  .app-header__heading h1 {
    text-align: center; }

  .casino-room__slots-wrapper {
    display: none;
    position: absolute;
    left: 50%;
    transform: translate(-8%, -20%);
    top: 50%;
    flex-direction: row;
    z-index: 50;

    .casino-room__slots-available {
      z-index: 10;
      width: 153px;
      height: 153px;
      transform: rotate(120deg); }

    .casino-room__slots {
      width: 129px;
      height: 129px;
      transform: rotate(-120deg);
      display: flex;
      align-items: center;

      & > h1 {
        height: 115px;
        display: block;
        // +fontsize(100px, 100px)
        font-size: 70px;
        line-height: normal;

        .oldie & {
          line-height: 70px; } } }

    .casino-room__slots-lower {
      z-index: 5;
      margin: 0 0 0 -50px; }

    .casino-room__slots-subline, .casino-room__slots-headline {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 8px;
      padding-left: 60px;

      & > p {
        margin: 0; } } } }

.casino-header__section .casino-room__slots-wrapper .casino-room__slots--mac > h1 {
  position: relative;
  top: 19px; }
