.icon {
  display: inline-block;
  position: relative;
  height: 30px;
  width: 30px;

  &__sprite {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important; }

  &__inner {
    display: block;
    position: relative;
    padding-top: 100%; }

  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    use {
      fill: black;
      @include transition; } }

  &.icon--hidden {
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out; }

  &.icon--visible {
    opacity: 1;
    visibility: visible;
    transition: all .4s ease-in-out; } }
