.product-search {
  max-width: 820px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  background-color: $c-white;
  border-radius: 8px;
  margin: 40px 0;
  @include drop-shadow;

  .happypraemien & {
    margin: 90px 0 40px; }

  @include mq($until: mobile) {
    padding: 10px 10px 20px 10px; }

  h3 {
    margin-bottom: 20px;

    @include mq($until: mobile) {
      margin-top: 20px; } }

  &__section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: $c-lightgrey;
    padding: 0 20px;
    box-sizing: border-box;

    @include mq($until: mobile) {
      padding: 0 10px; } }

  &__circle {
    position: absolute;
    left: 50%;
    top: 0;
    width: 100px;
    height: 100px;
    background-color: $c-white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 6;
    transform: translate(-50%, -50%);
    @include drop-shadow;

    @include mq($until: mobile) {
      left: auto;
      right: 20px;
      transform: translateY(-50%); }

    .lottie__uncontrolled {
      width: 70px;
      height: 70px; } }

  &__form form {
    display: block;

    @include mq($from: mobile) {
      display: flex; }

    .textfield input::placeholder {
      color: $c-grey; }

    & > .textfield:first-of-type {
      margin-right: 20px;
      flex: 2;
      margin-bottom: 0;

      @include mq($until: mobile) {
        margin-right: 0;
        margin-bottom: 10px; } }

    .textfield-group {
      flex: 2;
      @include mq($until: mobile) {
        margin-bottom: 20px; }

      .textfield {
        flex: 1;
        margin-bottom: 0; } }

    .button {
      width: 200px;
      display: block;
      margin: 0 auto;
      @include mq($from: mobile) {
        margin-left: 20px; } } } }
