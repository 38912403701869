.gewinnerreise-header {

  &__section {
    height: 412px;
    background-image: none;
    overflow: hidden;

    .app-gewinnerreise__background {
      background-color: white;
      padding: 8px;
      border-radius: 8px;
      margin-bottom: 20px;
      display: inline-block; }

    .app-header__content {
      background: no-repeat;
      background-position-x: center;
      background-position-y: center;
      background-size: cover; }

    &.gewinnerreise-header__section--ranking {
      height: auto;
      min-height: auto; }

    &.app-header__section--open {
      height: 412px;

      &.gewinnerreise-header__section--ranking {
        height: auto; }

      & ~ .highperformance-performance__section {
        .gewinnerreise-header__information--mobile {
          max-height: 24em;

          & > p, & > h3 {
            width: auto; } } }

      @include mq($until: mobile) {
        & ~ .highperformance-performance__section {
          padding: 60px 25px 20px 25px; } }

      &:not(.gewinnerreise-header__section--ranking) {
        @include mq($until: tablet) {
          .app-header__left h3,
          .app-header__left p {
            display: none; } } } }

    @include mq($from: mobile) {
      height: 540px;

      &.app-header__section--open {
        height: 540px; } }

    @include mq($from: tablet) {
      height: auto;

      & ~ .highperformance-performance__section {
        padding-top: 160px;

        .gewinnerreise-header__center {
          top: -10px;
          transform: translateX(-50%);
          @include transition;
          @include mq($from: desktop) {
            transform: translateX(0%); } } }

      &.gewinnerreise-header__section--ranking {
        height: 320px; }

      &.app-header__section--open {
        height: auto;

        & ~ .highperformance-performance__section {
          padding-top: 230px;
          @include transition;

          .gewinnerreise-header__center {
            top: -150px;
            transform: translateX(0%);
            @include transition; } }

        .app-header__left > .app-header__left-text {
          max-height: 40em;
          @include transition; }

        .app-header__box {
          width: 1012px;
          height: 517px;
          top: 220px;
          right: -100px;
          bottom: 103px;
          @include transition; } } }

    @include mq($from: desktop) {
      &.app-header__section--open {
        & ~ .highperformance-performance__section {
          padding-top: 145px;

          .gewinnerreise-header__right {
            top: -140px;
            @include transition; } } } }

    .app-header__actions {
      z-index: 26;

      @include mq($from: tablet) {
        display: block; }

      button:first-of-type {
        color: $c-black;
        align-self: flex-start;
        margin: 0;

        .icon {
          @include icon-color($c-black); } }

      .button:last-of-type {
        display: block; } }

    .app-header__left {
      z-index: 7;

      .app-header__left-text {
        max-height: 0;
        height: stretch;
        transition: max-height 0.3s ease-in-out;
        overflow: hidden;

        h3 {
          display: block;
          color: $c-black; }

        & > p {
          display: block;
          max-width: 380px;
          overflow: hidden;
          color: $c-black;
          background-color: rgba($c-white, .8);
          border-radius: 8px;
          padding: 20px;

          & > a {
            color: $c-black; } } }

      h1, h2 {
        color: $c-black;
        background-color: $c-white;
        width: max-content;
        padding: 0 10px;
        border-radius: 8px; }

      h1 {
        text-align: center;
        border-bottom-left-radius: 0;
        padding-top: 8px; }

      h2 {
        border-radius: 0 0 8px 8px; } }

    .app-header__box {
      display: block;
      z-index: 3;
      width: 373px;
      height: 190px;
      left: 50%;
      top: 154px;
      bottom: unset;
      transform: translateX(-50%);

      @include mq($from: mobile) {
        top: 289px;
        width: 587px;
        height: 300px;
        transform: none;
        left: 0;
        right: 0; }

      @include mq($from: tablet) {
        width: 647px;
        height: 331px;
        top: 12px;
        right: -20px;
        animation: planes-fly 0.6s ease-out; } } }

  &__information--mobile {
    max-height: 0;
    overflow: hidden;
    margin-bottom: 20px;
    transition: max-height 0.3s ease-in-out;
    max-width: 380px;
    color: $c-white;

    & > h3 {
      margin-bottom: 20px; }

    & > p, & > h3 {
      width: 0; }

    @include mq($until: mobile) {
      max-width: 380px;

      .app-header__section--open & {
        margin-top: 10px;
        max-height: 24em;
        transition: max-height 0.3s ease-in-out; } }
    @include mq($from: tablet) {
      display: none; } }

  &__caesar {
    position: absolute;
    display: block;
    top: 16px;
    z-index: 10;
    @include transition;

    @include mq($from: mobile) {
      top: 44px;
      @include transition; }

    &:first-of-type {
      left: 0;
      transform: scaleX(-1) translateX(58%);

      @include mq($from: mobile) {
        transform: scaleX(-1) translateX(76%);
        @include transition; } }

    &:last-of-type {
      right: 0;
      transform: translateX(58%);

      @include mq($from: mobile) {
        transform: translateX(76%);
        @include transition; } }

    .icon, .icon svg {
      width: 62px;
      height: 108px;
      @include icon-color($c-gold);
      @include transition;

      @include mq($from: mobile) {
        width: 98px;
        height: 170px;
        @include transition; } } }

  &__circle {
    border-radius: 50%;
    background-color: $c-white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    @include drop-shadow;
    position: absolute;
    top: 0;
    transform: translateY(-80%);
    @include transition;

    @include mq($from: mobile) {
      width: 80px;
      height: 80px;
      transform: translateY(-50%);
      @include transition; }

    .icon {
      @include icon-color($c-brightgrey);
      @include icon-size(38px);
      top: -2px; }

    &:first-of-type {
      left: 8px;
      @include transition;

      @include mq($from: mobile) {
        left: 30px;
        @include transition; } }

    &:nth-of-type(2) {
      transform: translateY(-100%);
      width: 80px;
      height: 80px;
      @include transition;

      @include mq($from: mobile) {
        width: 100px;
        height: 100px;
        transform: translateY(-75%) translateX(-50%);
        left: 50%;
        @include transition; }

      .icon {
        @include icon-size(54px);
        top: -2px; } }

    &:last-of-type {
      left: auto;
      right: 8px;
      @include transition;

      @include mq($from: mobile) {
        right: 30px;
        @include transition; } } }

  &__center {
    display: flex;
    flex-direction: column;
    width: 240px;
    align-items: center;
    z-index: 5;
    @include transition;
    margin: 0 auto;
    top: -320px;

    @include mq($from: mobile) {
      //transform: translateX(-50%)
      width: 342px;
      top: -340px;
      @include transition; }

    @include mq($from: tablet) {
      position: absolute;
      bottom: 800px;
      transform: translateX(0%);
      @include transition; }

    @include mq($from: desktop) {
      top: -150px;
      transform: translateX(0%);
      @include transition; }

    &-header, &-rank, &-points {
      border-radius: 8px;
      background-color: $c-white;
      color: $c-black;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box; }

    &-header {
      padding: 17px 34px 40px;
      z-index: 8;
      position: relative;
      box-sizing: border-box;
      min-height: 88px;
      @include transition;

      & > h3 {
        text-align: center;
        @include fontsize(22px, 26px);
        @include mq($from: mobile) {
          text-align: left;
          @include fontsize(24px, 28px); } }

      @include mq($from: mobile) {
        padding: 20px 20px 40px;
        box-sizing: border-box;
        @include transition; } }

    &-rank {
      background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box;
      color: $c-white;
      width: 180px;
      min-height: 70px;
      height: 70px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 18px;
      margin-top: -30px;
      z-index: 10;
      @include drop-shadow;
      @include transition;

      @include mq($from: mobile) {
        margin-top: -25px;
        @include transition; }

      & > h1 {
        @include fontsize(72px, 72px);
        height: 62px; }

      .icon {
        @include icon-color($c-white);
        @include icon-size(22px); } }

    &-points {
      z-index: 8;
      margin-top: -25px;
      width: 276px;
      min-height: 130px;
      height: 130px;
      padding: 40px 30px 20px;

      & > h3 {
        @include fontsize(18px, 22px); }

      & > h1 {
        margin-top: auto;
        color: $c-orange;
        @include fontsize(48px, 28px);

        & > sup {
          @include fontsize(28px, 14px); } } } }

  &__right {
    position: relative;
    display: flex;
    flex-direction: column;
    bottom: 0;
    z-index: 25;
    right: -50%;
    transform: translateX(-50%);
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    pointer-events: none;
    @include transition;

    @include mq($from: mobile) {
      position: unset;
      right: 0;
      transform: none;
      //margin-top: -54px
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      @include transition;

      & > .gewinnerreise-header__information--mobile {
        margin-left: 24px; }

      & > div:last-of-type {
        position: absolute;
        right: 44px;
        top: 40px;
        pointer-events: all;
        @include transition;

        @include mq($from: tablet) {
          top: 20px; }
        @include mq($from: desktop) {
          top: 0;
          right: 130px; } } }

    @include mq($from: tablet) {
      top: 0;
      //margin-top: -220px //short only
      @include transition; }

    @include mq($from: desktop) {
      top: 20px;
      margin-top: 0;
      padding-right: 110px;
      max-width: 100%;
      height: 100%;
      position: absolute;
      @include transition; }

    & > div:last-of-type {
      display: flex;
      flex-direction: column;

      .button {
        margin-left: 0;
        margin-bottom: 10px;
        background: $c-white;
        pointer-events: all; } } } }
