.textfield {
  //margin-bottom: 12px
  position: relative;

  &__label {
    @include fontsize(12px, 1.33);
    display: block;
    color: $c-grey;
    margin-bottom: 6px;
    margin-left: 20px; }

  &__input, .flatpickr-input, .flatpickr-input.flatpickr-mobile {
    padding-left: 20px;
    height: 48px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    color: $c-black;
    background: $c-lightgrey;
    border-radius: 8px;
    @include fontsize(18px, 1.33);
    @include transition;

    &::placeholder, &:disabled {
      color: $c-grey;
      @include transition; }

    &:disabled, .textfield--disabled & {
      pointer-events: none;
      color: $c-brightgrey;
      @include transition;

      &::placeholder {
        color: $c-brightgrey;
        @include transition; } } }

  &--error input {
    border: 2px solid $c-notification-red;
    color: $c-notification-red; }

  &__errormsg {
    margin: {
      top: 8px;
      left: 20px;
      bottom: 0 !important; }
    color: $c-notification-red;
    @include fontsize(12px, 1.33); } }
//position: absolute

.textfield + .textfield, h2 + .textfield, .selectfield__container + .textfield {
  margin-top: 40px; }

.textfield-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  //margin-top: 40px

  & > div {
    flex: 1;
    padding-right: 10px;

    &:last-of-type {
      padding-right: 0; } }

  .textfield + .textfield {
    margin-top: 0 !important; }

  div.textfield:first-of-type {
    padding-right: 5px; }

  div.textfield:last-of-type {
    padding-left: 5px; } }

