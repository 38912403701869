.news-item {
  margin-top: 10px;
  border-radius: 8px;
  background-color: $c-white;
  padding: 20px;
  max-width: 780px;
  width: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    @include transition;

    .news-item--active & {
      border-bottom: 1px solid $c-brightgrey;
      padding-bottom: 20px;
      @include transition; }

    .icon {
      pointer-events: none;
      margin-right: 20px;
      @include icon-size(32px);
      @include transition;

      @include mq($until: mobile) {
        @include icon-size(24px);
        margin-right: 10px;
        @include transition; } }


    &-indicator {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: $c-turquoise;
      margin-right: 20px;
      @include transition;

      .news-item--read & {
        background-color: $c-white; }

      @include mq($until: mobile) {
        margin-right: 10px;
        @include transition; } }

    &-content {
      display: flex;
      flex-direction: column; }

    &-label {
      @include fontsize(24px, 1.16);
      font-weight: bold;
      @include transition;

      @include mq($until: mobile) {
        @include fontsize(18px, 1.22);
        @include transition; }

      .news-item--read & {
        font-weight: normal; } }

    .icon:last-of-type {
      transform: rotate(90deg);
      @include icon-size(24px);
      @include transition;
      margin-left: auto;
      margin-right: 0;

      .news-item--active & {
        transform: rotate(-90deg);
        @include transition; } } }

  &__content {
    max-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-left: 88px;
    @include transition;

    @include mq($until: mobile) {
      padding-left: 60px; }

    h4 {
      padding-bottom: 20px; }

    .news-item--active & {
      padding-top: 20px;
      max-height: 30em;
      padding-bottom: 40px;
      overflow-y: auto;
      @include transition; }

    .checkbox__container {
      //align-items: center
      @include fontsize(16px, 1.5); }

    .checkbox__container:first-of-type {
      margin-top: 12px; } } }

