.profile-image-modal {
  max-width: 343px;
  width: stretch;
  border-radius: 8px;
  background-color: $c-white;
  z-index: 200;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: stetch;

    & > h2 {
      margin-top: 20px; } }

  &__close {
    display: flex;
    margin-left: auto;
    cursor: pointer;

    .icon {
      @include icon-size(18px); } }

  &__drop {
    width: 265px;
    height: 265px;
    @include drop-shadow;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin-top: 20px;

    &--inner {
      position: relative;
      width: 223px;
      height: 223px;
      border-radius: 50%;
      border: 1px solid $c-orange;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .profile-image-modal--selected & {
        border: unset; }

      & > .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 2;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover; } }


      .dropzone {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 3;

        & > h4 {
          width: 144px;
          text-align: center;

          .profile-image-modal--selected & {
            color: $c-white;
            text-shadow: 0 2px 8px rgba($c-black, .5);
            @include transition; } } }

      .icon {
        @include icon-size(58px);
        @include icon-color($c-orange);
        margin-bottom: 20px; } } }
  //z-index: 3

  &__actions {
    margin-top: 40px;
    width: 100%;

    .button {
      max-width: 300px;
      width: stretch;

      &:hover, &:focus {
        transform: none; }

      .icon {
        @include icon-size(18px); }

      & + .button {
        margin: 10px 0 0; } } } }

