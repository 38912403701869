.button {
  min-width: max-content;
  height: 48px;
  border-radius: 32px;
  color: $c-darkblue;
  @include fontsize(16px, 48px);
  cursor: pointer;
  font-weight: bold;
  position: relative;
  padding: 0 20px;
  letter-spacing: 0;
  white-space: nowrap;
  @include transition;

  &:disabled {
    background-color: $c-background !important; }

  &__bubble {
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 0;
    background-color: $c-darkblue;
    border-radius: 32px;
    display: flex;
    align-items: center;
    height: 100%;
    width: 48px;
    overflow: hidden;
    @include transition;

    .icon--arrow {
      top: 50%;
      transform: translateY(-50%); }

    & > p {
      position: absolute;
      width: 214px;
      text-align: center;
      padding: 3px 42px 0 14px;
      transform: translateX(-76%) translateY(-51%);
      top: 50%;
      box-sizing: border-box;
      color: $c-white;
      letter-spacing: 0;
      @include fontsize(16px, 48px);
      @include transition; }

    .icon {
      position: absolute;
      @include icon-size(20px);
      @include icon-color($c-white);
      right: 12px; } }

  &--club-link {
    width: 214px;
    padding: 0 48px 0 20px;
    border: 2px solid $c-turquoise;
    background-color: $c-white;
    height: auto;

    &.button--gold {
      border: 2px solid $c-gold; } }

  &--primary {
    background-color: $c-white;
    border: none;

    .icon {
      @include icon-color($c-black);
      @include transition;

      svg use {
        @include transition; } }

    &.button--white {
      color: $c-white;
      background-color: $c-darkblue;

      &.button--inactive {
        color: $c-brightgrey;
        background-color: $c-lightgrey;
        border-color: $c-brightgrey;
        pointer-events: none; }

      .icon {
        @include icon-color($c-white);
        @include transition; }

      &:hover, &:focus {

        .icon {
          @include icon-color($c-black);
          @include transition;

          svg use {
            @include transition; } } } }

    &.button--bg-black {
      background-color: $c-darkblue; } }

  &--secondary, &--danger {
    background-color: transparent;
    border: 2px solid $c-turquoise;
    box-sizing: border-box;
    line-height: 46px;

    &.button--bg-black {
      background-color: $c-darkblue; }

    &.button--bg-white {
      background-color: $c-white; }

    &.button--club {
      border: 2px solid $c-darkblue;

      &.button--white {
        border: 2px solid $c-white; }

      &.button--gold {
        border: 2px solid $c-gold; } }

    &.button--white {
      color: $c-white;

      .icon {
        @include icon-color($c-white);
        @include transition;

        svg use {
          @include transition; } }

      &:hover, &:focus {
        line-height: 46px;

        .icon {
          @include icon-color($c-black);
          @include transition;

          svg use {
            @include transition; } } } } }


  &--danger {
    border: 2px solid $c-notification-red;

    &:hover, &:focus {
      color: $c-white !important;
      background-color: $c-notification-red !important;
      @include icon-color($c-white);
      @include transition; } }

  &:hover, &:focus {
    @include transition;
    @include scale;
    color: $c-darkblue;
    background-color: $c-turquoise;
    padding-top: 0;
    padding-bottom: 0;

    &.button--club {
      background-color: $c-white;
      color: $c-darkblue;
      border: 2px solid $c-darkblue;
      @include transition;

      &.button--secondary {
        background-color: $c-darkblue;
        color: $c-white;

        .icon {
          @include icon-color($c-white); } }

      &.button--bg-white {
        background-color: $c-darkblue;
        color: $c-white;
        border: 2px solid $c-white; } }

    &.button--club-link {
      background-color: $c-white !important;
      color: $c-darkblue !important;
      transform: scale(1);
      border: 2px solid $c-turquoise !important;

      &.button--gold {
        border: 2px solid $c-gold !important; }

      .button__bubble {
        width: 100%;
        @include transition;

        > p {
          transform: translateY(-51%) translateX(0);
          top: 50%;
          @include transition; } } } }


  &--noOutline {
    background-color: transparent;
    border: none;

    &.button--white {
      color: $c-white; }

    &:hover, &:focus {
      color: $c-turquoise;
      background-color: transparent;
      width: 180px;

      .icon {
        @include transition;

        svg use {
          @include transition; } } } }

  &--link {
    background-color: transparent;
    margin: 0;
    width: max-content;
    padding: 0;
    height: unset;
    line-height: unset;
    font-weight: normal;
    border: none;
    text-decoration: underline;
    color: $c-black;

    &:hover, &:focus {
      transform: none;
      color: $c-orange;
      background-color: transparent;
      width: max-content;
      height: unset;
      line-height: unset; } }

  &--inactive {
    cursor: unset;
    pointer-events: none;
    color: $c-brightgrey;
    background-color: $c-lightgrey;
    border-color: $c-brightgrey;

    &.button--secondary {
      background-color: unset; }

    &:hover, &:focus {
      width: 180px;
      color: $c-brightgrey;
      border-color: $c-brightgrey;
      background-color: $c-lightgrey; } }

  &--has-icon {
    padding-left: 48px;
    //padding-left: 35px
    padding-right: 16px;

    .icon {
      width: 25px;
      height: 25px;
      position: absolute;
      top: 50%;
      left: 18px;
      transform: translateY(-50%);
      @include transition; } }

  &--icon-only {
    padding-left: 15px;

    .icon {
      left: 10px; } } }

.button + .button {
  margin-left: 24px; }

.gloria-rewards-app {
  .button {
    &--primary {
      &.button--white {
        color: $c-white;
        background-color: $c-black; } }

    &--secondary {
      border: 2px solid $c-black;

      &:hover, &:focus {
        background-color: $c-black;
        color: $c-white;

        svg use {
          fill: $c-white; } } } } }
