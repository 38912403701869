.contact {
  position: relative;
  padding: 0;

  &__header {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    @include drop-shadow;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
    background-color: $c-white;
    @include transition;

    @include mq($until: mobile) {
      width: 80px;
      height: 80px;
      @include transition; }

    .icon {
      @include icon-size(49px);
      @include icon-color($c-darkred);
      @include transition;

      @include mq($until: mobile) {
        @include icon-size(38px);
        @include transition; } }

    .lottie__uncontrolled {
      width: 60px;
      height: 60px;
      z-index: 10;
      @include transition;

      @include mq($from: mobile) {
        width: 90px;
        height: 90px;
        @include transition; } } }

  &__content {
    padding: 90px 130px;
    background-color: $c-lightgrey;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;

    @include mq($until: tablet) {
      padding: 60px 44px;
      @include transition; }

    @include mq($until: phone) {
      padding: 60px 10px;
      @include transition; } }

  &__choose-reason, &__form, &__telephone {
    max-width: 780px;
    width: 100%;
    width: stretch;
    background-color: $c-white;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;

    .selectfield__selected {
      background-color: $c-lightgrey; }

    .login__row + .login__row {
      margin-top: 40px; } }

  &__form {
    position: relative;

    .login__columns--wrapper {
      display: flex;
      flex-direction: column; }

    .button {
      margin-left: auto;
      max-width: 180px;
      width: stretch;
      margin-top: 40px; } }

  &__choose-reason {
    position: relative;

    h3 {
      padding-bottom: 40px; } }

  &__telephone {
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq($until: mobile) {
      flex-direction: column; }

    h4 {
      max-width: 420px;
      width: stretch;
      text-align: center;

      @include mq($until: mobile) {
        max-width: unset; } }

    &-details {
      display: flex;

      @include mq($until: mobile) {
        margin-top: 20px; }

      h3 {
        text-decoration: underline; }

      .icon {
        @include icon-size(24px);
        margin-right: 10px; } } } }
