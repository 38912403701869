.happy-praemien-header__section {
  background-image: url("/images/app/happy-praemien/img--bg-happy-praemien.jpg");
  background-position: bottom;
  min-height: 412px;
  overflow: hidden;
  @include transition;

  @include mq($from: mobile) {
    min-height: 780px; }
  @include mq($from: tablet) {
    min-height: 310px;
    @include transition; }

  // BEGIN section open
  &.app-header__section--open {
    height: auto;
    @include transition;

    .app-header__box {
      width: 550px;
      height: 745px;
      bottom: 0;
      left: 50%;
      top: auto;
      @include transition;

      @include mq($until: tablet) {
        width: 398px;
        left: 150%; }

      @include mq($until: mobile) {
        width: 222px;
        height: unset;
        left: 150%; } } }


  // END section open

  .app-header__box {
    bottom: -710px;
    //top: -320px
    width: 900px;
    height: 1350px;
    line-height: 0;
    left: 34%;
    transform: translateX(-5%);
    margin-bottom: 0;
    display: block;
    z-index: 1;
    @include transition;

    .image {
      width: unset;
      max-height: unset; }

    @include mq($until: tablet) {
      bottom: 0;
      width: 399px;
      height: 590px;
      left: 50%;
      transform: translateX(-50%); }

    @include mq($until: mobile) {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 222px; } }

  @include mq($until: tablet) {
    &.app-header__section--open {

      .app-header__left {
        align-items: center;

        h3 {
          margin-bottom: 20px; }

        h3, p {
          max-width: 480px;
          display: block; } } } } }

.happy-praemien-counter__section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $c-darkblue;
  flex-direction: column;
  position: relative;

  .app-header__actions--mobile {
    position: absolute;
    top: 0;
    transform: translateY(-50%); }

  @include mq($from: tablet) {
    display: none; } }
