.radio-button {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &__checkmark {
    background-color: $c-white;
    position: absolute;
    top: 0;
    left: 0;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 2px solid $c-brightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    .radio-button__container--disabled & {
      background-color: $c-lightgrey; }

    .radio-button__container--small & {
      width: 18px;
      height: 18px; } }

  &__checkmark .icon {
    display: none;
    pointer-events: none;
    @include icon-color($c-blue);
    @include icon-size(16px);

    .radio-button__container--small & {
      @include icon-size(10px); } }


  &:checked ~ &__checkmark .icon {
    display: block; }

  &__container {
    position: relative;
    padding-left: 36px;
    cursor: pointer;
    max-width: max-content;
    user-select: none;
    @include fontsize(12px, 26px);
    min-height: 20px;
    color: $c-black;
    display: flex;
    align-items: center;

    &--small {
      padding-left: 28px;
      @include fontsize(12px, 18px); }

    .styleguide > & {
      margin-bottom: 20px; }

    form & {
      margin-top: 40px; }

    form &:not(:first-of-type) {
      margin-top: 10px; }

    &--disabled {
      color: $c-brightgrey;

      .icon {
        @include icon-color($c-brightgrey); } } } }
