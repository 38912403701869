.checkout {

  &__container {
    display: flex;
    justify-content: space-between;

    @include mq($until: mobile) {
      flex-direction: column-reverse; }

    &--step-2 {
      @include mq($until: tablet) {
        flex-direction: column; } } }

  &__form {
    padding: 20px;

    .login__col {
      display: flex;
      flex-direction: column;

      .button {
        margin-top: auto; } }

    &-header {
      @include fontsize(24px, 1.16);
      font-weight: bold;
      margin-bottom: 40px;
      display: block; }

    &-actions {
      display: flex;
      justify-content: center;
      margin-top: 60px;

      & > .button--primary {
        width: max-content;
        padding: 0 20px; } }

    form {
      display: flex;
      flex-wrap: wrap; } }

  &__center {
    max-width: 488px;
    background-color: $c-white;
    border-radius: 8px;
    width: stretch;
    height: max-content;
    margin: 0 auto;
    padding: 20px;
    //+drop-shadow

    h3 {
      margin-bottom: 20px; }

    p {
      margin-bottom: 40px; }

    &--error {
      h3 {
        color: $c-notification-red;
        font-weight: bold; } }

    &-actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      a {
        position: relative;

        &:last-of-type {
          margin-top: 10px; }

        .button {
          width: 280px;
          margin-left: 0; } } } }

  &__left {
    background-color: $c-white;
    border-radius: 8px;
    flex: 65 50;
    @include transition;

    &--focus:not(.checkout__right--shipping) {
      @include drop-shadow-darker;
      @include transition; }

    @include mq($until: tablet) {
      max-width: unset;
      margin-bottom: 20px; }

    .checkout__container--step-1 & {
      display: none;

      @include mq($from: mobile) {
        display: block; } }

    &--mobile {
      display: block;
      margin-bottom: 10px;

      .checkout__form {
        background-color: $c-white;
        border-radius: 8px;
        margin-top: 20px;

        .button {
          white-space: break-spaces;
          padding: 0 10px;
          min-height: 48px;
          line-height: 1.23;
          height: unset;
          min-width: unset; } }

      .accordion__items {
        padding: 0; }

      .accordion--active > .accordion__items {
        max-height: 70em;
        padding: 0; }

      @include mq($from: mobile) {
        display: none; } }

    div.order-product {
      flex-wrap: wrap;
      min-width: 570px;
      width: stretch;

      &:after {
        content: "";
        width: 100%;
        margin: 0 auto;
        padding-top: 20px;
        border-bottom: 1px solid $c-brightgrey; }

      &:last-of-type {
        &:after {
          border-bottom: 0; } } }

    &.order__left--unavailable {
      position: relative;

      .order-product--available {
        opacity: .2; } } }

  &__right {
    position: relative;
    flex: 35 50;
    margin-bottom: 40px;
    @include transition;

    @include mq($from: mobile) {
      max-width: 280px;
      margin-left: 20px;
      margin-bottom: 0;
      @include transition; }

    @include mq($from: mobile) {
      @include mq($until: tablet) {
        .checkout__container--step-2 & {
          margin-left: auto;
          max-width: 380px; } } }

    @include mq($from: tablet) {
      margin-left: 20px;
      max-width: 280px; }


    @include mq($from: desktop) {
      max-width: 380px; }

    &--shipping {
      max-width: unset;
      margin-left: 0;

      @include mq($from: mobile) {
        @include transition;
        margin-right: 20px; } }

    .credit-box {
      margin-left: 20px;
      position: sticky;
      top: 100px; }

    .shipping-box {
      max-width: unset; } }

  //&__actions

  &__info {
    margin-bottom: 40px;
    display: none;

    @include mq($from: tablet) {
      display: block; } }

  &__list {
    padding: 20px 20px 0;

    div.cart-product {
      min-width: 570px;
      flex-grow: 100;
      flex-shrink: 100;
      width: 100%;
      padding: 0;
      margin-bottom: 20px;
      height: 100%;

      &:not(.cart-product--unavailable) {
        border-radius: 0;
        padding: 0 0 20px 0;
        margin-bottom: 20px;
        border-bottom: 1px solid $c-brightgrey; }

      &:last-of-type {
        margin-bottom: 0; }

      @include mq($until: tablet) {
        min-width: unset;
        height: unset;

        .cart-product__content {
          padding-left: 10px; }

        .cart-product__quantity {
          position: relative; } } } }

  &__shipping-actions {
    display: block;

    .button {
      margin-left: 0;
      width: stretch;

      &--danger.button--active {
        margin-top: 10px; } } }

  &__summary {
    @include fontsize(24px, 1.16);
    font-weight: bold;
    padding: 10px 0;
    display: flex;
    justify-content: space-between; }

  &__amount {
    display: block;
    margin-right: 20px;

    @include mq($from: mobile) {
      display: inline-block; } }

  &__sum {
    display: block;
    margin-right: 0;
    @include transition;

    .c-orange {
      display: inline-block; }

    @include mq($from: mobile) {
      display: inline-block; } }

  &__submit-box {
    margin-top: 20px;
    padding: 20px;
    width: 100%;
    background-color: $c-white;
    border-radius: 8px;
    @include drop-shadow;
    @include mq($from: mobile) {
      max-width: 380px;
      box-sizing: border-box; }

    .checkbox__container {
      position: relative;

      a:hover, a:focus {
        color: $c-orange;
        @include transition; } }

    .button--primary {
      margin: 20px auto 0 auto;
      display: block;
      max-width: 280px;
      width: stretch; }

    @include mq($until: mobile) {
      position: fixed;
      z-index: 70;
      top: unset;
      bottom: 0;
      left: 0;
      margin: 0;
      min-height: unset;
      padding: 20px;
      box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.2);
      border-radius: 0; } }

  &__column {
    position: sticky;
    top: 100px; } }
