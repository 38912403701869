html,
html input {
  font-family: 'Titillium', sans-serif !important; }

html.oldie,
html.oldie input {
  font-family: 'TitilliumIE', sans-serif !important; }

body, html, #root {
  min-height: 100vh;
  width: 100%;
  margin: 0; }

sup {
  vertical-align: super;
  font-size: smaller; }

#root {
  display: flex; }

body {
  background-color: $c-background;
  color: $c-black;
  min-width: 320px; }

a {
  text-decoration: underline;
  color: $c-black;
  @include transition; }

input {
  &:focus, &:active {
    outline: none;
    border: 2px solid $c-brightgrey !important; } }

*:focus, *:active {
  outline: none; }

*::-webkit-scrollbar {
  -webkit-appearance: none; }

*::-webkit-scrollbar:vertical {
  width: 11px; }

*::-webkit-scrollbar:horizontal {
  height: 11px; }

*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba($c-grey, .5); }

.c-orange {
  color: $c-orange; }

strong {
  font-weight: bold; }

.linklist {

  &__seperator {
    @include fontsize(12px, 1.33); }

  a {
    color: $c-black;
    @include fontsize(12px, 1.33);
    margin-right: 5px;
    text-decoration: none;

    &:not(:first-of-type) {
      margin-left: 5px; }

    &:hover {
      text-decoration: underline;
      color: $c-orange;
      @include transition; } } }

b {
  font-weight: bold; }

.navbar-margin {
  margin-top: 60px !important; }

.mainContainer {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $c-background;

  &--center {
    @include mq($from: mobile) {
      justify-content: center; } } }

.content {
  padding-left: 130px;
  padding-right: 130px;
  height: max-content;
  margin: auto;
  padding-top: 20px;
  overflow: visible;
  @include transition;

  @include mq($until: tablet) {
    padding-left: 60px;
    padding-right: 60px;
    @include transition; }

  @include mq($until: mobile) {
    padding-left: 20px;
    padding-right: 20px;
    @include transition; }

  &--rules {
    max-width: 580px;
    width: stretch;
    margin: 40px auto 60px;

    img {
      width: 100%; } }

  &.app {
    padding: 0; }

  &.wishlist, &.cart {
    @include mq($until: desktop) {
      padding-left: 44px;
      padding-right: 44px;
      @include transition; }

    @include mq($until: mobile) {
      padding-left: 20px;
      padding-right: 20px;
      @include transition; } }

  &.cart {
    @include mq($until: phone) {
      padding: 0;
      margin-top: 0; } }

  &.cart, &.wishlist {
    margin-top: 40px;
    overflow: visible; }

  &.checkout {
    background-color: $c-lightgrey;
    margin-top: 0;
    padding-top: 0;
    position: relative;
    padding-bottom: 40px;
    overflow: visible;
    @include mq($until: phone) {
      padding: 0 10px 80px 10px; } }

  &.shop-home,
  &.private {
    padding: 0; }

  &.private, &.news {
    overflow: visible; }

  &.orders {
    margin: 0;
    padding: 20px 10px 20px 10px;
    overflow: visible;

    @include mq($from: mobile) {
      padding: 20px 44px; } } }

.container {
  background-color: $c-white;
  position: relative;

  &--full-height {
    height: 100%; }

  &--cart, &--wishlist, &--orders {
    background-color: $c-lightgrey; }

  @include mq($until: mobile) {
    &--wishlist, &--cart {
      margin-bottom: 80px;

      & ~ .section__card-slider {
        display: none; } } }

  &--checkout, &--orders {
    padding-bottom: 100px; }

  @include mq($until: mobile) {
    margin-top: 48px; }

  @include mq($from: mobile) {
    &--scroll {
      margin-top: 60px; } } }


.styleguide {
  padding: 24px;
  width: 100%;

  h2 {
    margin: 34px 0; } }

.center-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

.center-text {
  text-align: center; }


.section {

  &__card-slider {
    padding: 60px 130px 100px 130px;
    background-color: $c-white;
    @include transition;

    @include mq($until: tablet) {
      padding: 60px 44px 100px 44px;
      @include transition; }

    @include mq($until: phone) {
      padding: 20px 0 20px 20px;
      @include transition; } } }

.empty-page ul li {
  margin-top: 60px; }

.m-t-auto {
  margin-top: auto; }

.oldie .oldie-hide {
  display: none; }

.anchor {
  height: 0;
  line-height: 0; }
//visibility: hidden
