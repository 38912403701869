.button-group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: fit-content;
  border-radius: 32px;
  background-color: $c-darkblue;
  position: relative;
  z-index: 5;

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background-color: $c-turquoise;
    height: 32px;
    width: 108px;
    border-radius: 16px; }

  &-button {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    & + label {
      float: left;
      height: 32px;
      width: 108px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #fff;
      @include fontsize(16px, 1.375);
      font-weight: bold;
      border-radius: 16px;
      z-index: 2;
      justify-content: center;

      &:first-of-type {
        border-radius: 16px; }

      &:last-of-type {
        border-radius: 16px; } }

    &:checked + label {
      color: $c-black;
      @include transition; } }

  input:checked:first-of-type ~ .button-group__background {
    transform: translateX(-1px);
    @include transition; }

  input:checked:nth-of-type(2) ~ .button-group__background {
    transform: translateX(108px);
    @include transition; }

  input:checked:nth-of-type(3) ~ .button-group__background {
    transform: translateX(217px);
    @include transition; }


  .button + .button {
    margin: 0; } }
