.styleguide {

  .micro-menu {
    margin-left: 200px; } }

//#navbar__micro-menu
//  display: block

//.container--scroll &
//  display: none

.navbar {

  &__micro-user {
    //transform: translate(-140px, 0px)
    position: absolute;
    right: 0;
    top: 0;
    border-bottom-left-radius: 8px;
    @include transition;
    @include drop-shadow;

    @include mq($until: mobile) {
      display: none; }

    .navbar__container--scroll & {
      transform: translate(-140px, 0px); }

    .navbar__container--scrolling-up & {
      transform: translate(0, 0);
      border-bottom-right-radius: 8px;
      box-shadow: none !important;
      @include transition; }

    .navbar__user-bar {
      height: 48px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      @include transition;

      .navbar__container--scroll & {
        border-bottom-right-radius: 0; }

      .navbar__container--scrolling-up & {
        border-bottom-right-radius: 8px;
        @include transition; } } }


  &__micro-menu {
    background-color: $c-white;
    height: 48px;
    width: 140px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    position: relative;
    @include transition;

    @include mq($until: mobile) {
      border-bottom-left-radius: 8px; }

    @include mq($from: mobile) {
      .navbar__container--scroll & {
        border-bottom-left-radius: 0; }
      .navbar__container--scrolling-up & {
        border-bottom-left-radius: 8px; } }


    &-collector {
      transform: translate(-182px, 60px);
      position: absolute;
      right: 0;
      top: 0;
      z-index: 100;
      @include transition;

      @include mq($until: tablet) {
        transform: translate(-96px, 60px); }

      @include mq($until: mobile) {
        transform: translate(-20px, 60px); }

      @include mq($until: mobile) {
        transform: translate(-53px, 0px);
        @include transition;

        &--mobile-open {
          transform: translate(-53px, 48px);
          @include transition; } }

      @include mq($until: phone) {
        &--cart-open {
          right: unset;
          left: 0;
          transform: none;
          transition: none; } } }

    &__wrapper {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
      z-index: 100;
      border-bottom-right-radius: 8px;
      @include transition;

      @include mq($until: mobile) {
        border-bottom-left-radius: 8px;
        box-shadow: none;

        .navbar__micro-menu-collector--mobile-open & {
          @include drop-shadow; } }

      @include mq($from: mobile) {
        .navbar__container--scrolling-up & {
          border-bottom-left-radius: 8px;
          @include transition; } }

      @include mq($until: phone) {
        .navbar__micro-menu-collector--cart-open & {
          box-shadow: none; } }

      &--fixed {
        position: sticky;
        display: none;

        .container--scroll & {
          display: block;
          right: 0;
          top: 0;
          transform: none; } } }

    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      height: 100%; }

    &__notification {
      display: block;
      width: 10px;
      height: 10px;
      background-color: $c-notification-red;
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;

      &--menu {
        top: 7px;
        right: -7px; } }

    &__item {
      width: 36px;
      height: 36px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: initial;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      cursor: pointer;

      .icon, a {
        @include icon-size(20px); }

      &--active .icon {
        @include icon-color($c-orange); }

      &:hover > .icon, a:hover > .icon,
      &:focus > .icon, a:focus > .icon {
        @include icon-color($c-orange); }

      &:hover > .micro-menu__flyout {
        display: flex;
        opacity: 1;
        transform: translateX(calc(-100% - 25px));
        @include transition; } }

    &__flyout {
      box-shadow: 0 2px 8px rgba($c-black, 0.16);
      position: absolute;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: max-content;
      height: max-content;
      max-height: 55px;
      background-color: $c-orange;
      color: $c-white;
      left: 100%;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      padding: 4px 25px 4px 10px;
      display: flex;
      opacity: 0;
      z-index: -1;
      @include transition;

      span {
        display: block;
        color: $c-white;

        a {
          color: $c-white;

          &:hover {
            color: $c-black; } }

        &:first-of-type {
          @include fontsize(20px, 24px); } } }

    &__account-points {
      font-weight: bold; } } }


