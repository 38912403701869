.highperformance-news, .highperformance-links {

  &__section {
    height: 487px;
    @include gradient-gold;
    margin: 40px 0 40px;
    position: relative;
    padding-left: 10px;
    padding-bottom: 40px;
    @include transition;

    .gewinnerreise &, .praemienshopclub & {
      background: $c-darkblue; }

    @include mq($from: mobile) {
      margin: 80px 0 60px;
      height: 461px;
      padding-left: 44px;
      padding-bottom: 0;
      @include transition;

      .gewinnerreise & {
        margin: 60px 0; } }

    @include mq($from: tablet) {
      padding-left: 0px;
      @include transition; } }

  &__header {
    width: 100%;
    min-height: 80px;
    height: 80px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    top: 32px;
    position: absolute;
    text-align: center;
    max-width: 156px;
    @include transition;

    .gewinnerreise &, .praemienshopclub & {
      color: $c-white; }

    @include mq($from: phone) {
      max-width: 250px; }

    @include mq($from: mobile) {
      max-width: 580px;
      text-align: left;
      top: -40px;
      @include gradient-gold;
      @include transition;

      .gewinnerreise & {
        top: auto;
        background: none; } } }

  &__content {
    width: 100%;
    padding: 120px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: auto;
    @include transition;

    @include mq($from: mobile) {
      padding: 80px 0 0;
      @include transition; }

    .highperformance-links__section & {
      padding: 40px 0 0;
      @include mq($until: mobile) {
        overflow: hidden; } } }

  &__slider {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 10px 0 0;
    margin: 0 auto;

    .highperformance-links__section & {
      padding: 32px 0 0;
      @include mq($until: mobile) {
        flex-direction: column; } } }

  &__box {
    width: 280px;
    height: 232px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 40px 20px 40px;
    box-sizing: border-box;
    background-color: $c-white;
    margin-right: 20px;
    position: relative;
    @include transition;

    @include mq($from: mobile) {
      padding: 40px 20px 20px;
      height: 243px;
      @include transition; }

    .highperformance-links__section & {
      padding: 0;
      width: 380px;
      height: auto;
      @include transition;

      @include mq($until: mobile) {
        width: 300px;
        margin: 65px 0 0;
        @include transition;
        &:first-of-type {
          margin-top: 0; } } }

    &-header {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      background-color: $c-white;
      border-radius: 8px;
      color: $c-black;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 240px;
      height: 65px;
      @include transition;

      @include mq($until: mobile) {
        width: 200px;
        height: 42px;
        @include transition;

        & > h3 {
          @include fontsize(22px, 22px); } } }

    &-lower {
      width: stretch;
      height: 100px;
      background-color: $c-gold;
      padding: 20px;
      box-sizing: border-box;
      position: relative;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      @include transition;

      @include mq($until: mobile) {
        height: 86px;
        @include transition; } }

    &-readmore {
      position: absolute;
      right: 20px;
      top: 27px;
      border-radius: 50%;
      background-color: $c-white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 46px;
      height: 46px;
      cursor: pointer;
      @include transition;
      @include mq($until: mobile) {
        top: 20px;
        @include transition; }

      &:hover, &:focus {
        @include scale;
        @include transition;
        @include drop-shadow; }

      .icon {
        @include icon-size(22px);
        left: 2px; } }

    &-image {
      width: stretch;
      height: 240px;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;
      overflow: hidden;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      line-height: 0;
      @include transition;

      @include mq($until: mobile) {
        height: 133px;
        @include transition; }

      img, .image {
        object-fit: cover;
        width: 100%;
        height: 100%; } }

    &:last-of-type {
      margin-right: 0;
      margin-bottom: 40px;

      @include mq($from: mobile) {
        margin-bottom: 0; } }


    & > a, & > a .button {
      margin-top: auto;
      width: 100%; }

    &--new {
      background-color: $c-darkblue;
      color: $c-white;

      .gewinnerreise & {
        background-color: $c-blue; }

      .praemienshopclub & {
        color: $c-black;
        @include gradient-gold; } }

    & > h3 {
      @include fontsize(28px, 32px);
      max-height: 64px;
      overflow: hidden;
      @include transition;

      @include mq($from: mobile) {
        @include fontsize(32px, 40px);
        max-height: 80px;
        @include transition; } } }

  &__new {
    width: 86px;
    height: 36px;
    background-color: $c-white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $c-black;
    font-weight: bold;
    position: absolute;
    top: 0;
    right: 10px;
    transform: translateY(-25%); }

  &__actions {
    width: 100%;
    display: flex;
    margin-top: 32px;
    justify-content: center;

    @include mq($from: mobile) {
      margin-top: 40px; } } }

.highperformance-links__section {
  padding-left: 44px;
  background: $c-darkblue;
  margin-top: 60px;

  @include mq($until: mobile) {
    height: auto;
    padding-left: 0; }

  @include mq($from: tablet) {
    padding: 0; } }

.highperformance-news__section + .highperformance-links__section {}
//margin-top: 40px
