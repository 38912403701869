.news {
  position: relative;
  padding: 0;

  &__empty {
    padding: 60px 40px 60px 40px;

    @include mq($until: mobile) {
      padding: 40px 20px 40px 20px; } }

  &__header {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    @include drop-shadow;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
    background-color: $c-white;
    @include transition;

    @include mq($until: mobile) {
      width: 80px;
      height: 80px;
      @include transition; }

    .icon {
      @include icon-size(49px);
      @include icon-color($c-darkred);
      @include transition;

      @include mq($until: mobile) {
        @include icon-size(38px);
        @include transition; } }

    .lottie__uncontrolled {
      width: 60px;
      height: 60px;
      @include transition;
      @include mq($from: mobile) {
        width: 90px;
        height: 90px;
        @include transition; } } }

  &__content {
    padding: 90px 130px;
    background-color: $c-lightgrey;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;

    @include mq($until: tablet) {
      padding: 60px 44px;
      @include transition; }

    @include mq($until: phone) {
      padding: 60px 10px;
      @include transition; } } }
