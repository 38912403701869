.product-view {
  margin-top: 20px;

  @include mq($from: tablet) {
    margin-top: 60px; }

  &__container {
    display: flex;
    flex-direction: column; }

  &__upper {
    margin-bottom: 40px;

    @include mq($from: mobile) {
      display: flex; }
    @include mq($from: tablet) {
      margin-bottom: 80px; } }

  &__left {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    height: max-content;

    @include mq($from: mobile) {
      width: auto;
      flex-grow: 100;
      margin-right: 20px; }

    @include mq($from: tablet) {
      flex: 100 100 280px;
      width: max-content;
      margin-right: 40px;
      margin-bottom: 0; } }

  &__gallery {
    max-height: max-content;
    display: flex; }

  &__slider {
    position: relative;

    &-count {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: $c-lightgrey;
      padding: 7px 10px 5px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      @include fontsize(12px, 1.33);
      color: $c-grey; } }

  &__image {
    opacity: .3;
    display: block;
    cursor: pointer;
    height: 106px;
    border: 1px solid $c-brightgrey;
    border-radius: 8px;
    width: 80px;
    overflow: hidden;

    .image {
      display: flex;
      background-color: $c-white;
      padding: 12px;
      height: 100%;
      box-sizing: border-box;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        align-self: center;

        .oldie & {
          height: auto; } } }

    &--selected {
      opacity: 1; }

    &-row {
      display: none;

      @include mq($from: tablet) {
        max-width: 100px;
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        height: max-content;

        &-wrapper {
          max-height: 632px;
          padding-right: 20px;
          overflow-y: auto;
          overflow-x: hidden;

          & > span:not(:first-of-type) {
            margin-top: 10px; } } } }

    &-big {
      border: 1px solid $c-brightgrey;
      background-color: $c-white;
      border-radius: 8px;
      position: relative;
      display: none;
      cursor: pointer;

      @include mq($from: tablet) {
        max-width: 480px;
        max-height: 632px;
        height: 40vw;
        min-height: 316px;
        display: flex; }

      &--mobile {
        display: block;
        max-height: 520px;
        height: stretch;
        max-width: 332px;
        width: stretch;

        @include mq($from: tablet) {
          display: none; } }

      &--single {
        height: 520px;
        max-width: 332px;
        display: table-cell !important;
        vertical-align: middle !important;
        width: stretch;
        @include transition; }

      .image {
        padding: 24px;
        box-sizing: border-box;
        align-self: center;
        height: 100%;
        @include transition;

        @include mq($until: phone) {
          padding: 12px;
          @include transition; }

        img {
          max-height: 618px;
          width: 100%;
          height: 100%;
          object-fit: contain;

          .oldie & {
            height: auto;
            max-height: 100%;
            top: 50%;
            position: relative;
            transform: translateY(-50%); } } } } }

  &__more-below {
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .icon {
      margin: 0 auto;
      display: block;
      transform: rotate(90deg);
      left: -10px;
      @include icon-color($c-brightgrey); } }

  &__right {
    position: relative;
    flex-shrink: 100;

    @include mq($from: tablet) {
      flex: 100 100 300px; } }

  .product-headline {
    margin-bottom: 10px; }

  .details {

    &__category {
      @include fontsize(12px, 1.33);
      margin-bottom: 10px; }

    &__id {
      @include fontsize(16px, 1.5);
      display: block;
      color: $c-grey;
      margin-bottom: 10px; }

    &__price {
      height: 32px;
      font-weight: bold;
      color: $c-white;
      background-color: $c-orange;
      border-radius: 8px;
      margin-bottom: 20px;
      padding: 3px 10px;
      display: inline-block;
      @include fontsize(18px, 32px); }

    &__variants .product__availability {
      margin-bottom: 20px;

      @include mq($from: tablet) {
        margin-bottom: 40px; } }

    &__variant-label {
      display: block;
      margin-bottom: 10px; }

    &__variant-selector {
      cursor: pointer;
      opacity: .3;
      display: inline-block; }

    &__variant-container {
      position: relative;
      margin-bottom: 20px;
      display: block;

      .image {
        display: flex;
        width: 60px;
        height: 80px;
        border-radius: 8px;
        margin-right: 10px;
        background-color: $c-white;
        border: 1px solid $c-brightgrey;
        padding: 4px;
        box-sizing: border-box;

        img {
          width: 100%;
          align-self: center;
          max-height: 75px;
          margin-right: auto;
          margin-left: auto;
          object-fit: contain; } } }

    &__actions {
      display: flex;
      //margin-bottom: 80px
      width: 100%;
      justify-content: flex-start;

      @include mq($from: tablet) {
        margin-bottom: 80px; }

      &--left {
        position: relative;
        margin-right: 20px;

        @include mq($from: tablet) {
          flex: 1; }

        .selectfield__wrapper {
          width: 131px;

          @include mq($from: tablet) {
            width: 80px; } }

        .selectfield__selected {
          background-color: $c-lightgrey; } }

      &--right {
        display: none;

        @include mq($from: tablet) {
          display: flex;
          flex: 4; }

        &--mobile {
          display: flex;
          margin-top: 20px;
          //margin-bottom: 80px

          .button {
            max-width: 280px; }

          @include mq($from: tablet) {
            display: none; } } }

      &--right, &--right--mobile {
        position: relative;

        .button {
          flex: 1;
          min-width: 240px;
          padding-left: 0;
          padding-right: 0;
          margin-bottom: 10px;
          margin-right: 10px; } } }

    &__description {
      display: none;
      position: relative;
      max-height: 480px;
      overflow: hidden;
      @include transition;

      @include mq($from: tablet) {
        display: block; }

      &--mobile {
        display: block;

        @include mq($from: tablet) {
          display: none; }

        &__wrapper {
          position: relative;
          margin-bottom: 40px; } }

      &--open {
        max-height: 800px;
        @include transition;

        & > .read-more {
          opacity: 0;
          @include transition; }

        & > p {
          display: block;
          max-height: 660px;
          overflow-y: auto; }

        & ~ .read-more__action .icon {
          transform: translate(-50%, 40px) rotate(-90deg);
          @include transition; } }

      p strong {
        padding-top: 40px;
        display: block;
        @include fontsize(18px, 1.22);
        font-weight: bold; }

      h3 {
        margin-bottom: 40px; } } }


  .variant--selected {
    opacity: 1; }

  .read-more {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    height: 82px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $c-white);
    @include transition;

    &__action {
      display: none;
      cursor: pointer;
      position: absolute;
      left: 50%;
      bottom: -8px;
      padding-top: 20px;

      @include mq($from: tablet) {
        display: block; }

      &--mobile {
        display: block;

        @include mq($from: tablet) {
          display: none; } }

      .icon {
        transform: translate(-50%, 40px) rotate(90deg);
        @include transition; } } }


  &__lower {
    margin-bottom: 100px;
    @include mq($until: tablet) {
      padding-top: 40px; } } }
