.textarea {
  width: stretch;
  max-width: 100%;
  min-height: 240px;
  box-sizing: border-box;
  //margin-bottom: 12px

  &__label {
    @include fontsize(12px, 1.33);
    display: block;
    color: $c-grey;
    margin-bottom: 6px;
    margin-left: 20px; }

  &__input {
    padding: 16px 16px 16px 20px;
    width: stretch;
    height: 250px;
    box-sizing: border-box;
    border: none;
    color: $c-black;
    background: $c-lightgrey;
    border-radius: 8px;
    resize: none;
    @include fontsize(18px, 1.33);

    &::placeholder {
      color: $c-grey; }

    .textarea--disabled & {
      pointer-events: none;
      color: $c-grey;

      &::placeholder {
        color: $c-grey; } } }

  &--error input {
    border: 2px solid $c-notification-red;
    color: $c-notification-red; }

  &__errormsg {
    margin: {
      top: 8px;
      left: 20px;
      bottom: 0; }
    color: $c-notification-red;
    @include fontsize(12px, 1.33); } }

.textarea + .textarea, h2 + .textarea, .selectfield__container + .textarea {
  margin-top: 40px; }
