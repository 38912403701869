.order-item {
  background-color: $c-white;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px;
  flex-wrap: wrap;
  min-height: 59px;
  //max-width: 880px

  .orders--detail & {
    max-width: unset; }

  @include mq($from: mobile) {
    flex-wrap: unset; }
  @include mq($from: tablet) {
    min-height: 108px;
    padding: 20px; }

  &__shipping {
    @include fontsize(16px, 1.5); }

  &__wrapper {
    display: flex;
    flex-grow: 1;
    padding-left: 10px; }

  &__image {
    display: block;
    overflow: hidden;
    max-width: 32px;
    width: stretch;
    max-height: 70px;
    @include transition;

    .orders--detail & {
      max-width: 40px; }

    @include mq($from: tablet) {
      max-width: 70px;
      @include transition; }

    .icon {
      @include icon-size(32px);
      @include transition;

      .orders--detail & {
        @include icon-size(40px); }


      @include mq($from: tablet) {
        @include icon-size(68px);
        @include transition; } } }


  &__row {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    padding-right: 10px;
    padding-left: 10px;
    @include transition;

    @include mq($from: tablet) {
      justify-content: center;
      padding-left: 20px;
      @include transition; } }

  &__title {
    font-weight: bold;
    @include fontsize(18px, 1.22);
    word-break: break-all;

    @include mq($from: mobile) {
      padding-bottom: 5px;
      @include fontsize(24px, 1.16); } }

  &__detail {
    @include fontsize(16px, 1.5); }

  &__details {
    min-width: 170px;
    padding-left: 52px;
    padding-top: 5px;
    @include mq($from: mobile) {
      padding-left: 0; } }

  &__price {
    @include fontsize(24px, 1.16);
    font-weight: bold;
    color: $c-orange;
    margin-bottom: 10px; } }

.order-item + .order-item {
  margin-top: 20px; }

