h1 {
  @include fontsize(32px, 1.31);
  font-weight: bold;
  @include transition;

  @include mq($from: mobile) {
    @include fontsize(48px, 1.08); }

  @include mq($from: tablet) {
    @include fontsize(86px, 1);
    @include transition; } }

h2 {
  @include fontsize(32px, 1.25);
  @include transition;
  text-transform: uppercase;

  @include mq($until: phone) {
    @include fontsize(22px, 1.27);
    @include transition; } }

h2.product-headline {
  text-transform: none;
  font-weight: bold;
  @include fontsize(32px, 1.25); }

h3 {
  @include fontsize(24px, 1.16);
  font-weight: bold; }

h4 {
  @include fontsize(18px, 1.22);
  font-weight: bold; }

h5 {
  @include fontsize(16px, 1.375);
  font-weight: bold; }

p {
  @include fontsize(16px, 1.5); }

p.infotext {
  margin: 10px 0 0 20px;
  font-weight: bold;

  &.error {
    color: $c-notification-red; } }


