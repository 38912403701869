.promobooster-header__section {
  background-image: url("/images/app/promo-booster/img--bg-bluehouse.jpg");
  background-position: bottom;
  min-height: 412px;
  @include transition;

  &--access {
    //min-height: 540px

    & > p {
      display: block; }

    .app-header__content {
      flex-direction: column; }

    .app-header__heading {
      z-index: 10;

      p {
        max-width: 480px;
        display: block;
        margin-bottom: 30px;
        max-height: 0;
        overflow: hidden;
        @include transition; } }

    .app-header__box {
      right: 195px;
      left: auto;
      top: 80px;
      bottom: auto;
      height: 453px;
      width: 375px; }

    &.app-header__section--open .app-header__heading p {
      max-height: 40em;
      @include transition; } }

  &.app-header__section--open {
    height: auto;
    @include transition;

    .app-header__heading p {
      max-height: 40em; }

    @include mq($from: tablet) {
      .app-header__box {
        width: 797px;
        height: auto;
        bottom: -30px;
        right: 0; } }

    .promobooster-header__lower {
      justify-content: center; }

    @include mq($from: tablet) {
      .app-header__box {
        width: 375px;
        height: auto;
        right: 45%;
        transform: none;
        bottom: 20px;
        @include transition; } } }

  @include mq($from: mobile) {
    background-position: bottom center;
    min-height: 840px;
    &.app-header__section--open {
      height: 1080px;

      .promobooster-header__lower {
        flex-direction: column;
        align-items: center;

        @include mq($from: tablet) {
          flex-direction: row;
          align-items: flex-start; } } } }

  @include mq($from: tablet) {
    height: 840px;
    &.app-header__section--open {
      height: 840px; } }

  .app-header__content {
    flex-direction: column;

    @include mq($from: tablet) {
      justify-content: flex-start; }

    .promobooster-header__lower {
      display: flex;

      @include mq($from: mobile) {
        justify-content: center; }
      @include mq($from: tablet) {
        justify-content: space-between; } }

    .app-header__left {
      @include mq($from: tablet) {
        margin-right: 20px;
        @include transition;

        h3, p, .button {
          display: block;
          margin-bottom: 20px;
          @include transition; }

        .button {
          color: $c-black; } } } }

  .app-header__heading h1 {
    text-align: center; }

  .app-header__left p {
    max-height: 40em; }

  .app-header__actions {
    margin-top: 20px;
    display: block; }

  .app-header__box {
    z-index: 2;
    display: block;
    left: auto;
    right: 50%;
    bottom: 0;
    width: 168px;
    height: auto;
    transform: translateX(50%);
    @include transition;

    @include mq($from: mobile) {
      right: 100%;
      transform: translate(80%, 20%);
      width: 264px;
      height: auto;
      @include transition; }

    @include mq($from: tablet) {
      width: 375px;
      height: auto;
      right: 50%;
      transform: none;
      bottom: 20px;
      @include transition; }

    @include mq($from: desktop) {
      width: 375px;
      height: 100%;
      right: 50%;
      transform: none;
      bottom: 20px;
      @include transition; } }

  &--2 {
    min-height: 840px;
    height: auto;

    .app-header__content {
      padding: 12px 10px;
      @include mq($from: phone) {
        padding: 40px 20px; }
      @include mq($from: mobile) {
        padding: 60px 40px; }

      @include mq($from: tablet) {
        padding: 60px 130px; } }

    .app-header__box {
      left: 0;
      @include transition; } } }

.promobooster-header {

  &__upper, &__lower {
    z-index: 22; }

  &__lower {
    @include mq($from: mobile) {
      @include mq($until: tablet) {
        flex-wrap: wrap; } } } }

.promobooster-form__section {
  padding: 64px 20px;
  margin-bottom: 0;
  display: none;

  @include mq($from: mobile) {
    display: flex;
    padding: 40px 0;
    flex-direction: row;
    justify-content: center; }

  @include mq($from: tablet) {
    padding: 0; }

  .promobooster-form__box {
    color: $c-black;
    border-radius: 8px;
    background-color: $c-white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: max-content;
    box-sizing: border-box;

    @include mq($from: mobile) {
      width: 280px;
      min-height: 380px; }

    h3 {
      margin-bottom: 20px; }

    & > p {
      display: block; }

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include mq($from: mobile) {
        margin-top: auto; }

      .textfield {
        width: stretch;

        & + .textfield {
          margin-top: 20px; } }

      a {
        align-self: flex-start;
        margin-top: 20px; }

      .button {
        margin-top: 20px;
        max-width: 280px;
        width: stretch; } }

    &:not(:last-of-type) {
      margin-bottom: 40px;
      @include mq($from: mobile) {
        margin-right: 20px;
        margin-bottom: 0; } } }

  &--mobile {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    background-color: $c-darkblue;

    @include mq($from: mobile) {
      padding: 0;
      .promobooster-form__box {
        display: none; } }
    @include mq($from: tablet) {
      margin-bottom: 0; } } }

.promobooster-header__upper--signup {
  display: flex;
  justify-content: center;

  & > .login__columns {
    background: $c-white;
    color: $c-black;
    border-radius: 8px;
    padding: 40px;
    max-width: 653px;
    width: stretch;

    .login__columns-header {
      display: flex;
      margin-bottom: 40px;
      flex-direction: column;

      h3 {
        margin-bottom: 10px;

        @include mq($from: mobile) {
          margin-bottom: 0; } }

      @include mq($from: mobile) {
        flex-direction: row;
        justify-content: space-between; } }


    .login__info-container {
      height: 432px; }

    .login__row:last-of-type {
      margin-top: 40px; }

    .login__col {
      .checkbox__container {
        margin-top: 0; }

      &--action {
        display: flex;
        justify-content: center;

        .button {
          max-width: 280px;
          width: stretch; } } } }


  .splitview {
    flex-direction: row-reverse;
    border-radius: 8px;
    overflow: hidden;
    height: 676px;
    min-height: 0;

    @include mq($until: mobile) {
      flex-direction: column-reverse;
      height: auto; }

    &--sidebar {
      background-color: $c-darkblue;
      padding: 40px;
      box-sizing: border-box;

      .login__container--left {
        padding: 0;
        color: $c-white;

        .login__row + .login__row {
          margin-top: 30px; }

        .textfield {
          width: stretch;

          .textfield__label {
            color: $c-white; } }

        h3 {
          margin-bottom: 20px;
          @include transition;

          @include mq($from: mobile) {
            margin-bottom: 60px;
            @include transition; } }

        p {
          margin-bottom: 40px;
          @include fontsize(12px, 16px); } } }

    &--main {
      box-sizing: border-box;
      padding: 40px;
      background: $c-white;
      overflow-y: auto;
      @include mq($until: mobile) {
        display: flex; }

      h3 {
        color: $c-black;
        margin-bottom: 20px; }

      .login__container--right {
        width: stretch;

        .login__columns form {
          flex-direction: column;
          @include mq($from: mobile) {
            margin-bottom: 40px; } } }

      .login__row:last-of-type {
        justify-content: flex-end;

        .login__col {
          display: flex;
          justify-content: center; }

        .button {
          max-width: 280px;
          width: stretch; } } } } }
