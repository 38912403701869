.wishlist {

  &__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }

  &__left {
    margin-bottom: 40px;
    flex-shrink: 100;
    flex-grow: 100;
    //background-color: $c-white
    border-radius: 8px;

    div.cart-product {
      //flex-wrap: wrap
      min-width: 600px;
      flex-grow: 100;
      flex-shrink: 100;
      width: 100%;
      margin-bottom: 20px;

      @include mq($until: mobile) {
        min-width: unset;
        flex-wrap: wrap;
        height: unset;

        .cart-product__content {
          padding-left: 10px; }

        .cart-product__quantity {
          width: max-content;
          flex-direction: row-reverse;
          align-items: center;
          padding-top: 20px;

          .selectfield__container {
            width: 84px;
            margin-right: 10px; } } } }


    &.wishlist__left--unavailable {
      position: relative;

      .cart-product--available {
        opacity: .2; } } }

  &__right {
    position: relative;
    margin-left: 20px;

    @include mq($until: tablet) {
      margin: 0 auto; } }

  &__info {
    width: 310px;
    position: sticky;
    top: 120px;
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;

    @include mq($until: mobile) {
      width: 300px; }

    p {
      padding-top: 10px;
      @include fontsize(12px, 1.33); } }

  &__actions {
    width: auto;
    padding: 20px;
    background-color: $c-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 20px;
    box-sizing: border-box;
    @include drop-shadow;

    @include mq($until: mobile) {
      width: 300px; }

    .button {
      width: 100%;
      margin-left: 0;

      &:first-of-type {
        margin-bottom: 10px; } }

    .credit-box {
      margin-left: 20px;
      position: sticky;
      top: 120px; } }

  &__left--empty {
    max-width: 780px;
    max-height: 293px;
    width: 100%;
    margin: 0 auto 40px auto;
    background-color: $c-white;

    .button, a {
      width: 260px; } }

  &__empty {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 40px 40px 40px;

    h2 {
      width: max-content;
      margin: 0 auto 20px auto;
      display: flex; } }

  &__lottie-circle {
    position: absolute;
    left: 50%;
    top: 0;
    width: 100px;
    height: 100px;
    background-color: $c-white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -25%);
    @include drop-shadow;

    .lottie__uncontrolled {
      width: 90px;
      height: 90px; } }

  &__empty-actions {
    margin: 0 auto;
    flex-direction: column;
    display: flex;

    .button {
      min-width: max-content;
      width: stretch;
      max-width: 260px;

      &:first-of-type {
        margin-bottom: 10px; } } } }


