.styleguide {

  & > .credit-box {
    margin-left: 50px;
    border-radius: 8px; } }


.credit-box {
  width: 380px;
  min-height: 287px;
  background-color: $c-white;
  display: inline-flex;
  flex-direction: column;
  border-radius: 8px;
  @include drop-shadow;
  @include transition;

  @include mq($until: desktop) {
    width: max-content;
    @include transition; }

  &--insufficient {
    color: $c-notification-red; }

  &__content {
    display: flex;
    padding: 20px;
    flex-direction: column; }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px; }

  &__sum {
    @include fontsize(24px, 1.16);
    font-weight: bold;
    color: $c-orange;
    margin-left: 20px;

    .credit-box--insufficient & {
      color: $c-notification-red; }

    &-label {
      @include fontsize(24px, 1.16);
      font-weight: bold; } }

  &__balance {
    @include fontsize(16px, 1.25);
    font-weight: bold;
    color: $c-grey;

    .credit-box--insufficient & {
      color: $c-notification-red; }

    &-label {
      @include fontsize(18px, 1.22);
      font-weight: bold;
      color: $c-grey;

      .credit-box--insufficient & {
        color: $c-notification-red; } } }

  &__estimated-delivery {
    display: block; }

  &__delivery {
    @include fontsize(12px, 1.33);

    &-label {
      @include fontsize(12px, 1.33); } }

  &__notification {
    color: $c-black;
    padding-top: 10px;
    max-width: none;
    @include mq($from: tablet) {
      max-width: 290px; } }

  &__actions {
    display: flex;
    align-self: center;
    flex-direction: column;
    margin-top: auto;
    padding: 0 20px 20px 20px;
    width: 100%;
    box-sizing: border-box;

    a {
      margin-top: 10px;
      display: flex;
      text-decoration: none; }

    .button-points {
      margin: 0 auto;
      font-size: 16px; }

    .button {
      max-width: 280px;
      width: 100%;
      margin: 10px auto 0 auto; } } }

