.neukunden-header__section {
  background-image: url("/images/app/neukunden/img--neukunden-bg.jpg");
  background-position: bottom;
  min-height: 412px;
  overflow: hidden;
  @include transition;

  &.app-header__section--open {
    height: auto;
    @include transition;

    .app-header__box {
      transform: translateX(80%);

      @include mq($from: mobile) {
        transform: none; }
      @include mq($from: tablet) {
        max-width: 797px;
        width: 100%;
        height: 100%;
        bottom: -30px;
        right: 0; } } }

  @include mq($from: mobile) {
    background-position: bottom center;
    min-height: 540px;
    &.app-header__section--open {
      height: auto; } }

  @include mq($from: tablet) {
    min-height: 310px;
    background-position: 70% 70%;
    &.app-header__section--open {
      height: auto; } }

  .app-header__heading h1 {
    text-align: center; }

  .app-header__box {
    display: block;
    width: 311px;
    height: 250px;
    left: auto;
    right: -42px;
    bottom: -30px;
    z-index: 12;
    @include transition;

    @include mq($from: tablet) {
      width: 600px;
      height: 482px;
      bottom: -180px;
      @include transition; } } }
