.content-header {
  width: 100%;
  max-width: 1440px;
  height: 582px;
  position: relative;
  @include transition;

  .image {
    height: 100%;
    object-fit: cover;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover; } }

  h2, h1 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: $c-black;
    text-transform: uppercase;
    @include transition; }

  h1 {
    bottom: 8px;
    display: block;
    padding: 20px;
    background-color: $c-white;
    border-radius: 8px;
    box-sizing: border-box;
    @include fontsize(48px, 48px);
    @include transition;
    @include mq($from: tablet) {
      @include fontsize(86px, 86px); } }

  h2 {
    @include gradient-gold;
    border-radius: 8px;
    padding: 22px;
    box-sizing: border-box;
    margin-top: -32px;
    width: auto;
    @include fontsize(28px, 28px);
    @include transition;
    @include mq($from: tablet) {
      @include fontsize(32px, 32px); } } }
