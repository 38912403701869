@font-face {
  font-family: TitilliumIE;
  font-weight: normal;
  font-style: normal;
  src: url('../../assets/fonts/titillium-web-v9-latin-regular.woff2') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */;
  src: url('../../assets/fonts/titillium-web-v9-latin-regular.woff') format('woff'); }

@font-face {
  font-family: TitilliumIE;
  font-weight: bold;
  font-style: normal;
  src: url('../../assets/fonts/titillium-web-v9-latin-700.woff2') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */;
  src: url('../../assets/fonts/titillium-web-v9-latin-700.woff') format('woff'); }


@font-face {
  font-family: Titillium;
  font-weight: normal;
  font-style: normal;
  src: url("../../assets/fonts/Titillium-Regular.otf") format("opentype"); }

@font-face {
  font-family: Titillium;
  font-weight: bold;
  font-style: normal;
  src: url("../../assets/fonts/Titillium-Bold.otf") format("opentype"); }


/* titillium-web-300 - latin */
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/titillium-web-v10-latin-300.eot');
  src: local(''), url('../../assets/fonts/titillium-web-v10-latin-300.woff2') format('woff2'), url('../../assets/fonts/titillium-web-v10-latin-300.woff') format('woff'), url('../../assets/fonts/titillium-web-v10-latin-300.ttf') format('truetype'), url('../../assets/fonts/titillium-web-v10-latin-300.svg#TitilliumWeb') format('svg'), url('../../assets/fonts/titillium-web-v10-latin-300.eot?#iefix') format('embedded-opentype'); }
