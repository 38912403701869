.heros-performance {
  position: relative;

  &__section {
    background-color: $c-darkblue;
    height: auto;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    position: relative;
    @include transition; }

  &__box {
    max-width: max-content;
    width: stretch;
    border-radius: 8px;
    background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include transition;

    @include mq($from: mobile) {
      align-items: stretch; }

    @include mq($from: tablet) {
      max-height: 271px; }

    & > h3 {
      margin-bottom: 20px;
      @include mq($until: mobile) {
        @include fontsize(20px, 1.3); } }

    p {
      display: none; }

    &-container {
      flex-direction: column;
      display: flex;
      margin-bottom: 0;
      width: stretch;
      overflow-x: auto;
      overflow-y: hidden;
      @include transition;
      @include mq($from: mobile) {
        width: auto; } }

    &-wrapper {
      display: flex;
      flex-direction: column;

      @include mq($from: mobile) {
        flex-direction: row; }

      & > span {
        display: flex; }

      p {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        text-align: left;
        margin-top: 10px;
        @include mq($from: mobile) {
          width: 113px; } }

      &:first-of-type {
        margin-right: 0; }

      &:not(:last-of-type) {
        margin-bottom: 20px; }

      &:last-of-type h3 {
        color: $c-orange; }

      .counter {
        width: 33px;
        height: 48px;
        @include transition;

        @include mq($from: mobile) {
          height: 80px;
          width: 55px;
          @include transition; } } }

    &-type {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $c-darkblue;
      box-sizing: border-box;
      border-radius: 8px;
      margin-left: 8px;
      width: 124px;
      height: 48px;
      padding: 0 8px;
      white-space: nowrap;
      @include transition;

      @include mq($from: mobile) {
        height: 80px;
        width: 121px;
        @include transition; } } }

  &__content {
    width: 100%;
    color: $c-white;
    z-index: 1;
    display: flex;
    padding: 40px 10px 0 10px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-wrap: wrap;
    @include transition;

    @include mq($from: mobile) {
      padding: 40px 10px; }
    @include mq($from: tablet) {
      align-items: stretch; }

    .heros-place-header__charts {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      margin-right: 0;
      max-width: 480px;
      height: 170px;
      width: 100%;
      width: stretch;
      margin-bottom: 20px;
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      @include transition;

      h3 {
        @include transition;
        @include mq($until: mobile) {
          @include fontsize(20px, 1.3);
          @include transition; } }

      @include mq($from: mobile) {
        padding: 20px;
        height: 271px;
        max-width: 680px;
        @include transition; }

      @include mq($from: tablet) {
        margin-right: 20px;
        max-width: 480px;
        @include transition;

        .heros-place-header__section--open & {
          display: none; } }

      .progress-circle {
        font-size: 86px;
        @include transition;

        @include mq($until: mobile) {
          span:first-of-type {
            @include fontsize(39px, 39px);
            @include transition; } }

        @include mq($from: mobile) {
          font-size: 180px;
          @include transition; }

        @include mq($from: tablet) {
          font-size: 120px;
          @include transition; } } }

    @include mq($until: tablet) {
      flex-direction: column; } } }
