.highperformance-header {

  &__section {
    min-height: 502px;
    background-image: url("/images/app/highperformance/anim--highperformance-bg.jpg");
    background-position: center;
    overflow: visible;

    &.app-header__section--open {
      height: auto;

      .app-header__left p {
        margin-bottom: 30px; }

      & ~ .highperformance-performance__section {
        .highperformance-header__information--mobile {
          max-height: 40em;

          & > p, & > h3 {
            width: auto; } } }

      @include mq($until: mobile) {
        & ~ .highperformance-performance__section {
          padding: 60px 25px 20px 25px; } }

      &:not(.highperformance-header__section--ranking) {
        @include mq($until: tablet) {
          .app-header__left h3,
          .app-header__left p {
            display: none; } } } }

    @include mq($from: mobile) {
      min-height: 800px;

      &.app-header__section--open {
        height: auto; } }

    @include mq($from: tablet) {
      min-height: 310px;

      & ~ .highperformance-performance__section {
        padding-top: 380px;

        .highperformance-header__center {
          top: 20px;
          transform: translateX(-50%);
          @include transition; }

        .highperformance-header__right {
          @include mq($until: desktop) {
            margin-top: -240px; } } }

      &.app-header__section--open {
        height: auto;

        & ~ .highperformance-performance__section {
          padding-top: 230px;
          @include transition;

          .highperformance-header__center {
            top: -300px;
            transform: translateX(0%);
            @include transition; } }

        .app-header__left > p {
          max-height: 40em;
          @include transition; }

        .app-header__left > h3 {
          display: block; }

        .app-header__box {
          width: 1012px;
          height: 517px;
          top: 220px;
          right: -100px;
          bottom: 103px;
          @include transition; } } }

    @include mq($from: desktop) {
      &.app-header__section--open {
        height: auto;

        & ~ .highperformance-performance__section {
          padding-top: 40px;

          .highperformance-header__right {
            top: -140px;
            @include transition; }

          .highperformance-header__center {
            transform: translateX(-30%); } } } }

    .app-header__actions {
      z-index: 26;

      .button:first-of-type {
        color: $c-white;
        align-self: flex-start; } }

    .app-header__left {
      z-index: 7;

      & > h3 {
        display: none; }

      & > p {
        max-width: 380px;
        max-height: 0;
        margin-bottom: 0;
        display: block;
        overflow: hidden;
        @include transition; }

      h1 {
        text-align: center;
        @include mq($from: tablet) {
          text-align: left; } } }

    .app-header__box {
      display: block;
      z-index: 3;
      width: 373px;
      height: 190px;
      left: 50%;
      top: 154px;
      bottom: unset;
      transform: translateX(-50%);

      @include mq($from: mobile) {
        top: 289px;
        width: 587px;
        height: 300px;
        transform: unset;
        left: unset;
        right: 0; }

      @include mq($from: tablet) {
        width: 647px;
        height: 331px;
        top: 12px;
        right: -20px;
        animation: planes-fly 0.6s ease-out; } } }

  &__information--mobile {
    max-height: 0;
    overflow: hidden;
    margin-bottom: 20px;
    transition: max-height 0.3s ease-in-out;
    max-width: 380px;
    color: $c-white;

    & > h3 {
      margin-bottom: 20px; }

    & > p, & > h3 {
      width: 0;

      a {
        color: $c-white; } }


    @include mq($until: mobile) {
      max-width: 380px;

      .app-header__section--open & {
        margin-top: 10px;
        max-height: 40em;
        transition: max-height 0.3s ease-in-out; } }
    @include mq($from: tablet) {
      display: none; } }

  &__caesar {
    position: absolute;
    display: block;
    top: 16px;
    z-index: 10;
    @include transition;

    @include mq($from: mobile) {
      top: -8px;
      @include transition; }

    &:first-of-type {
      left: 42px;
      transform: scaleX(-1) translateX(58%);

      @include mq($from: mobile) {
        transform: scaleX(-1) translateX(76%);
        @include transition; } }

    &:last-of-type {
      right: 42px;
      transform: translateX(58%);

      @include mq($from: mobile) {
        transform: translateX(76%);
        @include transition; } }

    .icon, .icon svg {
      width: 62px;
      height: 108px;
      @include icon-color($c-gold);
      @include transition;

      @include mq($from: mobile) {
        width: 98px;
        height: 170px;
        @include transition; } } }

  &__circle {
    border-radius: 50%;
    background-color: $c-white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    @include drop-shadow;
    position: absolute;
    top: 0;
    transform: translateY(-80%);
    @include transition;

    @include mq($from: mobile) {
      width: 80px;
      height: 80px;
      transform: translateY(-50%);
      @include transition; }

    .icon {
      @include icon-color($c-brightgrey);
      @include icon-size(38px);
      top: -2px; }

    &:first-of-type {
      left: 8px;
      @include transition;

      @include mq($from: mobile) {
        left: 30px;
        @include transition; } }

    &:nth-of-type(2) {
      transform: translate(-50%, -100%);
      left: 50%;
      width: 80px;
      height: 80px;
      @include transition;

      @include mq($from: mobile) {
        width: 100px;
        height: 100px;
        transform: translateY(-75%) translateX(-50%);
        left: 50%;
        @include transition; }

      .icon {
        @include icon-size(54px);
        top: -2px; } }

    &:last-of-type {
      left: auto;
      right: 8px;
      @include transition;

      @include mq($from: mobile) {
        right: 30px;
        @include transition; } } }

  &__center {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 240px;
    align-items: center;
    left: 50%;
    z-index: 5;
    @include transition;
    top: -220px;
    transform: translateX(-50%);

    @include mq($from: mobile) {
      width: 342px;
      top: -340px;
      @include transition; }

    @include mq($from: tablet) {
      bottom: 800px;
      transform: translateX(0%);
      @include transition; }

    @include mq($from: desktop) {
      top: -300px;
      transform: translateX(-50%);
      @include transition; }

    &-header, &-rank, &-points {
      border-radius: 8px;
      background-color: $c-white;
      color: $c-black;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box; }

    &-header {
      height: 116px;
      padding: 17px 42px;
      z-index: 8;
      position: relative;
      box-sizing: border-box;
      @include transition;

      & > h3 {
        text-align: center;
        @include fontsize(22px, 26px);
        @include mq($from: mobile) {
          text-align: left;
          @include fontsize(24px, 28px); } }

      @include mq($from: mobile) {
        padding: 52px 20px;
        box-sizing: border-box;
        @include transition; } }

    &-rank {
      @include gradient-gold;
      color: $c-white;
      width: 180px;
      min-height: 70px;
      height: 70px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 18px;
      margin-top: -30px;
      z-index: 10;
      @include drop-shadow;
      @include transition;

      @include mq($from: mobile) {
        margin-top: -25px;
        @include transition; }

      & > h1 {
        @include fontsize(72px, 72px);
        height: 62px; }

      .icon {
        @include icon-color($c-white);
        @include icon-size(22px); } }

    &-points {
      z-index: 8;
      margin-top: -25px;
      width: 276px;
      min-height: 130px;
      height: 130px;
      padding: 40px 30px 20px;

      & > h3 {
        @include fontsize(18px, 22px); }

      & > h1 {
        margin-top: auto;
        color: $c-gold;
        @include fontsize(48px, 28px);

        & > sup {
          @include fontsize(28px, 14px); } } } }

  &__right {
    position: relative;
    display: flex;
    flex-direction: column;
    bottom: 0;
    z-index: 25;
    right: -50%;
    transform: translateX(-50%);
    justify-content: center;
    align-items: center;
    @include transition;

    @include mq($from: mobile) {
      right: 0;
      transform: none;
      margin-top: -54px;
      width: stretch;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      @include transition;

      & > .highperformance-header__information--mobile {
        margin-top: 64px;
        margin-left: 24px; }

      & > div:last-of-type {
        margin-left: auto;
        margin-right: 20px; } }

    @include mq($from: tablet) {
      top: 0px;
      //margin-top: -220px //short only
      @include transition; }

    @include mq($from: desktop) {
      top: 178px;
      padding-right: 110px;
      max-width: max-content;
      height: max-content;
      position: absolute;
      @include transition; }

    & > div:last-of-type {
      display: flex;
      flex-direction: column;

      .button {
        margin-left: 0;
        margin-bottom: 10px;
        background: $c-white; } } } }
