$c-darkred: #BE1024;
$c-notification-red: #E6142D;
$c-orange: #F88F10;
$c-darkblue: #0F0F31;
$c-blue: #26264A;
$c-turquoise: #73EEDC;
$c-gold: #E3C15C;
$c-brightgold: #F8D97B;
$c-luxusgold: #F2DD8A;

$c-black: #000000;
$c-darkgrey: #454545;
$c-grey: #767676;
$c-brightgrey: #C3C3C3;
$c-lightgrey: #F2F2F2;
$c-white: #ffffff;
$c-greybg: #E0E0E0;
$c-silvergrey: #E4E4E4;

$c-background: #EbEbEb;

//ProgressCircle
$circle-width: 0.08em;
$circle-width-hover: 0.04em;
