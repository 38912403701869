.heros-place-header {
  position: relative;

  &__section {
    background-color: $c-darkblue;
    display: flex;
    min-height: 342px;
    flex-direction: row;
    background-image: url("/images/app/heros-place/img--bg-herosplace.jpg");
    background-position: bottom;
    box-sizing: border-box;
    position: relative;
    @include transition;

    video {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      object-fit: cover;
      border-radius: 1px;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .oldie & {
        height: auto; } }

    &--open {
      height: auto; }

    @include mq($from: mobile) {
      min-height: 540px;
      @include transition;

      &--open {
        height: auto; } }

    @include mq($from: tablet) {
      overflow: hidden;
      min-height: 310px;

      &--open {
        height: auto;
        @include transition; } } }

  &__charts {
    position: absolute;
    top: 600px;
    left: 50%;
    transform: translateX(-50%);
    width: 420px;
    height: 204px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;

    h3 {
      margin-bottom: 20px; }

    span {
      display: flex; } }

  &__box {
    margin-top: auto;
    position: absolute;
    max-width: 1000px;
    width: 100%;
    height: 100%;
    min-height: 833px;
    max-height: 833px;
    z-index: 1;
    right: 50%;
    line-height: 0;
    top: 62px;
    transform: translateX(50%);
    @include transition;

    @include mq($until: tablet) {
      max-width: 397px;
      min-width: auto;
      max-height: 329px;
      min-height: auto;
      bottom: 0;
      top: auto;
      left: auto;
      right: 0;
      transform: none;
      @include transition; }

    @include mq($until: mobile) {
      max-width: 300px;
      max-height: 250px; }

    .image {
      display: flex;

      img {
        width: 100%;
        height: 100%; } } }

  &__content {
    padding: 20px;
    width: 100%;
    color: $c-white;
    z-index: 5;
    display: flex;
    flex-direction: column;
    @include transition;

    @include mq($from: mobile) {
      padding-left: 60px;
      padding-right: 60px;
      padding-top: 60px;
      @include transition; }

    @include mq($from: tablet) {
      flex-direction: row;
      padding: 110px 80px;
      @include transition; }

    @include mq($from: desktop) {
      padding-left: 130px;
      padding-right: 130px; }

    .heros-place-header__charts {
      display: none;

      @include mq($from: desktop) {
        display: flex; } }

    p {
      max-height: 0;

      @include mq($from: tablet) {
        display: block;
        max-height: 40em;
        max-width: 480px; } } }

  &__actions {
    display: none;
    position: relative;

    @include mq($from: tablet) {
      display: block;
      max-width: 480px;

      .button:first-of-type {
        color: $c-white;
        padding-left: 26px;

        .icon {
          left: 0;
          transform: translateY(-50%) rotate(90deg);
          @include icon-color($c-white);
          @include icon-size(16px); }

        .heros-place-header__section--open & {
          margin-bottom: 20px;

          .icon {
            transform: translateY(-50%) rotate(-90deg); } } }
      //display: none


      .button:last-of-type {
        display: none;

        .heros-place-header__section--open & {
          display: block;
          margin-left: 0; } } }

    &--mobile {
      display: block;
      text-align: center;
      z-index: 5;
      margin-top: auto;

      @include mq($from: tablet) {
        display: none; }

      .button {
        position: relative;
        bottom: -50%; } } }

  &__heading {
    display: flex;
    flex-direction: column;
    justify-content: center;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include mq($from: tablet) {
        flex-direction: row;
        align-items: stretch; }

      & > span {
        //width: 40px
        //height: 40px
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        @include transition;

        @include mq($from: mobile) {
          margin-bottom: 20px;
          @include transition; }

        .icon {
          @include icon-color($c-white);
          @include icon-size(40px); }

        @include mq($from: mobile) {
          flex-shrink: 100;
          margin-bottom: 20px;
          margin-right: 20px;
          @include transition;

          .icon {
            @include icon-size(80px);
            @include transition; } }

        @include mq($from: tablet) {
          .icon {
            @include icon-size(60px);
            @include transition; } } } }

    h1 {
      @include mq($until: mobile) {
        @include fontsize(42px, 1.14);
        @include transition; }
      @include mq($from: mobile) {
        @include fontsize(86px, 0.9); } }

    h2 {
      margin-bottom: 20px;
      text-align: center;

      @include mq($until: phone) {
        @include fontsize(18px, 1.22); }

      @include mq($from: tablet) {
        text-align: left;
        margin-left: 80px; }
      @include mq($from: desktop) {
        margin-left: 84px; } } }

  &__left {
    display: flex;
    flex-direction: column;

    h3 {
      margin-bottom: 20px;
      margin-top: 40px;

      .heros-place-header__section--open & {
        display: block;

        @include mq($until: tablet) {
          max-width: 480px;
          width: stretch;
          text-align: left;
          margin: 40px auto 20px auto; } } }

    p {
      max-width: 480px;
      max-height: 0;
      overflow: hidden;
      margin: 0 auto;
      @include transition;

      .heros-place-header__section--open & {
        display: block;
        max-height: 40em;

        @include mq($until: tablet) {
          margin: 0 auto; } }

      @include mq($from: mobile) {
        margin-bottom: 30px; }
      @include mq($from: tablet) {
        margin-left: 0;
        margin-right: 0; } } }

  &__right {
    display: flex;
    height: stretch; } }

