@keyframes fadeIn {
  0% {
    visibility: visible;
    opacity: 0; }

  100% {
    visibility: visible;
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }

  99% {
    opacity: 0; }

  100% {
    opacity: 0;
    visibility: hidden; } }

.popup-cart {
  height: max-content;
  background-color: $c-white;
  position: absolute;
  border: 2px solid $c-brightgrey;
  border-radius: 8px;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 68px;
  z-index: 200;
  min-width: 400px;
  @include drop-shadow;

  @include mq($until: phone) {
    width: stretch;
    margin: 0 10px;
    position: fixed;
    min-width: unset; }

  &__upper {
    flex-direction: column;
    display: flex;
    padding: 20px; }

  &__close {
    align-self: flex-end;
    cursor: pointer;

    .icon {
      @include icon-size(18px); } }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > a {
      display: block;
      margin: 0 auto; } }

  &__title {
    @include fontsize(24px, 1.16);
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 40px; }

  &__products {
    max-height: 260px;
    min-height: 106px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    @include mq($until: phone) {
      overflow-y: scroll;
      overflow-x: hidden; }

    &--scroll {
      overflow-y: scroll;
      overflow-x: hidden; }

    div:last-of-type.cart-product {
      border-bottom: 0;
      padding-bottom: 0; }

    &-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%; } }

  &__more-below {
    display: flex;
    justify-content: center;
    align-self: center;

    .icon {
      transform: rotate(90deg);
      @include icon-size(24px); } }

  &__lower {
    padding: 20px;
    height: 154px;
    box-sizing: border-box;
    background-color: $c-lightgrey;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    margin-top: auto;

    .popup-cart__row:last-of-type {
      margin-top: 20px; }

    .button {
      width: 280px;
      margin: 0 auto; } }

  &__price {
    @include fontsize(24px, 1.16);
    font-weight: bold;
    color: $c-orange;

    &-label {
      @include fontsize(24px, 1.16);
      font-weight: bold;
      padding-bottom: 4px; } }

  &__delivery {
    @include fontsize(12px, 1.33);
    padding-bottom: 16px; }

  &__overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(256, 256, 256, 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: all;
    z-index: 50;
    visibility: hidden;
    @include transition;
    //animation: fadeOut 0.3s forwards

    &--visible {
      display: block;
      animation: fadeIn 0.3s forwards;
      @include transition; } } }
