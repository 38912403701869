.feuerfrei-header__section {
  background-image: url("/images/app/feuerfrei/img--bg-feuerfrei.jpg");
  background-position: bottom;
  min-height: 412px;
  overflow: hidden;
  @include transition;

  @include mq($from: mobile) {
    min-height: 540px;
    @include transition; }
  @include mq($from: desktop) {
    min-height: 412px; }

  &.app-header__section--open {
    height: auto;

    .app-header__box {
      width: 805px;
      //height: unset
      height: 496px;
      @include transition;

      @include mq($until: tablet) {
        width: 398px;
        height: 244px;
        left: 100%;
        transform: translateX(-65%); } } }

  .jackpot-counter {
    min-width: 447px;
    height: 212px;
    width: unset;
    padding: 40px 20px;
    display: none;
    top: auto;

    @include mq($from: tablet) {
      display: flex; }

    &__icon {
      position: absolute;

      .icon {
        @include icon-color($c-darkred);
        @include icon-size(40px); } } }

  .app-header__box {
    width: 634px;
    height: 388px;
    line-height: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0;
    display: block;
    @include transition;

    .image {
      width: unset;
      max-height: unset; }

    @include mq($until: tablet) {
      width: 398px;
      height: 244px; } }

  @include mq($until: tablet) {
    &.app-header__section--open {

      .app-header__left {
        align-items: center;

        h3 {
          margin-bottom: 20px; }

        h3, p {
          max-width: 480px;
          display: block; } } } } }

.feuerfrei-counter__section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 67px 0 40px 0;
  background-color: $c-darkblue;
  margin-bottom: 40px;
  flex-direction: column;
  position: relative;

  .app-header__actions--mobile {
    position: absolute;
    top: 0;
    transform: translateY(-50%); }

  .jackpot-counter {
    margin-top: 40px;
    width: unset;
    height: unset;
    padding-left: 10px;
    padding-right: 10px;

    @include mq($from: phone) {
      padding-left: 20px;
      padding-right: 20px; }

    &__icon > .icon {
      @include icon-color($c-darkred);
      @include icon-size(40px); } }

  @include mq($from: tablet) {
    display: none; } }
