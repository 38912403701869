.highperformance-performance {

  &__section {
    display: flex;
    flex-direction: column;
    background-color: $c-darkblue;
    position: relative;
    padding: 40px 25px 40px 25px;
    z-index: 23;
    @include transition;

    .gewinnerreise & {
      padding: 60px 25px 40px 25px; }

    @include mq($from: mobile) {
      padding: 40px 25px 40px 25px; }
    @include mq($from: tablet) {
      padding: 40px 25px 40px 25px;
      @include transition; }

    @include mq($from: desktop) {
      padding: 40px 10px 40px 10px;
      @include transition; }

    .app-header__actions--mobile {
      position: absolute;
      top: 0;
      display: block !important;
      justify-content: center;
      left: 50%;
      @include transition;

      @include mq($from: mobile) {
        transform: translateX(-50%);
        @include transition; }
      @include mq($from: tablet) {
        left: 44px;
        transform: unset; }

      .button {
        box-sizing: content-box;
        @include mq($from: tablet) {
          transform: translate(0%, -50%);
          @include transition; } } } }

  &__content {
    display: flex;
    flex-direction: column;
    margin: -10px;

    @include mq($from: tablet) {
      justify-content: center;
      align-items: center; }

    @include mq($from: desktop) {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: unset; } }

  &__loader {
    height: 40px;
    //max-width: 384px
    width: 100%;
    border-radius: 32px;
    background-color: $c-gold;
    overflow: hidden;
    margin: 0 auto 20px auto;
    @include transition;

    .gewinnerreise & {
      background-color: $c-darkblue; }


    @include mq($from: mobile) {
      margin: 0; }

    &--second {
      background-color: $c-orange; }

    &-indicator {
      display: block;
      height: 100%;
      background-color: $c-brightgold;
      border-right: 2.5px solid $c-white;
      width: 0%;

      .gewinnerreise & {
        background-color: $c-turquoise; } } }

  &__item {
    display: flex;
    height: 60px;
    min-width: 480px;
    width: stretch;
    background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    box-sizing: border-box;

    & > span {
      width: 33%;

      &:nth-of-type(2) {
        text-align: center; }

      &:last-of-type {
        text-align: right; } }

    &--current {
      background: $c-orange;
      color: $c-black;
      font-weight: bold; }

    &:not(:last-of-type) {
      margin-bottom: 10px; } }

  &__wrapper {
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;

    &--scroll .highperformance-performance__item {
      min-width: 460px;
      margin-right: 8px; } }
  //max-width: 460px

  &__box {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    width: stretch;
    color: $c-black;
    padding: 20px 10px;
    box-sizing: border-box;
    align-items: center;
    background: $c-white;
    margin: 10px;
    height: 270px;

    .gewinnerreise & {
      background: $c-blue;
      color: $c-white; }

    @include mq($from: mobile) {
      align-items: flex-start;
      padding: 20px 20px 40px; }
    @include mq($from: tablet) {
      max-width: 480px;
      height: 320px; }

    &--timer {
      height: auto;
      max-height: 320px;

      @include mq($from: tablet) {
        max-width: 680px; }

      & > h3 {
        margin-bottom: 20px;
        text-align: center;
        width: 100%; }

      & > span {
        display: flex;
        justify-content: center;
        width: 100%; }

      .progress-circle {
        font-size: 86px;

        @include mq($from: mobile) {
          font-size: 180px; }

        & > span:first-of-type {
          @include fontsize(39px, 39px);

          @include mq($from: mobile) {
            @include fontsize(70px, 70px); } } } }

    &-header {
      display: flex;
      justify-content: space-between;
      width: stretch;
      margin-bottom: 25px;
      min-width: 480px;

      div {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        & > h3 {
          margin-bottom: 0 !important; }

        & > span {
          width: 46px;
          height: 46px;
          border-radius: 50%;
          background-color: $c-blue;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;

          .icon {
            @include icon-color($c-white);
            @include icon-size(24px); } } } }

    &--percent {
      //padding: 20px

      @include mq($from: mobile) {
        .highperformance-performance__box-container {
          display: flex;
          flex-direction: column;
          width: 100%;

          & > div {
            align-items: center;
            display: flex;
            width: 100%;
            margin-bottom: 20px; } } }

      @include mq($from: tablet) {
        max-width: 680px; }
      @include mq($from: desktop) {
        width: 100%;
        max-width: 480px; }

      & > h3 {
        margin-bottom: 20px;
        text-align: center;
        width: 100%;
        @include transition;
        @include mq($from: mobile) {
          margin-bottom: 60px;
          @include transition; } } }

    &-percent {
      display: block;
      text-align: center;
      @include fontsize(56px, 56px);
      font-weight: bold;
      color: $c-black;

      .gewinnerreise & {
        color: $c-orange; }

      @include mq($from: mobile) {
        margin-left: 20px; }
      @include mq($from: tablet) {
        height: auto; } }

    &-container {
      display: flex;
      flex-direction: column;
      width: stretch;
      height: 100%;

      @include mq($from: mobile) {
        @include mq($until: tablet) {
          flex-direction: row; } }

      .neukunden-performance__congrats {
        margin: 0 0 0 20px; }

      & > p {
        margin-top: auto;
        font-weight: bold;
        text-align: left;

        @include mq($from: mobile) {
          text-align: center; } } }

    &-wrapper {
      display: flex;
      margin-bottom: 20px;
      @include transition;

      @include mq($from: mobile) {
        @include mq($until: tablet) {
          flex: 0 0 50%;
          @include transition; } }

      .counter {
        width: 33px;
        height: 48px;

        &-spawner {
          min-width: 111px;
          justify-content: flex-end; }

        &__value {
          @include fontsize(33px, 1.28); } } }

    &-type {
      margin-left: 10px;
      @include transition;

      @include mq($from: mobile) {
        margin-left: 20px;
        @include transition; } } }

  &__actions {
    margin-top: 40px;
    display: flex;
    width: stretch;
    justify-content: center; } }
