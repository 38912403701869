.orders {

  &__top--mobile {
    display: block;
    padding: 10px;

    .accordion {
      margin: 0; }

    .accordion__items {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      .orders__actions {
        margin-top: 10px; } }

    @include mq($from: mobile) {
      display: none; } }

  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include mq($from: tablet) {
      padding-top: 40px;
      flex-direction: row; }

    &--detail .order-item {
      margin-bottom: 20px; } }

  &__left {
    margin-bottom: 40px;
    flex-shrink: 100;
    //background-color: $c-white
    border-radius: 8px;
    width: stretch;

    div.cart-product {
      flex-wrap: wrap;
      width: stretch;
      margin-bottom: 20px;
      min-width: 570px;

      .orders__container--detail & {
        min-width: 300px;
        width: stretch;
        margin-bottom: 0;
        flex-direction: column;
        border-radius: 0;

        @include mq($from: desktop) {
          flex-wrap: wrap;
          flex-direction: row; }

        &:after {
          content: "";
          width: 100%;
          margin: 0 auto;
          padding-top: 20px;
          border-bottom: 1px solid $c-brightgrey; }

        &:first-of-type {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px; }

        &:last-of-type {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;

          &:after {
            border-bottom: 0; } } } } }

  &__right {
    display: none;

    .orders--detail & {
      display: block;
      text-align: right;

      .shipping-box {
        max-width: unset;
        @include mq($from: mobile) {
          max-width: 380px;
          margin-left: auto; } } }

    @include mq($from: mobile) {
      display: block;
      margin-left: 20px;
      position: relative;

      p {
        width: 280px;
        @include fontsize(12px, 1.33);
        position: sticky;
        top: 286px; } } }

  &__actions {
    max-width: 280px;
    width: stretch;
    box-sizing: border-box;
    padding: 20px;
    background-color: $c-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 20px;
    position: sticky;
    top: 120px;
    @include drop-shadow;

    .button {
      width: 240px;
      margin-top: 20px; }

    @include mq($from: mobile) {
      @include mq($until: tablet) {
        margin-left: auto; } } }

  &__date {
    width: 100%;
    align-self: flex-start;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-of-type {
      margin-bottom: 20px; }

    label {
      margin-right: 20px;
      font-weight: bold;
      @include fontsize(16px, 1.375); }

    .textfield__input {
      align-self: flex-end;
      max-width: 190px;
      width: stretch; } }

  &__left--empty {
    max-width: 780px;
    max-height: 293px;
    margin: 0 auto 40px auto;
    background-color: $c-white;

    .button, a {
      width: 260px; } }

  &__empty {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 100px 40px 40px 40px;

    @include mq($until: mobile) {
      padding: 100px 20px 20px 20px; }

    h2 {
      text-align: center;
      //width: max-content
      margin: 0 auto 20px auto;
      display: flex; } }

  &__lottie-circle {
    position: absolute;
    left: 50%;
    top: 0;
    width: 100px;
    height: 100px;
    background-color: $c-white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -25%);
    @include drop-shadow;

    .lottie__uncontrolled {
      width: 90px;
      height: 90px; } }

  &__empty-actions {
    margin: 0 auto;
    flex-direction: column;
    display: flex;
    align-items: center;

    .button {
      min-width: max-content;
      width: stretch;
      max-width: 260px;

      &:first-of-type {
        margin-bottom: 10px; } } } }


