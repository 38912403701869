.profile-login {

  &__section {
    background-color: $c-white;
    padding: 40px 0;

    h2 {
      text-align: center;
      padding-bottom: 40px; } }

  &__container {
    display: flex;
    flex-direction: column;
    max-width: 380px;
    width: stretch;
    margin: 0 auto;

    .login__action {
      align-items: center;
      display: flex; }


    @include mq($until: phone) {
      align-items: center; }

    .button {
      margin: 40px auto 0 auto;
      max-width: 280px;
      width: stretch;
      @include transition; }

    .textfield {
      max-width: 380px;
      width: stretch;
      @include transition;
      @include mq($until: phone) {
        max-width: 280px;
        @include transition; } } } }
