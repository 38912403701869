.textlink {
  text-decoration: underline;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: $c-black;
  @include transition;
  @include fontsize(16px, 1.5);

  &__wrapper {
    display: flex; }

  &:hover, &:hover > .icon {
    color: $c-orange;
    @include icon-color($c-orange);
    @include transition; }

  .icon {
    cursor: pointer;
    transform: rotate(180deg);
    margin-right: 10px;
    @include icon-size(16px);
    @include transition;

    svg use {
      @include transition; } }

  &--after .icon {
    margin-left: 10px;
    transform: unset; } }


