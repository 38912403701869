.breadcrumb {
  display: flex;
  position: relative;
  max-width: 570px;
  height: 120px;
  margin: 0 auto;
  padding: 0 10px;
  transform: translate(0, -40px);
  @include transition;

  @include mq($until: mobile) {
    @include transition;
    height: 90px;
    transform: translate(0, -30px); }

  &__bubble {
    //display: flex
    //flex-direction: column
    //align-items: center
    //justify-content: center
    position: relative;
    z-index: 3; }

  &__circle {
    background-color: $c-white;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    z-index: 4;
    @include drop-shadow;
    @include transition;

    @include mq($until: mobile) {
      width: 60px;
      height: 60px;
      margin-bottom: 10px;
      @include transition; }

    &--inactive svg path {
      fill: $c-brightgrey; }

    & > .lottie__controlled {
      height: 45px;
      width: 45px;

      @include mq($until: mobile) {
        width: 28px;
        height: 28px; } }

    &.lottie--overview {

      .icon {
        @include icon-size(50px);
        @include icon-color($c-brightgrey);
        //margin-top: 4px
        margin-left: 6px;
        margin-bottom: 2px;

        @include mq($until: mobile) {
          @include icon-size(38px); } }

      .lottie__controlled {
        margin-top: 4px;
        width: 50px;
        margin-left: 6px;

        @include mq($until: mobile) {
          width: 32px; } } }

    &.lottie--home .lottie__controlled,
    &.lottie--confirm .lottie__controlled {
      margin-bottom: 12px;
      @include mq($until: mobile) {
        margin-bottom: 6px; } }

    &.lottie--confirm {

      .icon {
        @include icon-color($c-brightgrey);
        @include icon-size(56px);
        margin-bottom: 16px;

        @include mq($until: mobile) {
          @include icon-size(42px); } } } }

  &__label {
    font-weight: bold;
    color: $c-brightgrey;
    position: absolute;
    bottom: 0;
    @include fontsize(18px, 1.22);
    @include transition;

    &--active {
      color: $c-black;
      @include transition; }

    @include mq($until: mobile) {
      @include fontsize(16px, 1.25); } }


  &__loader {
    width: calc((100% - 180px) / 2);
    display: block;
    height: 4px;
    top: -2px;
    z-index: 2;
    margin-top: 40px;
    position: relative;
    overflow-x: visible;
    @include transition;

    @include mq($until: mobile) {
      margin-top: 30px; }

    @include mq($from: mobile) {
      width: calc((100% - 240px) / 2); }

    &--inner {
      display: block;
      position: absolute;
      background-color: $c-darkred;
      height: 4px;
      max-width: 0;
      min-width: 0;
      left: 0;

      .breadcrumb__loader--done & {
        min-width: calc(100% + 14px);
        @include transition; } } } }
