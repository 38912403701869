.header {
  width: 100%;
  max-width: 1440px;
  height: 240px;
  position: relative;
  @include transition;

  @include mq($until: tablet) {
    height: 140px;
    @include transition; }

  &.header--no-image {
    background-color: $c-white;

    & > h1, & > h2 {
      color: $c-black;
      text-transform: none; }

    .container--order & {
      height: 286px;
      margin-bottom: 0; } }

  .image {
    height: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover; } }

  h2, h1 {
    position: absolute;
    margin-left: 130px;
    color: $c-white;
    text-transform: uppercase;
    @include transition;

    @include mq($until: tablet) {
      margin-left: 44px;
      @include transition; }

    //.container--wishlist &
    //  +mq($until: desktop)
    //    margin-left: 60px
    //    +transition
    //
    //  +mq($until: phone)
    //    margin-left: 20px

    @include mq($until: phone) {
      margin-left: 20px; } }

  h1 {
    bottom: 60px;
    display: block;

    @include mq($until: tablet) {
      bottom: 40px;

      .container--checkout & {
        bottom: 50px; } } }

  &--compl {
    height: 315px;

    h1 {
      max-width: 650px; } } }
