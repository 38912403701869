.shop {

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @include mq($until: mobile) {
      align-items: unset;
      flex-direction: column-reverse; } }


  &__top--mobile {
    width: stretch;
    @include mq($from: mobile) {
      display: none; }

    > .accordion {
      margin-top: 0;

      > .accordion__items {
        padding-left: 0; } }

    > .accordion--active > .accordion__items {
      max-height: 60em;
      padding-bottom: 20px; }
    //padding-left: 0

    .accordion__items {
      display: flex;

      .shop__filterbar {
        margin: 0 auto;
        padding-bottom: 0; } }

    .filterbar__heading {
      display: none; } }

  &__left {
    @include mq($until: mobile) {
      display: none; } }

  &__right {
    margin-bottom: 100px;
    flex-shrink: 100;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include transition;

    @include mq($from: mobile) {
      @include transition;
      margin-left: 10px; } }

  &__breadcrumb {
    span, a {
      color: $c-black;
      font-size: 14px;
      line-height: 1.33;
      margin-right: 5px;
      text-transform: capitalize;

      .product-view & {
        text-decoration: underline; } }

    //+mq($from: tablet)
    //  +fontsize(24px, 1.16)
    //  font-weight: bold

    .textlink .icon {
      width: 18px; } }


  &__sort {
    width: 200px;
    @include mq($until: mobile) {
      align-self: flex-end;
      margin-bottom: 10px;
      margin-top: 10px; }

    .selectfield__container {
      margin-bottom: 0; } }

  &__container {
    display: flex;
    @include mq($until: mobile) {
      flex-direction: column; } }

  &__filterbar {
    width: 280px !important;
    //display: flex
    //flex-direction: column
    height: auto;
    height: max-content;
    position: sticky;
    top: 100px;
    padding-bottom: 40px; }

  .filterbar {

    &__heading {
      font-weight: bold;
      margin-bottom: 20px;
      display: block; }

    &__spacer {
      height: 30px;
      display: block; }

    .textfield {
      margin-bottom: 10px; }

    .textfield-group {

      .textfield {
        width: 130px;
        margin-bottom: 0;
        margin-top: 0; } }

    .button {
      width: 100%;
      margin-top: 20px;
      padding-right: 48px; } }

  &__product-grid {
    margin: -10px;
    display: flex;
    flex-wrap: wrap;
    //margin-bottom: 40px
    justify-content: center;

    a {
      margin: 10px; } } }

.checkbox__container + .filterbar__please-select {
  display: none; }
