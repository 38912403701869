.lightbox {
  position: absolute;
  width: stretch;
  height: stretch;
  top: 0;
  left: 0;
  right: 0;
  z-index: 220;

  &__container {
    //transform: translateY(16vh)
    margin: 0 auto;
    width: max-content;
    position: relative;
    z-index: 202;
    padding: 40px;
    background-color: $c-white;
    border-radius: 8px;
    min-width: 880px;
    min-height: 560px;
    display: flex;
    align-items: center;
    top: 10vh;

    .image {
      display: flex;
      justify-content: center; } }

  &__actions {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(0%, -130%);
    cursor: pointer;

    .icon {
      @include icon-color($c-white);
      @include icon-size(24px); } } }

