.product {
  position: relative;
  display: inline-block;
  max-width: 380px;
  min-width: 380px;
  width: stretch;
  border-radius: 8px;
  cursor: pointer;
  @include transition;

  @include mq($until: mobile) {
    .card-slider & {
      min-width: 280px;
      max-width: 280px; } }

  &:hover > .product__price,
  &:focus > .product__price,
  &:hover > .product__upper,
  &:focus > .product__upper {
    @include drop-shadow;
    @include transition; }

  &__upper {
    border-radius: 8px;
    @include transition; }

  &--shop {
    width: 280px;
    min-width: 280px;
    max-width: 280px; }

  &--standard {
    .product__image {
      border: 0px;
      overflow: hidden;
      background-color: transparent; }
    .image--fullwidth {
      padding: 0px;
      background-size: cover;
      background-position: center; } }

  &--short {
    width: 280px;
    min-width: 280px;
    max-width: 280px; }

  &__label {
    position: absolute;
    top: 10px;
    left: 10px;
    font-weight: bold;
    @include fontsize(18px, 1.16); }

  &__image {
    max-width: 380px;
    height: 180px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 2px solid $c-lightgrey;
    background-color: $c-white;
    box-sizing: border-box;

    .image {
      height: 100%;
      padding: 24px;
      box-sizing: border-box;

      img {
        height: 100%;
        max-width: 100%;
        object-fit: contain;
        align-self: center;

        .oldie & {
          max-height: 100%;
          height: auto;
          top: 50%;
          position: relative;
          transform: translateY(-50%); } } }

    .product--short & {
      width: 280px; }

    .product--shop & {
      width: 280px;
      height: 290px; }

    @include mq($until: mobile) {
      .card-slider & {
        width: 280px; } } }

  &__actions {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    z-index: 10;

    .button-wishlist {
      margin: 10px; } }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .product--white & {
      background-color: $c-lightgrey; }

    .product--darkblue & {
      background-color: $c-darkblue;
      color: $c-white; }

    .product--shop & {
      width: 280px;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start; }

    .product--short & {
      min-height: 103px;
      max-height: 103px; }

    .product--standard & {
      padding: 20px 0; }

    @include mq($until: mobile) {
      .card-slider & {
        width: 280px;
        min-height: 103px;
        max-height: 103px; } } }

  &__title {
    width: 220px;
    text-align: center;
    @include fontsize(24px, 1.16);
    font-weight: bold;
    display: flex;
    align-items: center;

    .product--standard & {
      height: 3.46em;
      overflow: hidden;
      display: block; }

    .product--short & {
      height: 3.66em;
      overflow: hidden;
      display: block;
      padding: 20px 20px 0 20px;
      @include fontsize(18px, 1.22); }

    .product--shop & {
      padding: 20px 20px 0 20px;
      text-align: left;
      @include fontsize(18px, 1.22);
      height: 3.66em;
      overflow: hidden;
      display: block; }

    @include mq($until: mobile) {
      .card-slider & {
        height: 3.66em;
        overflow: hidden;
        display: block;
        @include fontsize(18px, 1.22); } } }

  &__price {
    display: block;
    margin: 0 auto;
    width: 300px;
    height: 32px;
    color: $c-white;
    text-align: center;
    vertical-align: middle;
    @include fontsize(16px, 32px);
    font-weight: bold;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: $c-orange;
    @include transition;

    .club & {
      @include gradient-gold;
      color: $c-black; }

    &--shop {
      color: $c-orange;
      margin-top: 10px;
      padding: 0 20px 20px 20px;
      font-weight: bold;
      @include fontsize(16px, 1.5); }

    .product--short & {
      width: 240px; }

    .product--shop & {
      display: none; }

    @include mq($until: mobile) {
      .card-slider & {
        width: 240px; } } }

  &__availability {
    display: flex;
    align-items: center;
    @include fontsize(16px, 1.5);

    .icon {
      width: 16px;
      height: 16px;
      margin-right: 10px; } } }

.product + .product {
  margin-left: 24px;
  margin-top: 12px; }
