.highperformance-ranking, .highperformance-placement, .highperformance-wertung {

  &__section {
    @include gradient-gold;
    position: relative;
    margin: 40px 0 60px;
    box-sizing: border-box;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    @include transition;

    @include mq($from: mobile) {
      padding: 50px 44px; }
    @include mq($from: tablet) {
      padding: 50px 60px; }
    @include mq($from: desktop) {
      padding: 50px 130px; }

    &.highperformance-wertung__section {
      height: auto; }

    &.highperformance-wertung__ranking {
      height: auto;
      min-height: unset; }

    @include mq($from: mobile) {
      margin: 40px 0 60px;
      height: auto;
      @include transition; } }

  &__header {
    width: 80%;
    border-radius: 8px;
    padding: 20px;
    margin-top: -40px;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    text-align: center;
    @include gradient-gold;
    //top: -40px
    box-sizing: border-box;
    min-width: 240px;
    @include transition;

    @include mq($from: mobile) {
      max-width: 620px;
      @include gradient-gold;
      @include transition; }

    &-sub {
      width: stretch;
      max-width: 580px;
      display: flex;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include mq($from: mobile) {
        flex-direction: row; }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 50%;
        @include gradient-gold;
        font-weight: bold;
        min-width: 220px;

        @include mq($from: mobile) {
          border-bottom-left-radius: 8px; }

        &:last-of-type {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          background: $c-white;
          font-weight: normal;
          @include mq($from: mobile) {
            border-bottom-left-radius: 0px; } } } } }

  &__info {
    display: block;
    overflow: hidden;
    max-height: 0;
    max-width: 580px;
    width: stretch;
    margin: 40px auto 0 auto;
    color: $c-white;
    @include transition;

    &--visible {
      max-height: 20em;
      @include transition; } }

  &__content {
    width: 100%;
    //padding: 50px 130px
    display: flex;
    flex-direction: column;
    align-items: center;
    @include transition; }

  &__list {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    @include mq($from: mobile) {
      overflow-x: hidden; }

    &-header ul, &-ranks ul li div {
      display: flex;
      font-weight: bold;
      padding: 16px 32px;
      box-sizing: border-box;
      justify-content: space-around; }

    &-header ul li, &-ranks ul li div span {
      width: 100%;

      &:first-of-type {
        max-width: 48px;
        text-align: center;
        margin-right: 40px;

        .highperformance-wertung__ranking & {
          max-width: 280px;
          text-align: left; } }

      &:nth-of-type(2) {
        max-width: 320px;

        .highperformance-wertung__ranking & {
          font-weight: normal; } }

      &:nth-of-type(3) {
        max-width: 320px;

        .highperformance-wertung__ranking & {
          font-weight: normal; } }

      &:nth-of-type(4) {
        max-width: 320px; }

      &:last-of-type {
        margin-left: auto;
        max-width: 104px;
        text-align: center;

        .highperformance-wertung__ranking & {
          max-width: 140px; } } }

    &-header {
      min-width: 720px;

      @include mq($from: mobile) {
        min-width: unset; }

      ul {
        padding: 0 32px 16px;

        li {
          .highperformance-wertung__ranking & {
            font-weight: bold !important; } } } }

    &-ranks {
      min-width: 720px;

      @include mq($from: mobile) {
        min-width: unset; }

      ul li {
        &:not(:last-of-type) {
          margin-bottom: 8px; } } }

    &-entry {
      height: 48px;
      width: 100%;
      display: flex;
      align-items: center;
      border-radius: 8px;
      background-color: $c-white;

      &--current {
        background-color: $c-blue;
        color: $c-white; }

      &--top {
        @include gradient-gold;
        color: $c-black; } } }

  &__actions {
    width: 100%;
    display: flex;
    margin-top: 32px;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @include mq($from: mobile) {
      flex-direction: row; }

    .button {
      max-width: 280px;
      width: stretch;
      margin: 10px; }

    .button--has-icon {
      padding-right: 48px; }

    @include mq($from: mobile) {
      margin-top: 40px; } } }

.highperformance-wertung__section {
  padding: 0;
  height: auto; }

.highperformance-placement__section {
  height: auto;
  min-height: unset;
  background: $c-darkblue;
  margin-top: 80px;
  margin-bottom: 0;
  //display: flex
  //padding: 40px 130px
  padding: 0 0 40px 0;
  display: flex;

  //&.visible

  & > .button {
    margin: 40px auto 40px auto;
    max-width: 280px;
    align-self: center; } }
