.app-header {
  &__slider {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    min-height: 600px;

    @include mq($until: tablet) {
      min-height: 400px; }

    .app-header__content {
      @include mq($until: desktop) {
        padding-left: 130px;
        padding-right: 130px; }

      @include mq($until: tablet) {
        padding-left: 0;
        padding-right: 0; } }

    .tns-nav {
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;
      height: auto;
      z-index: 2;
      text-align: center;

      @include mq($until: desktop) {
        display: none; }

      button {
        border: none;
        border-radius: 0;
        margin: 0 5px;
        width: 40px;
        height: 2px;
        opacity: 0.5;

        &.tns-nav-active {
          opacity: 1; } } }

    .tns-inner {
      min-height: 600px;

      @include mq($until: tablet) {
        min-height: 400px; } } }

  &__bg {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 600px; }

  &__item {
    position: relative; }

  &__content {
    max-width: 1180px; }

  &__arrow {
    position: absolute;
    top: 50%;
    left: 30px;
    margin-top: -25px;
    z-index: 2;
    cursor: pointer;
    background-color: $c-blue;
    border-radius: 50%;
    width: 50px;
    height: 50px;

    @include mq($until: desktop) {
      top: auto;
      transfrom: inherit;
      bottom: 50px;
      width: 35px;
      height: 35px; }

    &--right {
      left: auto;
      right: 30px; }

    &--left {
      transform: rotate(180deg); }

    svg use {
      fill: white; }

    .icon {
      position: absolute;
      top: 15px;
      left: 18px;
      width: 20px;
      height: 20px;

      @include mq($until: desktop) {
        top: 8px;
        left: 9px; } } } }

.app-header__slider {
  h1, h2 {
    position: relative;
    color: $c-blue;
    padding: 0 10px;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($c-white, 0.8);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 5px;
      z-index: -1;

      @include mq($until: tablet) {
        border-radius: 5px; }

      @include mq($until: phone) {
        border-radius: 5px; } } }

  h1 {
    text-transform: none;
    line-height: inherit;

    @include mq($until: desktop) {
      font-size: 60px; }

    @include mq($until: tablet) {
      font-size: 48px; }

    @include mq($until: mobile) {
      font-size: 38px; }

    @include mq($until: phone) {
      font-size: 30px; } }

  h2 {
    margin-bottom: 40px;
    margin-left: 0;
    text-transform: none;

    @include mq($until: mobile) {
      font-size: 28px; }

    @include mq($until: phone) {
      font-size: 20px; } }

  h2:after {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    @include mq($until: phone) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; } } }

.app-header__item--dark {
  h1, h2 {
    color: $c-white;

    &:after {
      background-color: rgba($c-black, 0.8); } } }
