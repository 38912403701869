@keyframes shrink-bounce {
  0% {
    transform: scale(1); }

  33% {
    transform: scale(.85); }

  100% {
    transform: scale(1); } }


@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #212121;
    transform: translate3d(0, 0, 0) rotate(45deg); }

  33% {
    width: .2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg); }

  100% {
    width: .2em;
    height: .5em;
    border-color: #212121;
    transform: translate3d(0, -.5em, 0) rotate(45deg); } }


.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  box-sizing: border-box;
  user-select: none;

  &__checkmark {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    background: transparent;
    border: 1px solid #9E9E9E;
    cursor: pointer;
    transition: all 250ms cubic-bezier(.4, .0, .23, 1);
    border-radius: 4px; }

  &__checkmark .icon {
    display: none;
    pointer-events: none;
    @include icon-color($c-black);
    @include icon-size(10px); }


  &:checked + span {
    border: 10px solid $c-brightgrey;
    animation: shrink-bounce 200ms cubic-bezier(.4, .0, .23, 1);
    box-sizing: border-box;
    user-select: none;
    border-radius: 4px; }

  &:checked + span:before {
    content: "";
    position: absolute;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    transform: rotate(45deg);
    transform-origin: 0% 100%;
    animation: checkbox-check 125ms 250ms cubic-bezier(.4, .0, .23, 1) forwards;
    top: 8px;
    left: 5px; }

  &__container {
    position: relative;
    cursor: pointer;
    max-width: 100%;
    user-select: none;
    @include fontsize(12px, 1.33);
    min-height: 20px;
    color: $c-black;
    display: flex;
    //align-items: center
    transition: color 250ms cubic-bezier(.4, .0, .23, 1);
    box-sizing: border-box;

    .styleguide > & {
      margin-bottom: 20px; }

    &:hover > span, &:focus > span {
      background: rgba(255, 255, 255, .1); }

    form & {
      margin-top: 40px; }

    form &:not(:first-of-type) {
      margin-top: 10px; }

    a {
      display: contents; } // not supported in IE11

    &--disabled {
      color: $c-brightgrey; } } }
