.pagination {
  display: flex;
  width: auto;
  flex-wrap: wrap;
  align-items: center;
  margin: 42px auto 0 auto;

  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
    @include transition;
    padding: 10px 20px;

    .icon {
      @include icon-size(16px);

      .pagination--black & {
        @include icon-color($c-black); }

      .pagination--white & {
        @include icon-color($c-white); } }

    &--left .icon {
      transform: rotate(-180deg); }

    &:hover {
      transform: scale(1.2);
      cursor: pointer;
      @include transition; }

    &--hidden {
      visibility: hidden;
      pointer-events: none; } }

  &__item {
    width: 40px;
    height: 40px;
    margin: 0 8px;
    text-decoration: none;
    list-style: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--placeholder {
      margin: 0 2px;
      width: 6px;

      .pagination__link {
        cursor: unset; } }

    &--current {
      background-color: $c-blue; } }

  &__link {
    text-decoration: none;
    color: $c-white;
    @include fontsize(16px, 1.5);
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%; } }

nav {
  display: flex; }
