@import "../../../node_modules/sass-mq/mq";

// based on https://bulma.io/documentation/modifiers/responsive-helpers/#hide
$mq-breakpoints: (phone: 480px, mobile: 768px, tablet: 1024px, desktop: 1215px, widescreen: 1407px);

$mq-static-breakpoint: tablet;

// force correct mqpacker order
html {
  @include mq($until: widescreen) {
    margin: 0; }
  @include mq($until: desktop) {
    margin: 0; }
  @include mq($until: tablet) {
    margin: 0; }
  @include mq($until: mobile) {
    margin: 0; }
  @include mq($until: phone) {
    margin: 0; } }
