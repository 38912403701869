.private-header {
  position: relative;

  &__section {
    background-color: $c-darkblue;
    min-height: 232px;
    display: flex;
    flex-direction: row;
    background-image: url("/images/private-section/user-section-teaser-bg.jpg");
    box-sizing: border-box;
    overflow: hidden;
    @include transition;

    .app-header__right {
      display: none;

      @include mq($from: tablet) {
        position: absolute;
        top: 7%;
        display: block;
        left: 70%;
        @include transition;

        @include mq($from: desktop) {
          @include transition;
          left: 70%; }

        .private-header__section--open & {
          top: 27%;
          @include transition; } } }

    .app-header__left {
      margin: 0 auto;

      p {
        margin: 0 0 30px 0;
        align-self: flex-start;
        width: auto; }

      @include mq($from: tablet) {
        margin: 0 auto 0 0; } }

    video {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%; }

    .app-header__actions {
      display: block;

      button:first-of-type {
        display: block;
        color: $c-white;
        margin-bottom: 20px;
        padding-left: 26px;

        .icon {
          left: 0;
          @include icon-color($c-white);
          transform: translateY(-50%) rotate(90deg);
          width: 16px;
          height: 16px; } }


      button:last-of-type {
        display: none; } }

    &--open {
      height: auto;

      .app-header__actions {

        button:first-of-type .icon {
          transform: translateY(-50%) rotate(-90deg);
          @include transition; }

        button:last-of-type {
          display: block;
          margin-left: 0; } } } }

  &__image {
    margin-right: 40px;
    margin-top: auto;
    position: absolute;
    line-height: 0;
    right: 0;
    bottom: 0;
    width: 222px;
    height: 258px;
    overflow: hidden;
    z-index: 2;
    max-height: 257px;
    @include transition;

    @include mq($from: mobile) {
      overflow: hidden;
      max-height: 540px;
      right: 20%;
      @include transition; }

    .private-header__section--open & {
      @include mq($from: tablet) {
        width: 466px;
        height: 540px;
        @include transition; } } }

  &__content {
    padding: 40px 20px 20px;
    color: $c-white;
    z-index: 5;
    display: flex;
    width: 100%;
    @include transition;

    @include mq($from: tablet) {
      padding: 100px 60px 60px;
      @include transition; }

    @include mq($from: desktop) {
      padding: 100px 130px 80px 130px;
      @include transition; }

    h2 {
      margin-bottom: 70px; }

    p {
      display: block;
      max-height: 0;
      overflow: hidden;
      max-width: 480px;
      margin: 0 0 30px 0;
      @include transition;

      .private-header__section--open & {
        max-height: 40em;
        @include transition; } }

    .jackpot-counter {
      min-width: 0;
      height: auto;
      margin-top: 100px; } } }



