.praemienclub-performance {

  &__section {
    @include gradient-gold;
    margin-bottom: 40px;
    padding-top: 128px;

    @include mq($from: desktop) {
      padding-top: 104px; }

    .app-header__actions--mobile button {
      box-sizing: content-box; }

    .highperformance-performance__box {
      background-color: $c-white;
      color: $c-black;
      height: auto; } } }
