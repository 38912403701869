.recommendations {
  margin-bottom: 40px;
  margin-top: 40px;

  .product {
    margin: 0 20px 0 0;

    &:last-of-type {
      margin-right: 0; } }

  &__section {
    background-color: $c-darkblue;
    //display: flex
    //flex-direction: column
    //justify-content: center
    //align-items: center
    padding: 40px 130px;
    @include transition;

    @include mq($until: tablet) {
      padding: 44px;
      @include transition; }

    @include mq($until: phone) {
      padding: 20px 0 20px 20px;
      @include transition; }

    h2 {
      color: $c-white; } }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center; } }
