.compl-tool {

  // Global Styles
  .header {
    h1 {
      text-transform: none; } }

  .spacer {
    display: block;
    margin-bottom: 80px; }

  .content {
    padding: 0 250px 80px;
    position: relative;

    @include mq($until: tablet) {
      padding: 0 20px 80px; }

    &--small {
      padding: 80px 300px 50px;

      @include mq($until: desktop) {
        padding: 80px 250px 50px; }

      @include mq($until: tablet) {
        padding: 80px 20px; } }

    &.padding-top {
      padding-top: 80px; }

    h2, h3 {
      margin-bottom: 30px; }

    h3 {
      font-size: 40px;

      @include mq($until: mobile) {
        font-size: 30px; } }

    h4 {
      font-size: 24px;
      margin-bottom: 15px; } }

  p {
    margin-bottom: 20px; }

  .compl__buttons {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    @include mq($until: mobile) {
      flex-wrap: wrap; } }

  .compl-check {
    width: 100%;
    display: inline-block;
    margin-top: 20px;

    label {
      font-size: 16px; } }

  .compl-btn {
    text-align: center;
    margin: 0 10px;

    @include mq($until: mobile) {
      margin-bottom: 15px; }

    .button--icon--right {
      .icon {
        position: absolute;
        top: 26px;
        right: 15px;
        left: auto;
        max-width: 20px; }

      &.button--has-icon {
        padding: 0 45px 0 20px;
        display: inline-block;
        text-decoration: none; } }

    .button--icon--left {
      .icon {
        position: absolute;
        top: 7px;
        left: 15px;
        max-width: 20px;
        transform: rotate(-180deg); }

      &.button--has-icon {
        padding: 0 20px 0 45px; } } }


  // Stepper
  .stepper {
    width: 100%;
    max-width: 700px;
    margin: 80px auto;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    position: relative;

    .step__inner {
      position: relative;

      &:after {
        content: "";
        display: block;
        width: 100px;
        height: 5px;
        background-color: $c-background;
        position: absolute;
        top: 18px;
        left: 70px;
        z-index: 0;

        @include mq($until: mobile) {
          width: 70px;
          left: 50px; }

        @include mq($until: phone) {
          width: 50px;
          left: 10px; } } }

    span {
      width: 40px;
      height: 40px;
      display: block;
      font-size: 26px;
      font-weight: bold;
      border-radius: 50%;
      margin: 0 40px;
      text-align: center;
      padding-top: 6px;
      background-color: $c-background;
      color: $c-white;
      box-sizing: border-box;
      position: relative;
      z-index: 1;

      @include mq($until: mobile) {
        margin: 0 20px; }

      @include mq($until: phone) {
        margin: 0 5px; } }

    .step:nth-child(6) .step__inner:after {
      display: none; } }

  .stepper--1 {
    .step:nth-child(1) {
      span {
        color: $c-white;
        background-color: $c-orange; } } }

  .stepper--2 {
    .step:nth-child(1),
    .step:nth-child(2) {
      span {
        color: $c-white;
        background-color: $c-orange; }

      .step__inner:after {
        background-color: $c-orange; } }

    .step:nth-child(2) {
        .step__inner:after {
          background-color: $c-background; } } }

  .stepper--3 {
    .step:nth-child(1),
    .step:nth-child(2),
    .step:nth-child(3) {
      span {
        color: $c-white;
        background-color: $c-orange; }

      .step__inner:after {
        background-color: $c-orange; } }

    .step:nth-child(3) {
        .step__inner:after {
          background-color: $c-background; } } }

  .stepper--4 {
    .step:nth-child(1),
    .step:nth-child(2),
    .step:nth-child(3),
    .step:nth-child(4) {
      span {
        color: $c-white;
        background-color: $c-orange; }

      .step__inner:after {
        background-color: $c-orange; } }

    .step:nth-child(4) {
        .step__inner:after {
          background-color: $c-background; } } }

  .stepper--5 {
    .step:nth-child(1),
    .step:nth-child(2),
    .step:nth-child(3),
    .step:nth-child(4),
    .step:nth-child(5) {
      span {
        color: $c-white;
        background-color: $c-orange; }

      .step__inner:after {
        background-color: $c-orange; } }

    .step:nth-child(5) {
        .step__inner:after {
          background-color: $c-background; } } }

  .stepper--6 {
    .step:nth-child(1),
    .step:nth-child(2),
    .step:nth-child(3),
    .step:nth-child(4),
    .step:nth-child(5),
    .step:nth-child(6) {
      span {
        color: $c-white;
        background-color: $c-orange; }

      .step__inner:after {
        background-color: $c-orange; } }

    .step:nth-child(6) {
        .step__inner:after {
          background-color: $c-background; } } }

  // Profile Styles
  .profile-header__section {
    margin-top: 0;
    padding-top: 0;

    h2 {
      padding-top: 10px; }

    p {
      max-width: 820px;
      margin: 0 auto 20px; } }

  .profile-header__section,
  .profile__personal-data,
  .profile__newsletter {
    box-shadow: inherit;
    background: transparent;
    color: $c-black;

    .button {
      color: $c-black; }

    label {
      color: $c-darkgrey; } }

  .profile__personal-data,
  .profile-login__section {
    background-color: $c-background;
    margin-bottom: 40px;

    input,
    select,
    .selectfield__selected {
      background-color: $c-white; }

    .button--primary {
      background-color: $c-white;
      border: 2px solid $c-turquoise;
      box-sizing: border-box;
      line-height: 46px;
      color: $c-black;

      &:hover {
        background-color: $c-turquoise; } } }

  .profile-header__image {
    border-color: $c-background; }

  .profile__update__container {
    .profile__messages {
      padding: 10px 8px;
      text-align: center;
      border-radius: 10px;
      max-width: 800px;
      margin: 0 auto 40px;

      &--success {
        background-color: $c-turquoise; }

      &--error {
        background-color: $c-darkred;
        color: $c-white; } } }

  // Registration Styles
  .compl__upload {
    background-color: $c-background;

    .content {
      padding: 30px 250px;
      text-align: center;

      @include mq($until: widescreen) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 30px 20px; } }

    .button {
      margin: 10px 0; }

    .button--secondary {
      background-color: $c-white;

      &:hover {
        background-color: $c-turquoise; } }

    .button--card {
      background-color: $c-white;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      padding: 0;
      width: auto;
      height: auto;
      line-height: inherit;
      overflow: hidden;
      border-radius: 15px;

      &:hover, &:focus {
        color: $c-white; }

      .button-txt {
        background-color: $c-blue;
        padding: 15px 25px;
        display: block; }

      .icon {
        position: relative;
        display: block;
        margin: 20px auto;
        width: 85px;
        height: 85px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        background: $c-white;
        text-align: center;
        top: 0;
        left: 0;
        transform: inherit;

        &__inner {
          padding: 20px 0 0; }

        svg {
          margin: 0 auto;
          position: relative;
          top: 0;
          left: 0;
          height: 40px;

          use {
            fill: $c-darkred; } } } }

    .button--card-active {
      color: $c-blue;

      &:hover, &:focus {
        color: $c-blue; }

      .button-txt {
        background-color: $c-turquoise; } } }

  .compl__info {
    padding-top: 80px;

    h3 {
      font-weight: 300;
      margin-bottom: 20px; }

    li {
      font-weight: bold;
      line-height: 1.6; }

    .table-seller {
      width: 100%;
      overflow: auto; }

    table {
      width: 950px;
      max-width: 100%;
      margin-top: 30px;

      thead, tbody {
        width: 100%; }

      thead tr {
        font-weight: bold;
        display: flex;
        justify-content: space-between;

        td {
          background: none;
          padding-bottom: 5px; } }

      tr {
        width: 100%;
        display: flex;
        justify-content: space-between; }

      td {
        flex: 0 0 20%;
        display: inline-block;
        text-align: left;
        border: 1px solid $c-white;
        background: $c-background;
        padding: 15px;

        &:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px; }

        &:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px; } } }

    .compl__pagination {
      width: 100%;

      li {
        margin: 0 5px;

        &.active {
          button {
            background-color: $c-blue;
            color: $c-white; } } }

      button {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: none;
        background-color: $c-background;
        text-align: center;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: $c-blue;
          color: $c-white;

          .icon {
            svg, use {
              fill: $c-white; } } }

        .sr-only {
          display: none; }

        .icon {
          max-width: 15px;
          height: auto;
          position:  relative;
          top: 1px;
          left: 2px;

          &.arrow--left {
            transform: rotate(-180deg);
            left: -2px; } } } } }

  .compl__user__container {
    background-color: $c-background;
    margin-top: 0;
    transition: all 0.3s ease-in-out;
    max-height: 0px;
    overflow: hidden;
    padding: 0;

    &--open {
      max-height: 2000px;
      overflow: visible;
      padding: 50px 0;
      margin-top: 10px; }

    &__form {
      @include mq($until: tablet) {
        padding: 0 20px; }

      .success-message,
      .error-message {
        background-color: $c-turquoise;
        padding: 20px 10px;
        text-align: center;
        border-radius: 10px;
        position: relative;
        top: 0;
        max-width: 800px;
        margin: 0 auto 40px;

        p {
          margin: 0; } }

      .error-message {
        background-color: $c-notification-red;
        color: $c-white; } }

    h2 {
      text-align: center;
      margin-bottom: 20px; }

    .profile__personal-data {
      background-color: $c-white;
      margin-bottom: 30px;
      position: relative;

      input,
      select,
      .selectfield__selected {
        background-color: $c-background; } }

    .button-close {
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      z-index: 10;

      .icon {
        max-width: 20px; } }

    .success-message {
      background-color: $c-turquoise;
      padding: 10px 8px;
      text-align: center;
      border-radius: 10px;
      max-width: 800px;
      margin: 0 auto 40px;

      p {
        margin: 0; } } }

  .compl__upload__container {
    background-color: $c-background;
    margin-top: 0;
    transition: all 0.3s ease-in-out;
    max-height: 0px;
    overflow: hidden;
    padding: 0;

    &--open {
      max-height: 2000px;
      overflow: visible;
      padding: 50px 0;
      margin-top: 10px; }

    .profile__personal-data {
      background-color: $c-white;

      .compl-btn {
        margin: 0 auto;
        position: relative;
        top: 15px;
        display: block; } }

    h3 {
      text-align: center;
      margin-bottom: 10px;
      font-size: 28px; }

    .button-send {
      text-align: center; }

    .dropzone {
      border: 2px solid $c-grey;
      text-align: center;
      padding: 30px;
      margin-bottom: 45px;
      border-radius: 10px;
      position: relative;
      top: 45px;
      cursor: pointer; }

    .content {
      max-width: 820px;
      padding: 0;

      @include mq($until: tablet) {
        padding: 0 20px; } }

    .button-close {
      position: absolute;
      top: 0;
      right: 15px;
      cursor: pointer;
      z-index: 10;

      .icon {
        max-width: 20px; } }

    .success-message,
    .error-message {
      background-color: $c-turquoise;
      padding: 20px 10px;
      text-align: center;
      border-radius: 10px;
      position: relative;
      top: 0;
      margin-bottom: 40px; }

    .error-message {
      background-color: $c-notification-red;
      color: $c-white;

      p {
        margin: 0; } }

    .preview {
      width: 100%;
      overflow: auto;
      margin-bottom: 30px;

      @include mq($until: tablet) {
        padding: 0 20px; } }

    table {
      // width: 1830px
      width: 1100px;
      max-width: 100%;
      margin-top: 10px;
      margin-bottom: 30px;

      thead, tbody {
        width: 100%; }

      thead tr {
        font-weight: bold;
        display: flex;
        justify-content: space-between;

        td {
          background: none;
          padding-bottom: 5px; } }

      tr {
        width: 100%;
        display: flex;
        justify-content: space-between; }

      td {
        // flex: 0 0 10%
        flex: 0 0 20%;
        display: inline-block;
        text-align: left;
        border: 1px solid $c-background;
        background: $c-white;
        padding: 15px;

        &:first-child {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px; }

        &:last-child {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px; } } } }


  .compl-blank-image {
    background-color: $c-grey;
    width: 100%;
    height: 100%; }

 }  // Login Styles
.login__wrapper .login-pic-compl {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0; }

.login__wrapper .login-compl-bg {
  height: 100%; }

.navbar__user.linked a:hover .navbar__username-inner {
  text-decoration: underline; }

.navbar__container--compl {
  .button--icon--right {
    margin-right: 0 !important;
    font-size: 13px;
    font-weight: 300;

    &:hover, &:focus {
      padding: 0 45px 0 20px !important; }

    .icon {
      position: absolute;
      top: 23px;
      right: 5px;
      left: auto !important;
      max-width: 20px; }

    &.button--has-icon {
      padding: 0 45px 0 20px;
      display: inline-block;
      text-decoration: none; } } }
