.styleguide {

  .navbar__container {
    position: relative; } }

.navbar {

  &__container {
    width: 100%;
    max-width: 1440px;
    background-color: transparent;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: visible;
    position: relative;
    z-index: 100;
    box-shadow: 0 2px 8px rgba($c-black, .16);
    @include transition;

    &--black {
      background-color: $c-black;
      @include transition; }

    &--white {
      background-color: $c-white;
      @include transition; }

    &--grey {
      background-color: $c-darkgrey; }

    &--scroll {
      position: fixed;
      top: -60px;
      @include transition;

      @include mq($until: mobile) {
        top: 0; } }

    &--scrolling-up {
      top: 0;
      @include transition; }

    @include mq($until: mobile) {
      height: 48px;
      position: fixed;
      top: 0; } }

  &__header {
    width: 180px;
    height: 80px;
    margin-left: 130px;
    align-self: flex-start;
    z-index: 101;
    box-shadow: 0 2px 8px rgba($c-black, .16);
    @include transition;

    @include mq($until: tablet) {
      margin-left: 44px; }
    @include mq($until: mobile) {
      margin-left: 20px; }

    @include mq($from: mobile) {
      .navbar__container--scroll & {
        transform: translateY(-20px);
        @include transition; }

      .navbar__container--scrolling-up & {
        transform: translateY(0);
        @include transition; } }

    @include mq($until: mobile) {
      height: 48px;
      width: 127px;
      margin-left: 0;
      box-shadow: none; } }

  &__logo {
    display: block;
    height: inherit;
    overflow: hidden;

    .logo {
      height: inherit;
      display: block;
      background: $c-white;
      transition: none;

      @include mq($until: mobile) {
        width: 127px; }

      img {
        object-fit: cover;
        width: auto;
        height: 100%;
        top: 50%;
        left: 50%;
        position: relative;
        transform: translate(-50%, -50%);
        max-height: 100%;
        max-width: 100%; } } }

  &__user {
    position: relative;
    margin-right: 130px;
    display: flex;
    align-items: center;
    height: 60px;
    z-index: 101;
    background-color: $c-black;
    // flex-grow: 1
    @include transition;

    .navbar__container--grey & {
      background-color: $c-darkgrey; }

    @include mq($until: mobile) {
      background-color: $c-white; }

    .button--has-icon.button--noOutline {
      padding: 0 0 0 32px;
      line-height: 50px;
      color: $c-white;
      margin-right: 20px;
      margin-left: 0;

      @include mq($until: desktop) {
        margin-right: 15px; }

      .navbar__container--white & {
        color: $c-black; }

      .icon {
        left: 0;
        @include icon-size(20px);
        @include icon-color($c-white);

        .navbar__container--white & {
          @include icon-color($c-black); } }

      &:hover, &:focus {
        width: auto;
        padding: 0 0 0 32px;
        line-height: 50px;
        transform: none;
        color: $c-turquoise;

        .icon {
          @include icon-color($c-turquoise); } } }

    .button:first-of-type {
      margin-left: auto;
      @include mq($until: mobile) {
        display: none; } }

    .button.navbar__categories-btn {
      .icon {
        top: 50%;
        width: 16px;
        height: 16px;
        transform: translateY(-50%) rotate(90deg);
        transform-origin: center;
        @include transition;

        .navbar__container--open & {
          transform: translateY(-50%) rotate(-90deg);
          @include transition; } } }

    .button.navbar__home-btn {
      //margin-left: auto
      margin-right: 20px;

      &:hover, &:focus {
        width: auto;
        padding: 0 20px;
        transform: none;
        color: $c-turquoise; } }

    .button:last-of-type {
      margin-left: 20px;
      margin-right: 0;

      .navbar__container--loggedOut & {
        margin-left: auto;

        .icon {
          @include icon-size(20px);
          transform: none; } } }

    @include mq($until: tablet) {
      margin-right: 44px; }

    @include mq($until: mobile) {
      height: 48px;
      margin-right: 0;

      .button {
        display: none; }
      .button:nth-of-type(2) {
        margin-left: auto; } }

    @include mq($from: phone) {
      @include mq($until: mobile) {
        .button.navbar__logout-btn {
          display: none; } } }

    &-bar {
      height: 100%;
      width: 140px;
      background-color: $c-orange;
      padding: 10px;
      box-sizing: border-box;
      justify-content: center;
      position: relative;
      margin-left: auto;
      display: block;

      .navbar__micro-user & {
        padding: 5px 10px; }

      .navbar__container--club & {
        background-color: $c-gold; }

      .navbar__user & {
        @include drop-shadow; }

      @include mq($from: mobile) {
        margin-left: 0; }
      @include mq($until: mobile) {
        min-width: max-content; }

      .icon {
        position: absolute;
        right: 8px;
        bottom: 8px;
        transform: rotate(90deg);
        @include icon-color($c-white);
        @include icon-size(15px);
        @include transition;

        .navbar__container--mobile-open & {
          transform: rotate(270deg);
          @include transition; }

        @include mq($from: mobile) {
          display: none; } } } }

  &__logout-btn {
    display: block;

    .navbar__container--loggedOut & {
      display: none; } }

  @keyframes backAndForth {
    0% {
      transform: translateX(0); }
    10% {
      transform: translateX(0); }
    45% {
      transform: translateX(-100%) translateX(120px); }
    55% {
      transform: translateX(-100%) translateX(120px); }
    90% {
      transform: translateX(0); }
    100% {
      transform: translateX(0); } }

  &__username {
    color: $c-white;
    width: 100%;
    overflow: hidden;
    max-width: 120px;
    display: block;
    line-height: 0;

    &-inner {
      @include fontsize(14px, 1.28);
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;

      &--scroll {
        animation: backAndForth 5s linear infinite;

        .oldie & {
          animation: none; } } } }

  &__balance {
    color: $c-white;
    width: 100%;
    overflow: hidden;
    max-width: 120px;
    display: block;

    &-inner {
      font-weight: bold;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      @include fontsize(20px, 1.11);

      @include mq($until: mobile) {
        padding-right: 18px; }

      &--scroll {
        animation: backAndForth 5s linear infinite; } } }


  &__menu-button--mobile {
    width: 53px;
    height: 48px;
    background-color: $c-darkgrey;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      @include icon-color($c-white);
      @include icon-size(20px); }

    @include mq($from: mobile) {
      display: none; } }

  &__category-button {
    //IE11
    position: fixed;
    position: sticky;
    height: 40px;
    top: 48px;
    width: 100%;
    width: stretch;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include drop-shadow;
    padding-right: 21px;
    background-color: $c-white;
    z-index: 98;

    @include mq($from: mobile) {
      display: none; }

    b {
      padding-right: 7px; }

    .icon {
      @include icon-size(16px);
      transform: rotate(90deg);
      @include transition;

      .navbar__container--open ~ & {
        transform: rotate(-90deg);
        @include transition; } } }

  // Language Switcher
  &__language-switch {
    color: $c-white;
    margin-right: 20px;

    @include mq($until: tablet) {
      display: none; }

    button {
      padding: 0;
      margin: 0 5px;

      &:last-of-type {
        margin: 0 5px !important; }

      &:hover, &:focus {
        width: auto; } }

    &--menu {
      display: none;
      color: $c-black;
      float: right;
      position: relative;
      top: -3px;

      @include mq($until: tablet) {
        display: block; }

      button {
        color: $c-black !important;
        padding: 0;
        margin: 0 5px;
        line-height: inherit;
        height: auto;

        &:last-of-type {
          margin: 0 5px !important; }

        &:hover, &:focus {
          width: auto; } } } } }

