.promobooster-code__section {
  display: flex;
  padding: 40px 0;
  justify-content: center;
  align-items: center;
  height: 128px;
  background-color: $c-darkblue;
  box-sizing: border-box;
  color: $c-white;

  .textfield {
    align-self: baseline;
    max-width: 580px;
    width: stretch; }

  .button {
    max-width: 180px;
    width: stretch; }

  h3, .textfield {
    margin-right: 20px; } }

.promobooster-selection__section {
  padding: 60px 130px;

  .card-slider__label {
    color: $c-black; } }
