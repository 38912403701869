.button-wishlist {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  border-radius: 50%;
  height: 46px;
  cursor: pointer;
  margin-top: 0;
  @include transition;

  &:hover {
    transform: perspective(1px) scale(1.1);
    @include transition;
    @include drop-shadow; }

  .icon {
    @include icon-size(20px);
    @include icon-color($c-white); }

  .icon--heart-active {
    @include icon-color($c-white); }

  &--like {
    background-color: $c-brightgrey;
    @include transition; }

  &--active {
    background-color: $c-orange;
    @include transition; }

  &--club.button-wishlist--active {
    background-color: $c-brightgold; } }
