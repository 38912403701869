.app-header {
  position: relative;

  &__section {
    background-color: $c-darkblue;
    min-height: 312px;
    height: auto;
    display: flex;
    flex-direction: row;
    background-image: url("/images/app/jackpot/img--bg-jackpot.png");
    background-position: bottom;
    box-sizing: border-box;
    position: relative;
    //overflow: hidden
    @include transition;

    &--open {
      height: auto;
      @include transition; }

    video {
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
      border-radius: 1px;
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .oldie & {
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        overflow: hidden;
        height: auto; } } }

  @include mq($from: mobile) {
    min-height: 680px;
    @include transition;

    &--open {
      height: auto;
      @include transition; } }

  @include mq($from: tablet) {
    min-height: 332px;
    @include transition;

    &--open {
      height: auto;
      @include transition; } }

  &__box {
    line-height: 0;
    display: none;
    margin-top: auto;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 241px;
    overflow: hidden;
    z-index: 10;
    @include transition;

    @include mq($from: mobile) {
      display: block; }

    @include mq($from: tablet) {
      left: 40%;
      width: 204px;
      height: 258px;
      z-index: 20;
      margin-bottom: -30px;
      @include transition;

      .app-header__section--open & {
        margin-bottom: 30px;
        z-index: 1;
        width: 359px;
        height: 453px;
        transform: translateX(10%); } }

    .image {
      overflow: hidden;
      @include transition;

      @include mq($from: tablet) {
        @include transition; }

      img {
        width: 100%;
        height: 100%; } } }

  &__content {
    padding: 20px 10px 40px 10px;
    width: 100%;
    color: $c-white;
    display: flex;
    flex-direction: column;
    background: no-repeat;
    @include transition;

    @include mq($from: mobile) {
      padding-left: 60px;
      padding-right: 60px;
      padding-top: 60px;
      @include transition; }

    @include mq($from: tablet) {
      flex-direction: row;
      padding-left: 80px;
      padding-top: 110px;
      padding-right: 80px;
      justify-content: space-between;
      @include transition; }

    @include mq($from: desktop) {
      padding-left: 130px;
      padding-right: 130px; }

    & > p {
      display: none;

      @include mq($from: tablet) {
        display: block;
        max-width: 480px; } } }

  &__actions {
    // display: none
    position: relative;

    @include mq($until: tablet) {
      text-align: center;
      padding-top: 30px;
      margin-top: 8px;
      margin-bottom: 7px;

      .button {
        margin-left: 0px; }
      .button--undefined {
        display: none; } }


    @include mq($from: tablet) {
      display: block;
      max-width: 480px;

      .app-header__section--open & {
        display: flex;
        flex-direction: column; }

      .button:first-of-type {
        color: $c-white;
        padding-left: 26px;
        width: max-content;
        margin-bottom: 20px;
        align-self: flex-start;

        .icon {
          left: 0;
          transform: translateY(-50%) rotate(90deg);
          @include icon-color($c-white);
          @include icon-size(16px);

          .app-header__section--open & {
            transform: translateY(-50%) rotate(-90deg); } } }


      .button:last-of-type {
        display: none;
        max-width: 280px;

        .app-header__section--open & {
          display: block;
          margin-left: 0; } } } }

  &__actions--mobile {
    display: block;
    text-align: center;
    z-index: 40;
    position: relative;

    .button {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      box-sizing: content-box; }

    @include mq($from: tablet) {
      display: none !important; } }

  &__heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: max-content;

    @include mq($from: tablet) {
      min-width: 430px;
      justify-content: flex-start;
      align-items: flex-start;
      z-index: 22;
      margin-right: 10px; }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: max-content;

      @include mq($from: tablet) {
        flex-direction: row;
        align-items: unset; }

      & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        @include transition;

        @include mq($from: mobile) {
          margin-bottom: 20px; }

        .icon {
          @include icon-color($c-white);
          @include icon-size(40px);

          @include mq($from: mobile) {
            @include icon-size(80px);
            flex-shrink: 100;
            margin-right: 20px;
            @include transition; }

          @include mq($from: tablet) {
            @include icon-size(60px); } } } }

    h1 {
      text-transform: uppercase;
      @include mq($until: mobile) {
        @include fontsize(42px, 1.14);
        @include transition; }
      @include mq($from: mobile) {
        @include fontsize(86px, 0.9); } }

    h2 {
      text-align: center;
      margin-bottom: 20px;

      @include mq($until: phone) {
        @include fontsize(18px, 1.22); }

      @include mq($from: tablet) {
        text-align: left;
        margin-left: 80px; } } }

  &__left {
    display: flex;
    flex-direction: column;
    min-height: max-content;
    z-index: 23;

    h3 {
      max-width: 480px;
      width: stretch;

      .app-header__section--open & {
        display: block;

        @include mq($until: tablet) {
          margin: 40px auto 0 auto;
          text-align: left; } } }

    p {
      max-width: 480px;
      max-height: 0;
      overflow: hidden;
      @include transition;

      @include mq($until: tablet) {
        margin: 0 auto; }

      .app-header__section--open & {
        display: block;
        max-height: 40em; }

      @include mq($from: tablet) {
        margin-bottom: 30px; } }

    a {
      color: $c-white;

      &:hover, &:focus {
        color: $c-orange; } } }

  &__right {} }
//display: flex

.jackpot-counter {
  min-width: 300px;
  height: 177px;
  border-radius: 8px;
  background-color: $c-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 40px 20px;
  position: relative;
  color: $c-black;
  box-sizing: border-box;
  margin: 40px auto 74px auto;
  z-index: 8;
  @include drop-shadow;
  @include transition;

  &__bank {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 270px;
    height: 74px;
    display: flex;
    flex-direction: column;
    background: transparent linear-gradient(180deg, rgba(70, 70, 118, 1) 0%, rgba(54, 54, 105, 1) 100%) 0% 0% no-repeat padding-box;
    color: $c-white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, 100%);
    z-index: 12;
    @include drop-shadow; }


  @include mq($from: mobile) {
    min-width: 447px;
    width: max-content;
    height: 223px;
    @include transition; }

  @include mq($from: tablet) {
    top: -20px;
    margin-top: 0;
    @include transition;

    .app-header__section--open & {
      top: auto;
      @include transition; } }

  &__icon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
    background-color: $c-white;
    @include drop-shadow;

    .lottie__uncontrolled {
      width: 50px;
      height: 50px; } }

  & > h2 {
    margin-bottom: 10px;
    @include transition;

    @include mq($until: mobile) {
      @include fontsize(22px, 1.04);
      @include transition; } }

  &__wrapper {
    display: flex;

    .counter-spawner {
      margin-bottom: 10px;
      margin-right: 6px; } } }


