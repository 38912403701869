.modal-points {
  min-width: 350px;
  min-height: 300px;
  height: max-content;
  background-color: $c-white;
  position: fixed;
  //border: 2px solid $c-brightgrey
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -25%);
  z-index: 200;
  @include drop-shadow;

  .paypal-buttons-all {
    width: 100%; }

  &__upper {
    flex-direction: column;
    display: flex;
    padding: 20px 20px 0 20px; }

  &__close {
    align-self: flex-end;
    cursor: pointer;

    .icon {
      @include icon-size(18px); } }

  &__row {
    display: flex;
    flex-direction: column;

    & > a {
      display: block;
      margin: 0 auto; } }

  &__title {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px; }

  &__lower {
    margin: 0 20px 20px 20px;
    padding: 20px;
    box-sizing: border-box;
    background-color: $c-darkblue;
    border-radius: 8px;


    .button {
      width: 280px;
      margin: 0 auto; }

    &--payment {
      position: relative;
      padding: 0 20px 20px 20px;

      a, a .button, .button, form {
        width: 100%; }

      .textfield-group {

        & > div:first-of-type {
          margin-right: 20px;
          width: stretch; }

        & > div:last-of-type {
          max-width: 85px; }

        .textfield {
          margin-top: 0; } }

      .modal-points__row {
        margin-bottom: 20px;
        display: flex; }

      & > span.modal-points__row:last-of-type {
        margin-bottom: 0; } } }

  &__missing {
    @include fontsize(24px, 1.16);
    font-weight: bold;
    color: $c-orange;
    margin-left: 5px;

    &-label {
      @include fontsize(24px, 1.16);
      font-weight: bold;
      padding-bottom: 4px;
      color: $c-white; } }

  &__price {
    color: $c-white;
    @include fontsize(16px, 1.5);
    padding-bottom: 20px; } }

