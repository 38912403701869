.account {

  &-header {

    &__section {
      padding: 60px 130px;
      background-color: $c-darkblue;
      color: $c-white;
      @include transition;

      @include mq($until: tablet) {
        padding: 20px 44px;
        @include transition; }

      @include mq($until: phone) {
        padding: 20px 10px;
        @include transition; }

      & > h2 {
        text-align: center;
        padding-bottom: 40px;

        @include mq($until: phone) {
          padding-bottom: 20px; } }

      .accordion {
        margin-top: 0;
        margin-bottom: 20px;

        .icon {
          @include icon-color($c-white); }

        .account__actions {
          margin: 0 auto;

          .button:first-of-type .icon {
            @include icon-color($c-black); }

          .flatpickr-input.flatpickr-mobile {
            padding-left: 20px;
            height: 48px;
            width: 195px;
            box-sizing: border-box;
            border: none;
            color: $c-black;
            background: $c-lightgrey;
            border-radius: 8px;
            @include fontsize(18px, 1.33);

            &::placeholder {
              color: $c-black; }

            .textfield--disabled & {
              pointer-events: none;
              color: $c-grey;

              &::placeholder {
                color: $c-grey; } } } } } }


    &__content {
      display: flex;

      @include mq($until: mobile) {
        flex-wrap: wrap; } } }

  &__left {
    margin-bottom: 40px;
    flex-shrink: 100;
    flex-grow: 100;
    border-radius: 8px; }
  //width: stretch

  &__right {
    margin-left: 20px;
    position: relative;

    @include mq($until: mobile) {
      flex: 1;
      margin-left: 0; } }


  &__filter {
    display: none;
    flex-direction: column;
    padding-bottom: 60px;

    @include mq($from: mobile) {
      display: flex; }

    & > h3 {
      padding-bottom: 21px; }

    & > h2 {
      color: $c-orange; }

    & > b:last-of-type {
      padding-top: 20px; } }

  &__info--mobile {
    display: none;

    div {
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      width: stretch;

      b {
        max-width: 120px; }

      &:first-of-type > h4 {
        font-weight: normal;
        color: $c-white; }

      h4 {
        color: $c-orange; } }

    @include mq($until: mobile) {
      justify-content: space-between;
      display: flex; } }

  &__actions {
    background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box;
    width: 280px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-bottom: 40px;
    box-sizing: border-box;
    @include drop-shadow;

    &--mobile {
      display: block;

      @include mq($from: mobile) {
        display: none; } }

    .orders__date:last-of-type {
      padding-bottom: 20px; }

    .button {
      padding-left: calc(50% - 48px);
      text-align: left;
      width: 240px;
      margin-left: 0;

      &:first-of-type {
        margin-bottom: 10px; } } }

  &__transaction {
    display: flex;
    align-items: center;
    min-height: 48px;
    width: stretch;
    margin-bottom: 20px;
    padding: 16px 20px;
    box-sizing: border-box;
    background: transparent linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box;
    border-radius: 8px;
    @include drop-shadow;

    span:first-of-type {
      min-width: 90px;
      //padding-right: 40px
      width: 20%; }

    span:nth-of-type(2) {
      max-width: 60%;
      width: stretch; }

    b {
      min-width: max-content;
      width: 20%;
      text-align: right;
      padding-left: 16px;
      margin-left: auto; }

    &-labels {
      display: flex;
      padding: 0 20px 21px 20px;

      @include mq($until: phone) {
        justify-content: space-between; }

      @include mq($from: phone) {
        h3:first-of-type {
          width: 20%;
          min-width: 90px; }

        h3:nth-of-type(2) {
          max-width: 60%;
          width: 60%; }

        h3:last-of-type {
          min-width: max-content;
          width: 20%;
          text-align: right; } } }

    &-container {
      display: flex;
      flex-direction: column; } } }
