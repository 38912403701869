@keyframes planes-fly {
  0% {
    transform: translateX(720px); }
  100% {
    transform: translateX(0px); } }

.salespush-header__section {
  height: auto;
  background-image: url("/images/app/heros-place/img--bg-herosplace.jpg");
  background-position: center;
  overflow: hidden;

  &.app-header__section--open {
    height: auto;

    .app-header__box {
      left: 95%; }

    @include mq($until: tablet) {
      .app-header__left h3,
      .app-header__left p {
        margin-left: auto;
        margin-right: auto; } } }

  @include mq($from: mobile) {
    min-height: 540px;

    &.app-header__section--open {
      height: auto;

      .app-header__box {
        left: auto;
        right: -290px;
        @include transition; } } }

  @include mq($from: tablet) {
    min-height: 310px;

    &.app-header__section--open {
      height: auto;

      .app-header__box {
        width: 1012px;
        height: 517px;
        top: 220px;
        left: 45%;
        bottom: 103px;
        @include transition; } } }

  .app-header__box {
    display: block;
    z-index: 3;
    width: 373px;
    height: 190px;
    left: 50%;
    top: 154px;
    bottom: auto;
    transform: translateX(-50%);
    @include transition;

    @include mq($from: mobile) {
      top: 289px;
      width: 587px;
      height: 300px;
      transform: none;
      left: auto;
      right: 0; }

    @include mq($from: tablet) {
      width: 647px;
      height: 331px;
      top: 12px;
      right: -20px;
      animation: planes-fly 0.6s ease-out; } } }
