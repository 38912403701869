.teamTrophy .app-header {
  position: relative;

  &__section {
    background-color: $c-darkblue;
    min-height: 312px;
    height: 512px;
    display: flex;
    flex-direction: row;
    background-image: url("/images/app/neukunden/img--neukunden-bg.jpg");
    background-position: bottom;
    margin-bottom: 270px;
    box-sizing: border-box;
    position: relative;
    @include transition;

    //white ellipse (desktop only)
    @include mq($from: tablet) {
      &:after {
        content: url("/images/app/team-trophy/trophy-ground.svg");
        position: absolute;
        bottom: -1px;
        right: 130px;
        line-height: 0;
        width: 360px;
        z-index: 5;
        @include transition; } }

    .app-header__left h3 {
      display: block;
      @include mq($until: tablet) {
        margin-top: 0; } }

    .app-header__left p {
      display: block;
      max-width: 400px;
      max-height: 0;
      @include mq($until: tablet) {
        max-height: 0;
        overflow: hidden;
        margin: 0 auto; } }

    &--open {
      //height: 920px
      height: auto;
      margin-bottom: 0;
      @include transition;

      &:after {
        width: 950px;
        right: -100px;
        bottom: -1px;
        @include transition;

        @include mq($from: desktop) {
          right: 0; } }

      .app-header__left p {
        max-height: 390px;
        @include transition;
        @include mq($until: tablet) {
          max-height: 480px; } }

      .app-header__right {

        @include mq($from: tablet) {
          right: 220px;
          bottom: 32px;
          transform: none;
          @include transition; }

        @include mq($from: desktop) {
          right: 320px; } } }

    video {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      object-fit: cover;
      border-radius: 1px;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .oldie & {
        display: none; } }

    @include mq($until: tablet) {
      height: 540px;
      margin-bottom: 350px;
      @include transition;

      &--open {
        height: 960px;
        @include transition; } }

    @include mq($until: mobile) {
      height: 412px;

      &--open {
        height: 880px;
        @include transition; } }

    @include mq($from: tablet) {
      height: auto;
      @include transition;

      &--open {
        height: auto;
        @include transition; } } }

  &__box {
    line-height: 0;
    display: none;
    margin-top: auto;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 241px;
    overflow: hidden;
    z-index: 60;
    @include transition;

    @include mq($from: mobile) {
      display: block; }

    @include mq($from: tablet) {
      left: 40%;
      width: 204px;
      height: 258px;
      z-index: 20;
      margin-bottom: -30px;
      @include transition;

      .app-header__section--open & {
        margin-bottom: 30px;
        z-index: 1;
        width: 359px;
        height: 453px;
        transform: translateX(10%); } }

    .image {
      overflow: hidden;
      @include transition;

      @include mq($from: tablet) {
        @include transition; }

      img {
        width: 100%;
        height: 100%; } } }

  &__content {
    color: $c-white;
    padding: 20px 10px 40px 10px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 23;
    @include transition;

    @include mq($from: mobile) {
      padding-left: 60px;
      padding-right: 60px;
      padding-top: 60px;
      @include transition; }

    @include mq($from: tablet) {
      flex-direction: row;
      padding-left: 80px;
      padding-top: 110px;
      padding-right: 80px;
      justify-content: space-between;
      @include transition; }

    @include mq($from: desktop) {
      padding-left: 130px;
      padding-right: 130px; }

    & > p {
      display: none;

      @include mq($from: tablet) {
        display: block;
        max-width: 480px; } } }

  &__actions {
    display: none;
    position: relative;

    @include mq($from: tablet) {
      display: block;
      max-width: 480px;

      .app-header__section--open & {
        display: flex;
        flex-direction: column; }

      .button:first-of-type {
        color: $c-white;
        padding-left: 26px;
        width: max-content;
        margin-bottom: 20px;
        align-self: flex-start;

        .icon {
          left: 0;
          @include icon-color($c-white);
          @include icon-size(16px); } }

      .button:last-of-type {
        max-width: 280px;

        .app-header__section--open & {
          display: block;
          margin-left: 0; } } } }

  &__actions--mobile {
    display: block;
    text-align: center;
    z-index: 40;
    position: relative;
    transform: translateY(-350px);

    .button {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%); }

    @include mq($from: tablet) {
      display: none !important; } }

  &__heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: max-content;

    @include mq($from: tablet) {
      min-width: 430px;
      justify-content: flex-start;
      align-items: flex-start;
      z-index: 22;
      margin-right: 10px; }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: max-content;

      @include mq($from: tablet) {
        flex-direction: row;
        align-items: unset; }

      & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        @include transition;

        @include mq($from: mobile) {
          margin-bottom: 20px; }

        .icon {
          @include icon-color($c-white);
          @include icon-size(40px);

          @include mq($from: mobile) {
            @include icon-size(80px);
            flex-shrink: 100;
            margin-right: 20px;
            @include transition; }

          @include mq($from: tablet) {
            @include icon-size(60px); } } } }

    h1 {
      text-transform: uppercase;
      @include mq($until: mobile) {
        @include fontsize(42px, 1.14);
        @include transition; }
      @include mq($from: mobile) {
        @include fontsize(86px, 0.9); } }

    h2 {
      text-align: center;
      margin-bottom: 20px;

      @include mq($until: phone) {
        @include fontsize(18px, 1.22); }
      @include mq($from: tablet) {
        text-align: left;
        margin-left: 80px; } } }

  &__left {
    display: flex;
    flex-direction: column;
    min-height: max-content;

    h3 {
      display: none;
      max-width: 480px;
      width: stretch;
      //margin-bottom: 20px

      .app-header__section--open & {
        display: block;

        @include mq($until: tablet) {
          margin: 40px auto 0 auto;
          text-align: left; } } }

    p {
      max-width: 400px;
      //max-height: 277px
      display: none;
      overflow: hidden;
      @include transition;

      .app-header__section--open & {
        display: block;

        @include mq($until: tablet) {
          margin: 0 auto; } }

      @include mq($from: tablet) {
        margin-bottom: 30px; }

      @include mq($from: desktop) {
        max-width: 480px; } } }


  &__right {
    display: flex;
    height: max-content;
    align-items: flex-end;
    left: auto;
    position: absolute;
    right: 50%;
    bottom: 0;
    transform: translateX(50%) translateY(85%);
    @include transition;

    @include mq($until: tablet) {
      bottom: -90px; } } }

.teamTrophy-counter {
  width: 260px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: $c-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 19px;
  padding-bottom: 10px;
  position: relative;
  color: $c-black;
  box-sizing: border-box;
  margin: 0 auto 50px auto;
  z-index: 18;
  @include drop-shadow;
  @include transition;

  @include mq($from: mobile) {
    width: 280px; }

  .app-header__section--open & {
    @include mq($from: tablet) {
      margin: 230px auto 50px auto; } }

  &__bank {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 260px;
    height: 50px;
    display: flex;
    flex-direction: column;
    @include gradient-blue-dark;
    color: $c-white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    justify-content: center;
    align-items: center;
    transform: translateY(100%);
    z-index: 12;
    @include drop-shadow;
    @include transition;

    @include mq($from: mobile) {
      width: 280px; }

    h2 {
      font-size: 28px; } }

  & .counter {
    @include mq($until: mobile) {
      width: 29px;
      height: 42px; }

    @include mq($from: mobile) {
      height: 60px;
      width: 40px; }

    &__value {
      @include mq($until: mobile) {
        font-size: 30px; }
      @include mq($from: mobile) {
        font-size: 44px; } } }

  //transparent trophy icon
  $trophyIconWidth: 230px;

  &__icon {
    align-items: center;
    //display: flex
    justify-content: center;
    position: absolute;
    right: 210px;
    bottom: 26px;
    width: 178px;
    height: 178px;
    @include transition;

    .lottie__uncontrolled {
      margin-bottom: -6px; }

    .app-header__section--open & {
      @include mq($from: tablet) {
        top: auto;
        bottom: 300px;
        left: auto;
        right: 242px;
        transform: none;
        width: $trophyIconWidth;
        @include transition; }
      @include mq($from: desktop) {
        right: 342px; } }

    @include mq($until: tablet) {
      bottom: 0;
      right: 50%;
      transform: translateX(50%);
      height: 218px;
      width: 218px; } }

  & > h3 {
    margin-bottom: 10px;
    @include transition;

    @include mq($until: mobile) {
      @include fontsize(22px, 1.04);
      @include transition; } }

  &__wrapper {
    display: flex;
    position: relative;

    .counter-spawner {
      position: relative;
      border: 6px solid $c-orange;
      border-bottom-width: 28px;
      background-color: $c-orange;
      border-radius: 8px;
      margin-bottom: 10px;
      @include transition;

      @include mq($from: mobile) {
        border: 20px solid $c-orange;
        border-right-width: 33px; } }

    h5 {
      font-size: 18px;
      position: absolute;
      color: $c-white;
      font-weight: bold;
      top: auto;
      bottom: 12px;
      left: 50%;
      transform: translateX(-50%);
      @include transition;

      @include mq($from: mobile) {
        margin-top: -16px;
        transform: rotate(-90deg);
        bottom: auto;
        right: -3px;
        left: auto;
        top: 50%; } } } }


