@mixin fontsize($fontsize, $lineheight) {
  font-size: #{$fontsize};
  line-height: #{$lineheight}; }

@mixin icon-color($color) {
  svg use {
    fill: #{$color}; } }

@mixin icon-size($size) {
  width: $size;
  height: $size; }

@mixin transition {
  transition: all 0.3s ease-in-out; }

@mixin gradient-blue {
  background: linear-gradient(top, #85bae0, #5489c2); }

@mixin gradient-blue-medium {
  background: transparent linear-gradient(180deg, rgba(70, 70, 118, 1) 0%, rgba(54, 54, 105, 1) 100%) 0% 0% no-repeat padding-box; }

@mixin gradient-blue-dark {
  background: linear-gradient(180deg, rgba(39, 39, 75, 1) 0%, rgba(34, 34, 72, 1) 100%) 0% 0% no-repeat padding-box; }

@mixin gradient-gold {
  background: transparent linear-gradient(180deg, rgba($c-brightgold, 1) 0%, rgba($c-gold, 1) 100%) 0% 0% no-repeat padding-box; }

@mixin drop-shadow {
  box-shadow: 0 2px 8px rgba($c-black, .16); }

@mixin drop-shadow-darker {
  box-shadow: 0 2px 8px rgba($c-black, 0.32); }

@mixin scale($factor:1.05) {
  transform: perspective(1px) scale($factor); }
