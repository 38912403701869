.cart {

  &__container {
    display: flex;
    justify-content: space-between; }

  &__left {
    margin-bottom: 40px;
    flex-shrink: 1;
    flex-grow: 1;
    background-color: $c-white;
    border-radius: 8px;
    padding: 20px;
    height: max-content;

    div.cart-product {
      //flex-wrap: wrap
      min-width: 600px;
      flex-grow: 100;
      flex-shrink: 100;
      width: unset;
      padding: 0;
      margin-bottom: 20px;
      height: unset;

      &:not(.cart-product--unavailable) {
        border-radius: 0;
        padding: 0 0 20px 0;
        margin-bottom: 20px;
        border-bottom: 1px solid $c-brightgrey; }

      &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0; }

      @include mq($until: mobile) {
        min-width: unset;
        height: unset;

        .cart-product__content {
          padding-left: 10px; }

        .cart-product__quantity {
          position: relative;
          //width: max-content
          //flex-direction: row-reverse
          //align-items: center
          //padding-top: 20px

          .selectfield__container {} } } }
    //width: 84px
    //margin-right: 10px

    &.cart__left--unavailable {
      position: relative;

      .cart-product--available {
        opacity: .2; } } }

  &__right {
    position: relative;

    .credit-box {
      margin-left: 20px;
      margin-bottom: 40px;
      position: sticky;
      top: 120px; }

    @include mq($until: tablet) {
      position: unset;

      .credit-box {
        position: fixed;
        z-index: 70;
        top: unset;
        width: 100%;
        bottom: 0;
        left: 0;
        margin: 0;
        min-height: unset;
        padding: 0;
        box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.2);
        border-radius: 0;

        &__actions a:last-of-type {
          display: none; } } } }


  &__left--empty {
    width: 100%;
    max-width: 780px;
    max-height: 293px;
    margin: 0 auto 40px auto; }

  &__empty {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 40px 40px 40px;

    h2 {
      width: max-content;
      margin: 0 auto 20px auto;
      display: flex; } }

  &__lottie-circle {
    position: absolute;
    left: 50%;
    top: 0;
    width: 100px;
    height: 100px;
    background-color: $c-white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -25%);
    @include drop-shadow;

    .lottie__uncontrolled {
      width: 90px;
      height: 90px; } }

  &__empty-actions {
    margin: 0 auto;
    flex-direction: column;
    display: flex;
    align-items: center;

    a {
      width: 100%; }

    .button {
      min-width: max-content;
      width: 100%;
      width: stretch;
      max-width: 260px;

      &:first-of-type {
        margin-bottom: 10px; } } } }


