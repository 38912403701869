.footer {
  width: 100%;
  max-width: 1440px;
  height: 200px;
  background-color: $c-darkblue;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__links {
    margin-top: 40px; }

  &__links {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;

    a {
      display: inline-block;
      margin: 0 20px;
      text-decoration: none;
      color: $c-white;
      @include fontsize(16px, 1.5);
      border-bottom: 2px solid transparent;
      @include transition;

      &:hover {
        border-bottom: 2px solid $c-turquoise;
        @include transition; } } } }

